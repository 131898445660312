import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class YouTubeVideoService {

  private initialLoad: boolean = true;

  constructor() { }

  loadVideo() {
    if (this.initialLoad) {
      const iframeElement = document.getElementById('youtubeVideossssss') as HTMLIFrameElement;
      if (iframeElement) {
        iframeElement.src = iframeElement.src;
      }
      this.initialLoad = false;
    }
  }
}
