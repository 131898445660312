export class UnitData{

public static bbCodes(){
 var bbscodes = [
    {
      "slug" : "ilahiyat-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 452,
      "abisis-id" : 422

    },
    {
      "slug" : "ziraat-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 459,
      "abisis-id" : 529

    },
    {
      "slug" : "veteriner-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 458,
      "abisis-id" : 487
    },
    {
      "slug" : "uygulamali-bilimler-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 915,
      "abisis-id" : 944
    },
    {
      "slug" : "tip-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 457,
      "abisis-id" : 81,
    },
    {
      "slug" : "spor-bilimleri-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 462,
      "abisis-id" : 1100
    },
    {
      "slug" : "siyasal-bilgiler-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 456,
      "abisis-id" : 358
    },
    {
      "slug" : "saglik-bilimleri-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 455,
      "abisis-id" : 714
    },
    {
      "slug" : "muhendislik-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 454,
      "abisis-id" : 700
    },
    {
      "slug" : "iletisim-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 453,
      "abisis-id" : 583,
    },
    {
      "slug" : "acik-ve-uzaktan-egitim-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 934,
      "abisis-id" : 1444,
    },
    {
      "slug" : "hukuk-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 451,
      "abisis-id" : 330
    },
    {
      "slug" : "hemsirelik-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 929,
      "abisis-id" : 1182
    },
    {
      "slug" : "guzel-sanatlar-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 916,
      "abisis-id" : 815
    },
    {
      "slug" : "fen-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 450,
      "abisis-id" : 256
    },
    {
      "slug" : "egitim-bilimleri-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 449,
      "abisis-id" : 295
    },
    {
      "slug" : "eczacilik-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 448,
      "abisis-id" : 155
    },
    {
      "slug" : "dis-hekimligi-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 447,
      "abisis-id" : 136
    },
    {
      "slug" : "dil-ve-tarih-cografya-fakultesi",
      "degree-id" : 2,
      "bologna-id" : 446,
      "abisis-id" : 174
    },
    {
      "slug" : "1-organize-sanayi-bolgesi-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 933,
      "abisis-id" : 668
    },
    {
      "slug" : "adalet-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 470,
      "abisis-id" : 353
    },
    {
      "slug" : "ankara-universitesi-ayas-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 917,
      "abisis-id" : 947
    },
    {
      "slug" : "beypazari-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 463,
      "abisis-id" : 681
    },
    {
      "slug" : "elmadag-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 838,
      "abisis-id" : 19
    },
    {
      "slug" : "siber-guevenlik-meslek-yueksekokulu",
      "degree-id" : 1,
      "bologna-id" : 937,
      "abisis-id" : 0
    },
    {
      "slug" : "gama-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 889,
      "abisis-id" : 1086
    },
    {
      "slug" : "haymana-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 882,
      "abisis-id" : 893
    },
    {
      "slug" : "kalecik-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 467,
      "abisis-id" : 724
    },
    {
      "slug" : "bala-meslek-yueksekokulu",
      "degree-id" : 1,
      "bologna-id" : 938,
      "abisis-id" : 1771
    },
    {
      "slug" : "kizilcahamam-saglik-hizmetleri-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 927,
      "abisis-id" : 1161
    },
    {
      "slug" : "nallihan-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 888,
      "abisis-id" : 967
    },
    {
      "slug" : "saglik-hizmetleri-meslek-yuksekokulu",
      "degree-id" : 1,
      "bologna-id" : 465,
      "abisis-id" : 611
    },
    {
      "slug" : "biyoteknoloji-enstituesue",
      "degree-id" : 3,
      "bologna-id" : 911,
      "abisis-id" : 856
    },
    {
      "slug" : "egitim-bilimleri-enstituesue",
      "degree-id" : 3,
      "bologna-id" : 899,
      "abisis-id" : 570
    },
    {
      "slug" : "fen-bilimleri-enstituesue",
      "degree-id" : 3,
      "bologna-id" : 928,
      "abisis-id" : 572

    },
    {
      "slug" : "nuekleer-bilimler-enstituesue",
      "degree-id" : 3,
      "bologna-id" : 912,
      "abisis-id" : 864
    },
    {
      "slug" : "saglik-bilimleri-enstituesue",
      "degree-id" : 3,
      "bologna-id" : 902,
      "abisis-id" : 573
    },
    {
      "slug" : "sosyal-bilimler-enstituesue",
      "degree-id" : 3,
      "bologna-id" : 903,
      "abisis-id" : 574
    },
    {
      "slug" : "tuerk-inkilap-tarihi-enstituesue",
      "degree-id" : 3,
      "bologna-id" : 913,
      "abisis-id" : 579
    },
    {
      "slug" : "su-yoenetimi-enstituesue",
      "degree-id" : 3,
      "bologna-id" : null,
      "abisis-id" : 878
    },
    {
      "slug" : "adli-bilimler-enstituesue",
      "degree-id" : 3,
      "bologna-id" : null,
      "abisis-id" : 575
    },
    {
      "slug" : "gida-guevenligi-enstituesue",
      "degree-id" : 3,
      "bologna-id" : null,
      "abisis-id" : 969
    },
    {
      "slug" : "hepatoloji-enstituesue",
      "degree-id" : 3,
      "bologna-id" : null,
      "abisis-id" : 582
    },
    {
      "slug" : "hizlandirici-teknolojileri-enstituesue",
      "degree-id" : 3,
      "bologna-id" : null,
      "abisis-id" : 877
    },
    {
      "slug" : "kanser-arastirma-enstituesue",
      "degree-id" : 3,
      "bologna-id" : null,
      "abisis-id" : 1235
    },
    {
      "slug" : "koek-huecre-enstituesue",
      "degree-id" : 3,
      "bologna-id" : null,
      "abisis-id" : 875
    },
    {
      "slug" : "devlet-konservatuvari",
      "degree-id" : "konservatuvar",
      "bologna-id" : 469,
      "abisis-id" : 978
    }
  ];

  return bbscodes;
}
}
