import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  ActivityHistoryEntity,
  ConferencePaper,
} from 'src/app/contracts/cms/avesis-entities';
import { GeneralSettingEntity } from 'src/app/contracts/cms/general-setting-entity';
import { HomeModel } from 'src/app/contracts/cms/home-model';
import {
  HttpClientService,
  RequestParameters,
} from 'src/app/services/http-client-services';
import { LangService } from 'src/app/services/lang-service';
import {
  AvesisAuthService,
  _avesisAccessToken,
  _avesisIsAuthenticated,
} from 'src/app/services/models/avesis-auth.service';
import { AveisLoginService } from 'src/app/services/models/avesis-login.service';

import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { TranslationService } from 'src/app/services/translation.services';
import { SectionSlider4Component } from './section-slider4/section-slider4.component';
import { NotFoundHandlerService } from 'src/app/services/not-found-handller-service';
import { Router } from '@angular/router';
import { SeoService } from 'src/app/services/seo-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { InfiniteScrollDirective, NgxInfiniteScrollService } from 'ngx-infinite-scroll';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],

})
export class HomeComponent implements AfterViewInit, OnInit, OnChanges,OnDestroy  {
  currentLang: string = 'tr-TR';
  // Yüklenen bölümleri takip etmek için bir liste
  loadedSections: string[] = [];
  // Scroll tetiklemesi için gerekli ayarları tanımlıyoruz
  scrollDistance = 1;
  scrollUpDistance = 2;

  constructor(
    private httpClientService: CMSDynamicService,
    public avesisAuthService: AvesisAuthService,
    public aveisLoginService: AveisLoginService,
    private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService,
    private renderer: Renderer2,
    private router: Router,
    private seoService: SeoService
  ) {
    aveisLoginService.login();
    avesisAuthService.identityCheck();
  }

  private scrollListener!: () => void;
  private pageReloaded = false;

  ngOnChanges(changes: SimpleChanges): void {

    this.translationServices();
  }
  ngOnInit(): void {
    this.scrollListener = this.renderer.listen('window', 'scroll', (event) => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const pageHeight = document.documentElement.scrollHeight;


        this.scrollCount++;
        if (this.scrollCount >= this.maxScrollCount) {
          if (!this.avesisHistory && this.currentLang !== 'en-EN') {

            this.getAvesis();
          }
          this.scrollCount = 0;
        }

    });
    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.homeModelData = null;

      this.updateMetaTags();
      await this.getHome();
    });

    window.scrollTo(0, 0);
  }

  ngOnDestroy(): void {
    if (this.scrollListener) {
      this.scrollListener(); // Dinleyiciyi temizle
    }
  }



  scrollCount = 0; // Kaydırma sayacı
  maxScrollCount = 45; // Tetiklemek için gereken kaydırma sayısı

  ekutuphane = '';
  edevlethizmetleri = '';
  ekampus = '';
  ebeyas = '';
  malzemeportali = '';
  akademikarsivsistemi = '';
  ekdersyazilimi = '';
  akademikveriys = '';
  bulten = '';
  duyurular = '';
  etkinlikler = '';
  ehizmetler = '';
  rektorluk = '';
  haberlerigoruntule = '';
  teknofest = '';

  translationServices() {

    const lang: 'en' | 'tr' = this.storageService
      .getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';

    this.ekutuphane = this.translationService.getTranslation(
      'ekutuphane',
      lang
    );
    this.edevlethizmetleri = this.translationService.getTranslation(
      'edevlethizmetleri',
      lang
    );
    this.ekampus = this.translationService.getTranslation('ekampus', lang);
    this.ebeyas = this.translationService.getTranslation('ebeyas', lang);
    this.malzemeportali = this.translationService.getTranslation(
      'malzemeportalı',
      lang
    );
    this.akademikarsivsistemi = this.translationService.getTranslation(
      'akademikarsivsistemi',
      lang
    );
    this.ekdersyazilimi = this.translationService.getTranslation(
      'ekdersyazilimi',
      lang
    );
    this.akademikveriys = this.translationService.getTranslation(
      'akademikveriys',
      lang
    );
  }

  updateMetaTags(): void {
    const title = 'Ankara Üniversitesi | Cumhuriyetin İlk Üniversitesi';
    const description = 'Cumhuriyetin İlk Üniversitesi';
    const keywords = ['Ankara', 'üniversite', 'eğitim', 'öğrenci'];
    const authors = ['Ankara Üniversitesi'];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl = 'https://www.example.com/image.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr';

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      'Anasayfa',
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );

    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);

    this.translationServices();
  }

  async ngAfterViewInit(): Promise<void> {}

  homeModelData: HomeModel | any;
  avesisHistory: ConferencePaper[] | any;
  avesisLatest: ActivityHistoryEntity[] | any;

  async getHome() {
    const promiseData: HomeModel | any = await this.httpClientService.get(
      'CMS',
      'GetMainHome',
      '',
      1,
      this.currentLang
    );


    this.homeModelData = promiseData;
  }

  async getAvesis() {
    var queryString = 'criteria.lastNItem=30';

    var requestParameter: Partial<RequestParameters> = {
      fullEndPoint: 'https://avesisveri.ankara.edu.tr/sonyayinlar.php',
    };

    const promise: ActivityHistoryEntity[] | any =
      await this.httpClientService.getx(requestParameter);

    this.avesisHistory = promise.result;

    var requestParameter2: Partial<RequestParameters> = {
      fullEndPoint: 'https://avesisveri.ankara.edu.tr/songuncellemeler.php',
      /* headers: new HttpHeaders({
        Authorization: 'Bearer ' + _avesisAccessToken,
      }), */
    };

    const promise2: ConferencePaper[] | any = await this.httpClientService.getx(
      requestParameter2
    );

    this.avesisLatest = promise2;
  }

  social = [
    {
      id: '',
      date: 1712874954,
      platform: 'instagram',
      text: 'Ankara \u00dcniversitesi T\u00fcrkiye\u2019nin Oyuncak Haritas\u0131n\u0131 \u00c7\u0131kard\u0131. Oyuncaklar\u0131n nitelikleri ve hangi y\u00f6reye ait oldu\u011fu kay\u0131t alt\u0131na al\u0131nd\u0131. \n\n\ud83d\udcfaTRT Haber',
      media_url:
        'https://scontent-iad3-1.cdninstagram.com/o1/v/t16/f1/m82/AD4F323CC757C13EC1A38479BDB1428C_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmNsaXBzLnVua25vd24tQzMuMTA4MC5kYXNoX2Jhc2VsaW5lXzFfdjEifQ&_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=110&vs=2407842532738505_3259215127&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC9BRDRGMzIzQ0M3NTdDMTNFQzFBMzg0NzlCREIxNDI4Q192aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVAhg6cGFzc3Rocm91Z2hfZXZlcnN0b3JlL0dDbldFUnF0ZmdQVmlKZ0xBRENaQ1FpYVI2WjFicV9FQUFBRhUCAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJvCQlOKLk5xAFQIoAkMzLBdAX%2BFHrhR64RgSZGFzaF9iYXNlbGluZV8xX3YxEQB1AAA%3D&ccb=9-4&oh=00_AfB2Kt_ym7QmVj0nkd9liJjAzYTmHE4cVNZDD2iXLVzn9g&oe=661A3497&_nc_sid=1d576d&_nc_rid=4c5cc1bfcc',
      permalink: 'https://www.instagram.com/reel/C5og6vYsbHl/',
      thumb: '',
    },
    {
      id: '',
      date: 1712673380,
      platform: 'youtube',
      text: 'HAYIRLI BAYRAMLAR',
      media_url:
        'https://i9.ytimg.com/vi/T5_vQGi1cmU/hqdefault.jpg?sqp=CLzU1LAG&rs=AOn4CLDFbRubkAmKFOCxV1QCpcnBu7WGGA',
      permalink: 'https://www.youtube.com/watch?v=T5_vQGi1cmU',
      thumb: '',
    },
    {
      id: '',
      date: 1711967569,
      platform: 'instagram',
      text: 'Siber G\u00fcvenlik Sohbetlerinde bu hafta Burak Day\u0131o\u011flu ile \nAtar Labs \u00f6zelinde giri\u015fimcilik olmak \u00fczere sekt\u00f6re dair bir\u00e7ok konuyu konu\u015fuyoruz. \n\n\ud83c\udf99\ufe0fBurak Day\u0131o\u011flu\n\ud83d\udd58 21.00\n\ud83d\uddd3\ufe0f 3 Nisan 2024 \u00c7ar\u015famba',
      media_url:
        'https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/435008141_779313797122762_8664761428028602230_n.webp?stp=dst-jpg&_nc_cat=110&ccb=1-7&_nc_sid=18de74&_nc_ohc=BX67rCHKhq4AX_Ee0W4&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfCz_76KvNvNlqFDMCGwCe-ByGJ5iLbg_4SGHxMqWJ6ctA&oe=66104F86',
      permalink: 'https://www.instagram.com/p/C5NepGFMz-H/',
      thumb: '',
    },
    {
      id: '',
      date: 1711735993,
      platform: 'instagram',
      text: '\ud83d\udcf7 Dil ve Tarih-Co\u011frafya Fak\u00fcltemizden',
      media_url:
        'https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/434645627_792928438864827_180222348692873533_n.webp?stp=dst-jpg&_nc_cat=110&ccb=1-7&_nc_sid=18de74&_nc_ohc=7HauoW7h3OIAX9xQZsS&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfA_5wS3mjcBjh-JvaVn_xAvrFF6Hr7yVQ0q5H5dQXVgkA&oe=660AF42D',
      permalink: 'https://www.instagram.com/p/C5Gk8fLMvAi/',
      thumb: '',
    },
    {
      id: '',
      date: 1711565009,
      platform: 'instagram',
      text: '\u0130lkbahar yerle\u015fkelerimize bir ba\u015fka g\u00fczellik kat\u0131yor. \n\n\ud83d\udccdG\u00f6lba\u015f\u0131\n\ud83d\udccdBe\u015fevler ',
      media_url:
        'https://scontent-iad3-2.cdninstagram.com/o1/v/t16/f1/m82/4749E32C54C6DB5EFF393036D2426C89_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmNsaXBzLnVua25vd24tQzMuNzIwLmRhc2hfYmFzZWxpbmVfMV92MSJ9&_nc_ht=scontent-iad3-2.cdninstagram.com&_nc_cat=107&vs=429064902959114_3148116923&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC80NzQ5RTMyQzU0QzZEQjVFRkYzOTMwMzZEMjQyNkM4OV92aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVAhg6cGFzc3Rocm91Z2hfZXZlcnN0b3JlL0dQWE4zUm1TY2I2ektJSURBR20xRHJwQU50Z0JicV9FQUFBRhUCAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJtin1t2d9%2F8%2FFQIoAkMzLBdAQRbpeNT99BgSZGFzaF9iYXNlbGluZV8xX3YxEQB1AAA%3D&ccb=9-4&oh=00_AfCo0nGavf56uK9eMldNK3pcFuFDfGPmFTxYg0qHQXkbog&oe=6605A759&_nc_sid=1d576d&_nc_rid=29e2234705',
      permalink: 'https://www.instagram.com/reel/C5BesWaIYMn/',
      thumb: '',
    },
    {
      id: '',
      date: 1711476971,
      platform: 'instagram',
      text: '\ud83d\udccdVeteriner Fak\u00fcltesi, Anatomi Anabilim Dal\u0131, Anatomi M\u00fczesi \n\n\u2139\ufe0f\u00dclkemizin en kapsaml\u0131 Veteriner Anatomi M\u00fczesi, Veteriner Fak\u00fcltemiz, Anatomi Anabilim Dal\u0131 k\u00fcrs\u00fcs\u00fcnde bulunmaktad\u0131r. M\u00fczemiz sadece veteriner lisans ve \u00fcst lisans \u00f6\u011frencileri i\u00e7in referans laboratuvar\u0131 de\u011fil ayn\u0131 zamanda g\u00f6rsel zenginli\u011fi ile t\u00fcm halk\u0131m\u0131z i\u00e7in bir bilgilendirme merkezi olma \u00f6zelli\u011fi ta\u015f\u0131maktad\u0131r.\n\n#AnkaraSeniBekliyor',
      media_url:
        'https://scontent-iad3-1.cdninstagram.com/o1/v/t16/f1/m82/7C4A6930C8452CCA2A1CB3E9BE8D10AC_video_dashinit.mp4?efg=eyJ2ZW5jb2RlX3RhZyI6InZ0c192b2RfdXJsZ2VuLmNsaXBzLnVua25vd24tQzMuNzIwLmRhc2hfYmFzZWxpbmVfMV92MSJ9&_nc_ht=scontent-iad3-1.cdninstagram.com&_nc_cat=110&vs=1497635287815778_3548391762&_nc_vs=HBksFQIYT2lnX3hwdl9yZWVsc19wZXJtYW5lbnRfcHJvZC83QzRBNjkzMEM4NDUyQ0NBMkExQ0IzRTlCRThEMTBBQ192aWRlb19kYXNoaW5pdC5tcDQVAALIAQAVAhg6cGFzc3Rocm91Z2hfZXZlcnN0b3JlL0dBLWk1Um1vMV9ZQmZlNEpBSUtIaGVpd200bGNicV9FQUFBRhUCAsgBACgAGAAbAYgHdXNlX29pbAExFQAAJtLcrb755u9AFQIoAkMzLBdAPqZmZmZmZhgSZGFzaF9iYXNlbGluZV8xX3YxEQB1AAA%3D&ccb=9-4&oh=00_AfAIJGaL9j2nwqzmJGFNpAn_w2ZxmJswEXIEH-wF6qySgA&oe=66047743&_nc_sid=1d576d&_nc_rid=a7ca2bd113',
      permalink: 'https://www.instagram.com/reel/C4-2oUhIbW0/',
      thumb: '',
    },
    {
      id: '',
      date: 1711148962,
      platform: 'instagram',
      text: 'Sanki hi\u00e7 kar ya\u011fmam\u0131\u015f gibi\u2026\n\n\u0130yi geceler, mutlu hafta sonlar\u0131 Ankara \u00dcniversitesi ailesi.',
      media_url:
        'https://scontent-iad3-1.cdninstagram.com/v/t51.29350-15/434119671_3653675098216957_883163245421676357_n.webp?stp=dst-jpg&_nc_cat=108&ccb=1-7&_nc_sid=18de74&_nc_ohc=orT5o0G6oHQAX8MaNfP&_nc_ht=scontent-iad3-1.cdninstagram.com&edm=ANo9K5cEAAAA&oh=00_AfBhSSqzkTH1EMcUwQE6Gb_NBCLw1hedsVz-3ZjFRwhCJg&oe=6603419C',
      permalink: 'https://www.instagram.com/p/C41FRePM4MT/',
      thumb: '',
    },
    {
      id: '',
      date: 1711126493,
      platform: 'youtube',
      text: "22 Mart D\u00fcnya Su G\u00fcn\u00fc Kentsel Su Direnci Yakla\u015f\u0131m\u0131 ile T\u00fcrkiye'deki \u015eehirlerde \u0130klim De\u011fi\u015fikli\u011fi",
      media_url: 'https://i.ytimg.com/vi/0-vN9tF2iBo/hqdefault_live.jpg',
      permalink: 'https://www.youtube.com/watch?v=0-vN9tF2iBo',
      thumb: 'https://i.ytimg.com/vi/0-vN9tF2iBo/sddefault_live.jpg',
    },
  ];

  video = {
    title: 'All Solutions',
    subtitle: '',
    videolink:
      'https://ubys.ankara.edu.tr/Resources/Cms/31-05-2024/31-05-2024-15-30-22-news.mp4',
    solutions: [
      {
        count: '86984',
        title: 'ÖĞRENCİ',
        ico: '+',
      },
      {
        count: '5435',
        title: 'İDARİ PERSONEL',
        ico: '+',
      },
      {
        count: '4777',
        title: 'AKADEMİK PERSONEL',
        ico: '+',
      },
      {
        count: '841',
        title: 'PROGRAM',
      },
      {
        count: '19',
        title: 'FAKÜLTE',
      },
      {
        count: '49',
        title: 'ARAŞTIRMA MERKEZİ',
      },
    ],
    description: {
      title: 'Ankara Üniversitesi',
      subtitle:
        'Nitelikli araştırmalar yapan, bilgi ve yeteneklerini insanlık ve ülke yararına kullanan ve evrensel düzeyde fark yaratarak geleceğe yön veren yenilikçi üniversite olmaktır..',
      quote:
        "Prof.Dr. Necdet ÜNÜVAR. 6 Haziran 1960'ta Adana Ceyhan'da doğdu. İlkokulu Adana'da, ortaokul ve Liseyi Mersin'de",
      quoteby: 'Necdet Ünüvar, Rektör',
    },
  };
}
