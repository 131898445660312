<div class="page-wraper">
  <!-- CONTENT START -->
  <div class="page-content">
    <app-section-inner-banner [breadcrumbs]="breadcumb"></app-section-inner-banner>

    <div class="section-full p-t0 p-b40">
      <div class="toolbar">
        <div
          class="container d-flex justify-content-center aligment-item-center"
        >
          <button
            class="btn btn-light"
            style="height: 50px; padding-right: 10px"
            value="Tümü"
            (click)="selectLisans($event)"
          >
           {{tum}}
          </button>

          <select
            *ngIf="onLisansFilterData.length >= 1"
            id="selectOlisans"
            class="selectpicker select-margin ml-auto"
            data-live-search="true"
            title="{{onlisans}}"
            (change)="selectOnlisans($event)"
          >
            <ng-container *ngFor="let onlisans of onLisansFilterData">
              <option id="child" value="{{onlisans.name[getLang]}}">
                {{ onlisans.name[getLang] }}
              </option>
            </ng-container>
          </select>

          <select
            id="selectLisans"
            class="selectpicker select-margin ml-auto"
            data-live-search="true"
            title={{lisans}}
            (change)="selectLisans($event)"
          >
            <option
              *ngFor="let lisans of lisansFilterData"
              value="{{ lisans.name[getLang] }}"
            >
              {{ lisans.name[getLang] }}
            </option>
          </select>

          <select
            id="selectYuksekLisans"
            class="selectpicker select-margin ml-auto"
            data-live-search="true"
            title="{{yukseklisans}}"
            (change)="selectYuksek($event)"
          >
            <option
              *ngFor="let yuksek of yuksekDoktoraFilterData"
              value="{{yuksek.name[getLang]}}"
            >
              {{ yuksek.name[getLang] }}
            </option>
          </select>
          <select
            id="selectDoktora"
            class="selectpicker select-margin ml-auto"
            data-live-search="true"
            title="{{doktora}}"
            (change)="selectDoktora($event)"
          >
            <option
              *ngFor="let doktora of yuksekDoktoraFilterData"
              value="{{doktora.name[getLang]}}"
            >
              {{ doktora.name[getLang] }}
            </option>
          </select>

          <mat-form-field class="ml-auto">
            <mat-label>Program {{search}}</mat-label>
            <input matInput placeholder="{{search}}" (keyup)="searchFilter($event)" />
          </mat-form-field>
        </div>
      </div>

      <div class="container">
        <div class="section-content">
          <div class="wt-separator-two-part">
            <div class="row wt-separator-two-part-row">
              <div class="col-lg-2 col-md-2 wt-separator-two-part-left">
                <!-- TITLE START-->
                <div class="section-head left wt-small-separator-outer">
                  <h3>{{tum}}</h3>
                  <div class="wt-small-separator site-text-primary">
                    <div class="sep-leaf-left"></div>
                    <div>- {{ count }} {{countmetin}}</div>
                  </div>
                </div>

                <!-- TITLE END-->
              </div>

              <div                class="col-lg-3 col-md-3 wt-separator-two-part-left text-left"
              >
                <select
                  id="selectProgramTip"
                  class="selectpicker select-margin ml-auto"
                  data-live-search="true"
                  title="{{programtype}}"
                  (change)="changeType($event)"
                >
                  <option
                    *ngFor="let programType of programTypes"
                    value="{{ programType.type }}"
                  >
                    {{ programType.type }}
                  </option>
                </select>
              </div>
              <div                class="col-lg-7 col-md-7 wt-separator-two-part-right text-right"
              >
                <button
                  class="btn btn-sm btn-warning select-margin"
                  value="Ön Lisans"
                  (click)="routerW(1)"
                >
                 {{onlisans}}
                </button>
                <button
                  class="btn btn-sm btn-primary select-margin"
                  value="Lisans"
                  (click)="routerW(2)"
                >
                  {{lisans}}
                </button>
                <button
                  class="btn btn-sm btn-success select-margin"
                  value="Yüksek Lisans"
                  (click)="routerW(3)"
                >
                 {{ yukseklisans}}
                </button>
                <button
                  class="btn btn-sm btn-danger select-margin"
                  value="Doktora"
                  (click)="routerW(4)"
                >
                  {{doktora}}
                </button>
              </div>
            </div>
          </div>

          <div
            class="row d-flex justify-content-center"
            *ngIf="this.tempProgramData != null"
          >
            <div
              class="col-lg-4 col-md-6 col-sm-12 m-b50"
              *ngFor="let object of this.tempProgramData"
            >
              <div class="service-border-box service-image-box">
                <div class="wt-box service-box-1 bg-white">
                  <div
                    class="service-box-title title-style-2 site-text-secondry  {{
                      object.color
                    }}-border"
                  >
                    <span class="s-title-one">{{
                      object.program_name[getLang]
                    }}</span>
                    <span class="s-title-one">{{
                      object.program_type[getLang]
                    }}</span>
                    <span class="s-title-two">{{
                      object.unit_name[getLang]
                    }}</span>
                  </div>
                  <div class="service-box-content">
                    <a
                      routerLink="{{ object.degree_id }}/{{ object.unit_id }}/{{
                        object.id
                      }}-{{ object.botid }}"
                      class="site-button-link btn btn-light w-100"
                      >{{read}}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
