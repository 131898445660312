// not-found-handler.service.ts

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from './logging-service';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class NotFoundHandlerService {
  private notFoundSource = new Subject<string>();
  notFound = this.notFoundSource.asObservable();
  constructor(private router: Router, private loggingService: LoggingService) { }
  logNotFound(url: string): void {
    this.notFoundSource.next(url);
  }
  handleNotFound(url: string): void {
    this.loggingService.logNotFound(url);
    // 404 hatası oluştuğunda loglama işlemi yapılır

    this.router.navigateByUrl('404/sayfa-bulunumadi');
    // Kullanıcıyı 404 sayfasına yönlendirilir
  }
}

