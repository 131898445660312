

<div class="page-wraper">
  <!-- CONTENT START -->
  <div class="page-content">
    <app-section-inner-banner></app-section-inner-banner>
    <div class="section-full p-t80 p-b50 bg-gray-light">
      <div class="container mt-4">
        <div class="row mb-3">
          <div class="col-md-4 col-sm-6">
            <label for="countrySelect">Ülke Seçin:</label>
            <select id="countrySelect" class="form-control" [(ngModel)]="selectedCountry" (change)="onCountryChange()">
              <option value="">Tümü</option>
              <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
            </select>
          </div>
          <div class="col-md-4 col-sm-6">
            <label for="statusSelect">Durum:</label>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="status" id="active" [(ngModel)]="showActive" [value]="true" (change)="onActiveChange()">
              <label class="form-check-label" for="active">
                Aktif
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="status" id="inactive" [(ngModel)]="showActive" [value]="false" (change)="onActiveChange()">
              <label class="form-check-label" for="inactive">
                Pasif
              </label>
            </div>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead class="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Purpose</th>
                <th scope="col">Start Date</th>
                <th scope="col">Finish Date</th>
                <th scope="col">Protocol Link</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let protocol of paginatedProtocols; let i = index">
                <th scope="row">{{ (currentPage - 1) * pageSize + i + 1 }}</th>
                <td data-label="Name">{{ protocol.name }}</td>
                <td data-label="Purpose">{{ protocol.purpose }}</td>
                <td data-label="Start Date">{{ protocol.startDate | date }}</td>
                <td data-label="Finish Date">{{ protocol.finishDate | date }}</td>
                <td data-label="Protocol Link"><a [href]="protocol.protocolLink" target="_blank">Link</a></td>
              </tr>
            </tbody>
          </table>
        </div>

        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item" [class.disabled]="currentPage === 1">
              <button class="page-link" (click)="previousPage()">Önceki</button>
            </li>
            <li class="page-item" *ngFor="let page of pages" [class.active]="currentPage === page">
              <button class="page-link" (click)="setPage(page)">{{ page }}</button>
            </li>
            <li class="page-item" [class.disabled]="currentPage === totalPages">
              <button class="page-link" (click)="nextPage()">Sonraki</button>
            </li>
          </ul>
        </nav>
      </div>

    </div>
  </div>
</div>
