import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private meta: Meta, private title: Title) {}

  updateMetaTags(
    title: string,
    description: string,
    keywords: string[],
    authors: string[],
    developers: string[],
    tags: string[]
  ): void {
    // Başlık ve açıklamayı güncelle
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'description', content: description });

    // Anahtar kelimeleri güncelle
    this.meta.updateTag({ name: 'keywords', content: keywords.join(', ') });

    // Yazarları ve geliştiricileri güncelle
    this.meta.updateTag({ name: 'author', content: authors.join(', ') });
    this.meta.updateTag({ name: 'developer', content: developers.join(', ') });

    // Etiketleri güncelle
    this.meta.updateTag({ name: 'tag', content: tags.join(', ') });
  }

  updateSocialMetaTags(
    title: string,
    imageUrl: string,
    site: string,
    twitterSite: string,
    url: string
  ): void {
    // Twitter kartı için meta etiketlerini güncelle
    this.meta.updateTag({ name: 'twitter:image', content: imageUrl });
    this.meta.updateTag({ name: 'twitter:site', content: twitterSite });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:url', content: url });

    // Open Graph meta etiketlerini güncelle
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
    this.meta.updateTag({ property: 'og:site_name', content: site });
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:url', content: url });
  }

  addCanonicalUrl(canonicalUrl: string): void {
    // Canonical URL'yi ekleyin
    this.meta.updateTag({ rel: 'canonical', href: canonicalUrl });
  }
}
