// translation.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface Translation {
  [key: string]: {
    title: {
      en: string;
      tr: string;
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private translations: Translation = {
    allNewsButton: {
      title: {
        en: "View the News",
        tr: "Haberleri Görüntüle"
      }
    },
    news: {
      title: {
        en: "News",
        tr: "Haberler"
      }
    },
    events: {
      title: {
        en: "Events",
        tr: "Etkinlikler"
      }
    },
    notices: {
      title: {
        en: "Notices",
        tr: "Duyurular"
      }
    },
    unit: {
      title: {
        en: "Umits",
        tr: "Birimler"
      }
    },

    program: {
      title: {
        en: "Programs",
        tr: "Programlar"
      }
    },
    onlisans: {
      title: {
        en: "Associate's Degree",
        tr: "Ön Lisans"
      }
    },
    doktora: {
      title: {
        en: "Doctorate's Degree",
        tr: "Doktora"
      }
    },
    lisans: {
      title: {
        en: "Bachelor's Degree",
        tr: "Lisans"
      }
    },
    yukseklisans: {
      title: {
        en: "Master's Degree",
        tr: "YüksekLisans"
      }
    },
    countmetin: {
      title: {
        en: " listed.",
        tr: "listelendi."
      }
    },


    search: {
      title: {
        en: "Search",
        tr: "Ara"
      }
    },
    readmore: {
      title: {
        en: "Read More",
        tr: "İncele"
      }
    },

    programfilter: {
      title: {
        en: "Filter by Program Type",
        tr: "Program Tipine Göre Filtrele"
      }
    },
    tanitim: {
      title: {
        en: "Introducing",
        tr: "Tanıtım"
      }
    },
    programamac: {
      title: {
        en: "Program Purpose",
        tr: "Programın Amacı"
      }
    },
    courselist: {
      title: {
        en: "Course List",
        tr: "Ders Listesi"
      }
    },

    coursename: {
      title: {
        en: "Course Name",
        tr: "Ders Adı"
      }
    },

    etkinlik: {
      title: {
        en: "Event",
        tr: "Etkinlik"
      }
    },

    numbers: {
      title: {
        en: "Number",
        tr: "Etkinlik"
      }
    },


    suresi: {
      title: {
        en: "Duration(Hours)",
        tr: "Süresi(Saat)"
      }
    },

    totalwork: {
      title: {
        en: "Total Workload (Hours)",
        tr: "Toplam İş Yükü (Saat)"
      }
    },

    totalworksaat: {
      title: {
        en: "Total Workload / 30 (Hours)",
        tr: "Toplam İş Yükü / 30 (s)"
      }
    },


    code: {
      title: {
        en: "Code",
        tr: "Kod"
      }
    },

    tp: {
      title: {
        en: "T+P",
        tr: "T+U"
      }
    },


    kredi: {
      title: {
        en: "Credits",
        tr: "Kredi"
      }
    },
    akts: {
      title: {
        en: "ECTS",
        tr: "AKTS"
      }
    },
    derskazanim: {
      title: {
        en: "Ders Kazanımları",
        tr: "Course Outcomes"
      }
    },
    kazanilanderece: {
      title: {
        en: "Qualification Awarded",
        tr: "Kazanılan Derece"
      }
    },
    kariyersecenekleri: {
      title: {
        en: "Occupational Profile of Graduates",
        tr: "Kariyer Seçenekleri"
      }
    },
    akademikgelecek: {
      title: {
        en: "Access to Further Studies",
        tr: "Akademik Gelecek"
      }
    },
    mezuniyetsartlari: {
      title: {
        en: "Graduation Requirements",
        tr: "Mezuniyet Şartları"
      }
    },
    derecesartlari: {
      title: {
        en: "Qualification Requirements and Rules",
        tr: "Kazanılan Derece Şartları"
      }
    },
    sinavlardegerlendirme: {
      title: {
        en: "Examinations, Assessment and Grading",
        tr: "Sınavlar, Değerlendirme ve Notlandırma"
      }
    },
    nasilkayit: {
      title: {
        en: "How Can I Register?",
        tr: "Nasıl Kayıt Olabilirim?"
      }
    },

    coursebilgi: {
      title: {
        en: "Course İnformation",
        tr: "Ders Bilgileri"
      }
    },

    coursetitle: {
      title: {
        en: "Course Title",
        tr: "Dersin Adı"
      }
    },


    coursecode: {
      title: {
        en: "Course Code",
        tr: "Dersin Kodu"
      }
    },

    dersduzey: {
      title: {
        en: "Course Level",
        tr: "Dersin Düzeyi"
      }
    },

    yariyil: {
      title: {
        en: "Semester",
        tr: "Yarıyıl"
      }
    },

    dersdili: {
      title: {
        en: "Language of Instruction",
        tr: "Dersin Dili"
      }
    },

    dersiverenler: {
      title: {
        en: "Instructors",
        tr: "Dersi Verenler"
      }
    },

    amacvekazanimlar: {
      title: {
        en: "Purpose and Achievements",
        tr: "Amaç ve Kazanımlar"
      }
    },

    haftalikdersakisi: {
      title: {
        en: "Weekly Course Flow",
        tr: "Haftalık Ders Akışı"
      }
    },
    konu: {
      title: {
        en: "Subject",
        tr: "Konu"
      }
    },
    hafta: {
      title: {
        en: "Week",
        tr: " Hafta"
      }
    },

    ogrenme: {
      title: {
        en: "	Learning Activities",
        tr: "Öğrenme Faaliyetleri "
      }
    },

   yontem : {
      title: {
        en: "Method",
        tr: "Yontem"
      }
    },


    olcmedegerlendirme: {
      title: {
        en: "Quantification and Consideration",
        tr: "Ölçme ve Değerlendirme"
      }
    },

    fakulteler: {
      title: {
        en: "Faculties",
        tr: "Fakülteler"
      }
    },

    enstituler: {
      title: {
        en: "Institutes",
        tr: "Enstitüler"
      }
    },

    konservatuar: {
      title: {
        en: "State Conservatory",
        tr: "Konservatuvar"
      }
    },

    myo: {
      title: {
        en: "Vocational Schools",
        tr: "Meslek Yüksekokulları"
      }
    },


    yuksekokul: {
      title: {
        en: "College",
        tr: "Yüksekokullar"
      }
    },


    idaribirimler: {
      title: {
        en: "Administrative Offices",
        tr: "İdari Birimler"
      }
    },


    koordinatorlukler: {
      title: {
        en: "Coordinatorships",
        tr: "Koordinatörlükler"
      }
    },


    merkezler: {
      title: {
        en: "Centers",
        tr: "Merkezler"
      }
    },


    hastaneler: {
      title: {
        en: "Hospitals",
        tr: "Hastaneler"
      }
    },


    tumu: {
      title: {
        en: "Show All",
        tr: "Tümünü Görüntüle"
      }
    },

    tum: {
      title: {
        en: "All",
        tr: "Tümü"
      }
    },


    akademikgelismeler: {
      title: {
        en: "Updates",
        tr: "Akademik Gelişmeler"
      }
    },
    songuncellemer: {
      title: {
        en: "Latest Publications",
        tr: "Son Yayınlar"
      }
    },
    akademikpersonel: {
      title: {
        en: "Faculty",
        tr: "Akademik Personel"
      }
    },

    duyurular: {
      title: {
        en: "Announcements",
        tr: "Duyurular"
      }
    },

    programlar: {
      title: {
        en: "Programs",
        tr: "Programlar"
      }
    },

    faks: {
      title: {
        en: "Fax",
        tr: "Faks"
      }
    },

    eposta: {
      title: {
        en: "E-Mail",
        tr: "E-Posta"
      }
    },

    derece: {
      title: {
        en: "Degree",
        tr: "Derece"
      }
    },
    egitimturu: {
      title: {
        en: "Mode of Study",
        tr: "Eğitim Türü"
      }
    },
    programtipi: {
      title: {
        en: "Type",
        tr: "Program Tipi"
      }
    },
    programdili: {
      title: {
        en: "Program Language",
        tr: "Program Dili"
      }
    },
    programdirektor: {
      title: {
        en: "Program Director",
        tr: "Program Direktörü"
      }
    },



    harita: {
      title: {
        en: "Map",
        tr: "Harita"
      }
    },

    dekan: {
      title: {
        en: "Administrator",
        tr: "Dekan"
      }
    },
    mudur:{
      title: {
        en: "Administrator",
        tr: "Müdür"
      }
    },
    telefon: {
      title: {
        en: "Phone",
        tr: "Phone"
      }
    },

    adres: {
      title: {
        en: "Address",
        tr: "Adres"
      }
    },
    onceki: {
      title: {
        en: "Previous",
        tr: "Önceki"
      }
    },
    sonraki: {
      title: {
        en: "Next",
        tr: "Sonraki"
      }
    },
    kurucumuz: {
      title: {
        en: "Our Founder Atatürk",
        tr: "Kurucumuz Atatürk"
      }
    },
    bulten: {
      title: {
        en: "Bulletin",
        tr: "Bülten"
      }
    },
    avesistitle:{
      title:{
        en:"What's Happening",
        tr:"Akademide"
      }
    },
    avesisSubtitle:{
      title:{
        en:"in Academia?",
        tr:"Neler Oluyor"
      }
    },

    yazarlar:{
      title:{
        en:"Authors",
        tr:"Yazarlar"
      }
    },



    avesisicerik:{
      title:{
        en:"Academicians from Ankara University contribute to both the scientific world and our country through their work, leading its development. Through our Academic Data Management System, you can follow the work of our academicians, developments in academia, and updates on newly added and revised publications, projects, and patents.",
        tr:"Ankara Üniversiteli Akademisyenler yapmış oldukları çalışmalar ile hem bilim dünyasına hem de ülkemize katkı sağlıyor, gelişimine öncü oluyor. Akademik Veri Yönetim Sistemimiz üzerinden akademisyenlerimizin  yaptıkları çalışmaları, akademideki gelişmeleri, yeni eklenen ve güncellenen yayın, proje, patent gelişmelerini takip edebilirsiniz."
      }
    }
    ,




    logo1:{
      title:{
        en:"First University of the Republic",
        tr:"Cumhuriyetin İlk Üniversitesi"
      }
    },


    logo2:{
      title:{
        en:"Devletin Fikir Mutfağı",
        tr:" Devletin Fikir Mutfağı"
      }
    },

    logo3:{
      title:{
        en:"Research University",
        tr:"Araştırma Üniversitesi"
      }
    },



    ekutuphane:{
      title:{
        en:" E-Library ",
        tr:"E-Kütüphane"
      }
    },


    edevlethizmetleri:{
        title:{
            en:"E-Devlet Services",
            tr:"E-Devlet Hizmetleri"
        }
    },


    ekampus:{
        title:{
            en:"E-Campus",
            tr:"E-Kampüs"
        }
    },


    ebeyas:{
        title:{
            en:"Electronic Document Management and Archive System",
            tr:"Elektronik Belge Yönetimi ve Arşiv Sistemi"
        }
    },


    malzemeportalı:{
        title:{
            en:"Open Course Material Portal",
            tr:"Açık Ders Malzeme Portalı"
        }
    },

    akademikarsivsistemi:{
        title:{
            en:"Academic Archive System",
            tr:"Akademik Arşiv Sistemi"
        }
    },

    ekdersyazilimi:{
        title:{
            en:"Additional Course Software",
            tr:"Ek Ders Yazılımı"
        }
    },


    akademikveriys:{
        title:{
            en:"Academic Data Management System",
            tr:"Akademik Veri Yönetim Sistemi"
        }
    },





    etkinlikler:{
        title:{
            en:"Events",
            tr:"Etkinlikler"
        }
    },


    ehizmetler:{
        title:{
            en:"E-services",
            tr:"E-hizmetler"
        }
    },


    rektorluk:{
        title:{
            en:"Ankara University Rectorate",
            tr:"Ankara Üniversitesi Rektörlüğü"
        }
    },


    haberlerigoruntule:{
        title:{
            en:"View the News",
            tr:"Haberleri Görüntüle"
        }
    },


    teknofest:{
        title:{
            en:"Techno Fest",
            tr:"Teknofest"
        }
    },

  };

  constructor() { }

  getTranslation(key: keyof Translation, lang: "en" | "tr"): string {
    if (!this.translations || !this.translations[key]) return "";

    return this.translations[key].title[lang] || "";
  }
}
