import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return true;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
    route.data['storedHandle'] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data['storedHandle'];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return route.data['storedHandle'] || null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
