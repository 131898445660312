// logging.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor() { }

  logNotFound(url: string): void {
    console.error(`404 Not Found: ${url}`);
    // Burada loglama işlemini istediğiniz gibi özelleştirebilirsiniz
  }
}