import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemDetailModel } from 'src/app/contracts/base/news-detail-model';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
import { TranslationService } from 'src/app/services/translation.services';
interface UrlData {
  type: string;
  getMethod: string;
}


@Component({
  selector: 'app-section-event-detail',
  templateUrl: './section-event-detail.component.html',
  styleUrls: ['./section-event-detail.component.css'],
})
export class SectionEventDetailComponent implements OnInit {
  method: string | any;

  slug: string | any;
  currentLang="";
  items: ItemDetailModel | any;

  breadcumb: Breadcrumb[] = [{
    label:'Etkinlikler',
    url:"/kategori/etkinlikler/1"
  }];
  @Input() data: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClientService: CMSDynamicService,
    private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService,
    private seoService: SeoService
  ) {
    this.method = this.activatedRoute.snapshot.params['type'];

    this.slug = this.activatedRoute.snapshot.params['slug'];
  }

  async ngOnInit() {
    window.scrollTo(0, 0);
    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
     this.translationServices();

    });


  }

  async translationServices() {
    const lang: 'en' | 'tr' = this.storageService.getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';
      await this.getNews();


  }

  content="";
  async getNews() {
    const promiseData: ItemDetailModel | any = await this.httpClientService.get(
      'CMS',
      'GetMainEventsSlug',
      '',
      1,
      'tr-TR',
      '&categorySlug=' + this.slug
    );

    this.items = promiseData;
    this.content =this.items.item.icerik.replace('<h1>&nbsp;</h1>', '')
    this.breadcumb.push({
      label:this.items.item.category.name,
      url: "/kategori/etkinlikler/"+this.items.item.category.slug,
    });

    this.updateMetaTags(this.items.item.baslik, this.items.item.manset);
  }
  updateMetaTags(titlex: string = '', newsimage: string = ''): void {
    const title =
      this.currentLang == 'tr-TR'
        ? titlex + ' | Ankara Üniversitesi'
        : titlex + ' | Ankara University';

    const description =
      this.currentLang == 'tr-TR'
        ? 'Cumhuriyetin İlk Üniversitesi '
        : 'The First University of the Republic';
    const keywords = ['Ankara', 'üniversite', 'etkinlikler', titlex];
    const authors = [
      this.currentLang == 'tr-TR' ? 'Ankara Üniversitesi' : 'Ankara University',
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl = newsimage
      ? newsimage
      : 'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/etkinlik/' + this.slug;

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      titlex,
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );
    this.breadcumb.push({
      label:title.split('|')[0],
      url: canonicalUrl,
    });
    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }

  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);

    return splitDate[0].split('-')[2];
  }
  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }
  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }

  ayIsimAl(ayIndex: number): string {
    switch (ayIndex) {
      case 0:
        return 'Ocak';
      case 1:
        return 'Şubat';
      case 2:
        return 'Mart';
      case 3:
        return 'Nisan';
      case 4:
        return 'Mayıs';
      case 5:
        return 'Haziran';
      case 6:
        return 'Temmuz';
      case 7:
        return 'Ağustos';
      case 8:
        return 'Eylül';
      case 9:
        return 'Ekim';
      case 10:
        return 'Kasım';
      case 11:
        return 'Aralık';
      default:
        return '';
    }
  }
}
