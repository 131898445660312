import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import countriesData from 'src/assets/custom-data/countries.json';

@Component({
  selector: 'app-world-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class WorldMapComponent implements OnInit {
  private map!: L.Map;

  ngOnInit(): void {
    this.initMap();
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [0, 0],
      zoom: 2,
      maxBounds: [[-90, -180], [90, 180]], // Harita sınırları
      maxBoundsViscosity: 1.0
    });

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: ''
    }).addTo(this.map);

    window.addEventListener('resize', () => {
      this.map.invalidateSize();
    });
    // Ülkelerden marker ekleme
    countriesData.forEach((country: any) => {
      if (country.latlng && country.latlng.length === 2) {
        const marker = L.marker([country.latlng[0], country.latlng[1]])
          .addTo(this.map)
          .bindPopup(this.createPopupContent(country));

        // Bayrak ekleme
        const flagIcon = L.divIcon({
          html: `<img src="${country.flags.png}" alt="Flag" style="width: 20px; height: auto;" />`,
          iconSize: [20, 20], // Bayrak ikonunun boyutu
          className: '' // Gereksiz CSS sınıfını kaldırdık
        });

        marker.setIcon(flagIcon);
      }
    });
  }

  private createPopupContent(country: any): string {
    const graduatesList = country.graduates?.map((grad: any) => `<li>${grad.field}: ${grad.count}</li>`).join('') || 'No graduates data';
    return `
      <div>
        <h5>${country.name.common}</h5>
        <p>Capital: ${country.capitalInfo?.latlng ? country.capitalInfo.latlng.join(', ') : 'N/A'}</p>
        <p>Coordinates: ${country.latlng.join(', ')}</p>
        <ul>${graduatesList}</ul>
      </div>
    `;
  }
}
