<div class="page-wraper">
  <!-- CONTENT START -->
  <div class="page-content">
    <app-section-inner-banner
      title="Etkinlikler"
      [breadcrumbs]="breadcumb"
    ></app-section-inner-banner>
    <div class="section-full p-t80 p-b50 bg-gray-light">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 m-b30">
            <!-- BLOG START -->
            <div class="blog-post date-style-2 blog-lg" *ngIf="items">
              <div class="wt-post-media wt-img-effect zoom-slow">
                <a><img src="{{ items.item.manset }}" alt="" /></a>
              </div>
              <div class="wt-post-info bg-white p-t30 p-l20 p-r20">
                <div class="wt-post-meta">
                  <ul>
                    <li class="post-category">
                      <span>{{ items.item.category.name }}</span>
                    </li>
                    <li class="post-date">
                      {{
                        transform(
                          items.item.eventDateTime != null
                            ? items.item.eventDateTime
                            : items.item.createdDate
                        )
                      }}
                      {{
                        ayYaz(
                          items.item.eventDateTime != null
                            ? items.item.eventDateTime
                            : items.item.createdDate
                        )
                      }}
                      {{
                        transformYil(
                          items.item.eventDateTime != null
                            ? items.item.eventDateTime
                            : items.item.createdDate
                        )
                      }}

                      {{ items.item.updatedDate != null ? "" : "" }}
                    </li>
                  </ul>
                </div>
                <div class="wt-post-title">
                  <h2 class="post-title">{{ items.item.baslik }}</h2>
                </div>

                <div class="wt-post-text" [innerHTML]="content"></div>
              </div>
            </div>

            <!--  <div class="widget bg-white  widget_tag_cloud">
             <h4 class="tagcloud">Tags</h4>
             <div class="tagcloud">
                 <a routerLink="/blog-list">Analytics</a>

             </div>
         </div> -->

            <!-- BLOG END -->
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 rightSidebar m-b30">
            <aside class="side-bar">
              <div class="widget recent-posts-entry p-a20">
                <div class="text-left m-b30">
                  <h3 class="widget-title">Diğer</h3>
                </div>
                <div class="section-content" *ngIf="items">
                  <div class="widget-post-bx">
                    <div
                      class="widget-post clearfix"
                      *ngFor="let object of items.items"
                    >
                      <div class="wt-post-media">
                        <img
                          src="{{ object.manset ?? object.banner }}"
                          alt=""
                        />
                      </div>
                      <div class="wt-post-info">
                        <div class="wt-post-header">
                          <h6 class="post-title">
                            <a href="/etkinlik/{{ object.url }}">{{
                              object.baslik
                            }}</a>
                          </h6>
                        </div>
                        <div class="wt-post-meta">
                          <ul></ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
