import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CMSDynamicService } from './cms-service';
import { StorageService } from '../local-storage-serves';


@Injectable({
  providedIn: 'root',
})
export class AvesisAuthService {
  constructor(
    private jwtHelper: JwtHelperService,
    private storageService: StorageService
  ) {}

  identityCheck() {

    const token: string = this.storageService.getData('avesis_accessToken') || '';

    //const expirationDate: Date = this.jwtHelper.getTokenExpirationDate(token);
    let expired: boolean;
    try {
      expired = this.jwtHelper.isTokenExpired(token);
    } catch {
      expired = true;
    }
    _avesisAccessToken =  this.storageService.getData('avesis_accessToken') || '';
    _avesisIsAuthenticated = token != null && !expired;
  }


  get avesisIsAuthenticated(): boolean {
    return _avesisIsAuthenticated;
  }

  get _avesisAccessToken():string{
    return _avesisAccessToken;
  }
}

export let _avesisIsAuthenticated: boolean;
export let _avesisAccessToken:string;
