import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { RehberEntity } from 'src/app/contracts/cms/rehber-entity';
import { CMSDynamicService } from 'src/app/services/models/cms-service';


@Component({
  selector: 'app-rehber',
  templateUrl: './rehber.component.html',
  styleUrls: ['./rehber.component.css']
})
export class RehberComponent implements OnInit {
  people: RehberEntity[] = [];
  searchQuery: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';
  sortField: string = '';

  constructor(private httpClientService: CMSDynamicService,) { }

  ngOnInit(): void {
    this.searchQuery = '';
  }

  async onSearchChange(val: any): Promise<void> {

    var query = val.target.value;

    if (query.length >= 3) {
      try {
        const response = await this.httpClientService.getRehber(query);
        if (response.success) {
          this.people = response.data;
          this.sortData();
        } else {
          console.error('Search error:', response.message);
          this.people = [];
        }
      } catch (error) {
        console.error('Search error:', error);
        this.people = [];
      }
    } else {
      this.people = [];
    }
  }

  sortBy(field: string): void {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortDirection = 'asc';
    }
    this.sortData();
  }

  sortData(): void {
    if (!this.sortField) return;

    this.people.sort((a, b) => {
      const aValue = a[this.sortField as keyof RehberEntity];
      const bValue = b[this.sortField as keyof RehberEntity];

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return this.sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }

      return this.sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1);
    });
  }
}
