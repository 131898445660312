<div class="page-wraper">
  <div class="page-content">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- SERVICE DETAIL SECTION START -->
    <div
      class="section-full small-device p-t80 p-b50 bg-white"
      *ngIf="programs"
    >
      <div class="section-content">
        <div class="container">
          <div class="row">
            <div
              class="col-md-12 faculty-header d-flex align-items-center"
              *ngIf="unitDetail != null"
            >
              <img
                src="https://dizin.ankara.edu.tr/assets/{{ unitDetail.Logo }}"
                alt="{{ unitDetail.Baslik }}"
              />
              <div>
                <h2>{{ programs.data.program.title[getLang] }}</h2>
                <h5>{{ unitDetail.Baslik }}</h5>

                <a
                  href="{{ unitDetail.Web_Sitesi }}"
                  target="_blank"
                  class="btn btn-primary btn-sm"
                  >Website</a
                >
                <a
                  href="{{ unitDetail.location }}"
                  target="_blank"
                  class="btn btn-secondary btn-sm"
                  >Harita</a
                >
              </div>
            </div>

            <div class="col-md-12 p-b10">
              <div class="content text-center">
                <div class="info-group row">
                  <div class="info-item col-md-1">
                    <strong>{{ derecex }}:</strong>
                    <span>{{ programs.data?.degree?.name[getLang] }}</span>
                  </div>
                  <div class="info-item col-md-3">
                    <strong>{{ programtipi }}:</strong>
                    <span>{{
                      programs.data.program.program_type[getLang]
                    }}</span>
                  </div>
                  <div class="info-item col-md-2">
                    <strong>{{ programdili }}:</strong>
                    <span>{{
                      programs.data.program.education_language[getLang]
                    }}</span>
                  </div>
                  <div class="info-item col-md-2">
                    <strong>{{ egitimturu }}:</strong>
                    <span>{{ programs.data.program.study_type[getLang] }}</span>
                  </div>
                  <div class="info-item col-md-3">
                    <strong>{{ programturu }}</strong>
                    <span>{{
                      programs.data.program.head_of_department[getLang]
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 rightSidebar p-t10">
              <div class="all_services">
                <ul class="list-group">
                  <li>
                    <a
                      href="programlar/{{degreeId}}/{{unit}}/{{param}}#about"
                      class="list-group-item-action active"
                      >{{ tanitim }}</a
                    >
                  </li>
                  <li>
                    <a href="programlar/{{degreeId}}/{{unit}}/{{param}}#goals">{{ programamac }}</a>
                  </li>
                  <li>
                    <a href="programlar/{{degreeId}}/{{unit}}/{{param}}#lessons">{{ courselist }}</a>
                  </li>
                  <li>
                    <a href="programlar/{{degreeId}}/{{unit}}/{{param}}#outcomes">{{ derskazanim }}</a>
                  </li>
                  <li>
                    <a href="programlar/{{degreeId}}/{{unit}}/{{param}}#qualification_awarded">{{
                      kazanilanderece
                    }}</a>
                  </li>

                  <li>
                    <a href="programlar/{{degreeId}}/{{unit}}/{{param}}#graduates_profile">{{
                      kariyersecenekleri
                    }}</a>
                  </li>

                  <li>
                    <a href="programlar/{{degreeId}}/{{unit}}/{{param}}#access_further">{{
                      akademikgelecek
                    }}</a>
                  </li>

                  <li>
                    <a href="programlar/{{degreeId}}/{{unit}}/{{param}}#graduation_requirements">{{
                      mezuniyetsartlari
                    }}</a>
                  </li>
                  <li>
                    <a href="programlar/{{degreeId}}/{{unit}}/{{param}}#qualification_requirements">{{
                      derecesartlari
                    }}</a>
                  </li>

                  <li>
                    <a href="programlar/{{degreeId}}/{{unit}}/{{param}}#examinations"
                      >{{ sinavlardegerlendirme }}
                    </a>
                  </li>

                  <li>
                    <a href="programlar/{{degreeId}}/{{unit}}/{{param}}#admission_requirements"
                      >{{ nasilkayit }}
                    </a>
                  </li>
                </ul>
              </div>

              <div
                class="service-side-btn m-b30 site-bg-secondry p-a20 text-white"
              ></div>
            </div>

            <div class="col-lg-8 col-md-12">
              <div class="section-content" *ngIf="programs != null">
                <!-- TITLE START -->
                <div class="text-left" id="about">
                  <h3 class="wt-title">
                    <mat-toolbar>
                      {{ tanitim }}
                    </mat-toolbar>
                  </h3>
                </div>
                <!-- TITLE END -->
                <div class="wt-box services-etc m-b30">
                  <p>
                    {{ programs.data.program.about[getLang] }}
                  </p>
                  <div class="wt-info"><hr /></div>
                </div>

                <div class="text-left" id="goals">
                  <h3 class="wt-title">
                    <mat-toolbar>
                      {{ programamac }}
                    </mat-toolbar>
                  </h3>
                </div>
                <!-- TITLE END -->
                <div class="wt-box services-etc m-b30">
                  <p>
                    {{ programs.data.program.goals[getLang] }}
                  </p>
                  <div class="wt-info"><hr /></div>
                </div>

                <div id="lessons" *ngIf="programs.data.courses != null">
                  <div class="text-left" id="lessons">
                    <h3 class="wt-title">
                      <mat-toolbar>
                        {{ courselist }}
                      </mat-toolbar>
                    </h3>
                  </div>
                  <div
                    class="wt-accordion acc-bg-gray"
                    *ngFor="let course of programs.data.courses"
                    id="accordion{{slugify(course.semester)}}"
                  >
                    <div class="panel wt-panel">
                      <div class="acod-head">
                        <h4 class="acod-title">
                          <a
                            data-toggle="collapse"
                            href="#collapseOne{{slugify(course.semester) }}"
                            attr.data-parent="#accordion{{slugify(course.semester) }}"
                            class="collapsed"
                          >
                            {{ course.semester }}. {{ yariyil }}
                            <span class="indicator"
                              ><i class="fa fa-plus"></i
                            ></span>
                          </a>
                        </h4>
                      </div>
                      <div
                        id="collapseOne{{slugify(course.semester)}}"
                        class="acod-body collapse bg-gray"
                      >
                        <div class="acod-content p-a15">
                          <div
                            class="wt-accordion acc-bg-gray"
                            *ngFor="
                              let subcourse of course.course_type;
                              let i = index
                            "
                            id="accordion{{ i + 1000 + '-' + slugify(course.semester) }}"
                          >
                            <div class="panel wt-panel">
                              <div class="acod-head">
                                <h4 class="acod-title">
                                  <a
                                    data-toggle="collapse"
                                    href="#collapse-{{
                                      slugify(subcourse.title.tr)
                                    }}{{ i + 1000 }}-{{
                                      slugify(subcourse.title.tr)
                                    }}"
                                    attr.data-parent="#accordion{{
                                      i + 1000 + '-' + slugify(course.semester)
                                    }}"
                                    class="collapsed"
                                  >
                                    {{ subcourse.title[getLang] }}
                                    <span class="indicator"
                                      ><i class="fa fa-plus"></i
                                    ></span>
                                  </a>
                                </h4>
                              </div>
                              <div
                                id="collapse-{{ slugify(subcourse.title.tr) }}{{
                                  i + 1000
                                }}-{{ slugify(subcourse.title.tr) }}"
                                class="acod-body collapse bg-gray"
                              >
                                <div class="acod-content p-a15">
                                  <table
                                    class="table table-striped"
                                    *ngFor="
                                      let detail of subcourse.course_type_detail
                                    "
                                  >
                                    <thead>
                                      <tr>
                                        <th scope="col">{{ coursetitle }}</th>
                                        <th scope="col">{{ coursecode }}</th>
                                        <th scope="col">{{ tp }}</th>
                                        <th scope="col">{{ kredi }}</th>
                                        <th scope="col">{{ akts }}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <app-popup-tabs
                                            [title]="detail.title[getLang]"
                                            [id]="detail.id"
                                          ></app-popup-tabs>
                                        </td>
                                        <td>{{ detail.code }}</td>
                                        <td>{{ detail.tp_hour }}</td>
                                        <td>{{ detail.credits }}</td>
                                        <td>{{ detail.ects }}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-left" id="outcomes">
                  <h3 class="wt-title">
                    <mat-toolbar>
                      {{ derskazanim }}
                    </mat-toolbar>
                  </h3>
                </div>


                <!-- TITLE END -->
                <div class="wt-box services-etc m-b30">
                  <table class="table table-striped">
                    <tbody>
                      <tr
                        *ngFor="let outcomes of programs.data.program.outcomes"
                      >
                        <td>{{ outcomes.title[getLang] }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div class="wt-info"><hr /></div>
                </div>

                <div class="text-left" id="qualification_awarded">
                  <h3 class="wt-title">
                    <mat-toolbar>
                      {{ kazanilanderece }}
                    </mat-toolbar>
                  </h3>
                </div>
                <!-- TITLE END -->
                <div class="wt-box services-etc m-b30">
                  <p>
                    {{ programs.data.program.qualification_awarded[getLang] }}
                  </p>
                  <div class="wt-info"><hr /></div>
                </div>

                <div class="text-left" id="graduates_profile">
                  <h3 class="wt-title">
                    <mat-toolbar>
                      {{ kariyersecenekleri }}
                    </mat-toolbar>
                  </h3>
                </div>
                <!-- TITLE END -->
                <div class="wt-box services-etc m-b30">
                  <p>
                    {{ programs.data.program.graduates_profile[getLang] }}
                  </p>
                  <div class="wt-info"><hr /></div>
                </div>

                <div class="text-left" id="access_further">
                  <h3 class="wt-title">
                    <mat-toolbar>
                      {{ akademikgelecek }}
                    </mat-toolbar>
                  </h3>
                </div>
                <!-- TITLE END -->
                <div class="wt-box services-etc m-b30">
                  <p>
                    {{ programs.data.program.access_further[getLang] }}
                  </p>
                  <div class="wt-info"><hr /></div>
                </div>

                <div class="text-left" id="graduation_requirements">
                  <h3 class="wt-title">
                    <mat-toolbar>
                      {{ mezuniyetsartlari }}
                    </mat-toolbar>
                  </h3>
                </div>
                <!-- TITLE END -->
                <div class="wt-box services-etc m-b30">
                  <p>
                    {{ programs.data.program.graduation_requirements[getLang] }}
                  </p>
                  <div class="wt-info"><hr /></div>
                </div>

                <div class="text-left" id="qualification_requirements">
                  <h3 class="wt-title">
                    <mat-toolbar>
                      {{ derecesartlari }}
                    </mat-toolbar>
                  </h3>
                </div>
                <!-- TITLE END -->
                <div class="wt-box services-etc m-b30">
                  <p>
                    {{
                      programs.data.program.qualification_requirements[getLang]
                    }}
                  </p>
                  <div class="wt-info"><hr /></div>
                </div>

                <div class="text-left" id="examinations">
                  <h3 class="wt-title">
                    <mat-toolbar>
                      {{ sinavlardegerlendirme }}
                    </mat-toolbar>
                  </h3>
                </div>
                <!-- TITLE END -->
                <div class="wt-box services-etc m-b30">
                  <p>
                    {{ programs.data.program.examinations[getLang] }}
                  </p>
                  <div class="wt-info"><hr /></div>
                </div>

                <div class="text-left" id="admission_requirements">
                  <h3 class="wt-title">
                    <mat-toolbar>
                      {{ nasilkayit }}
                    </mat-toolbar>
                  </h3>
                </div>
                <!-- TITLE END -->
                <div class="wt-box services-etc m-b30">
                  <p>
                    {{ programs.data.program.admission_requirements[getLang] }}
                  </p>
                  <div class="wt-info"><hr /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SERVICE DETAIL SECTION END -->
  </div>
</div>
