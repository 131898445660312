import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from '@angular/router';
import { filter } from 'rxjs';
import { HeaderEntity } from 'src/app/contracts/cms/header-entity';
import { MenuEntity } from 'src/app/contracts/cms/menu-entity';
import { PopupEntity } from 'src/app/contracts/cms/popup-entity';
import { ShortCutNavEntity } from 'src/app/contracts/cms/shortcut-nav-entity';
import { DarkModeService } from 'src/app/services/dark-mode.service';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { ResponsiveService } from 'src/app/services/responsive-service';
import { TranslationService } from 'src/app/services/translation.services';

declare var jQuery: any;
declare var toggleSwitch: any;
declare var mobile_nav_2: any;
// Mobile side drawer function by = custom.js
declare var mobile_side_drawer: any;
@Component({
  selector: 'app-section-header4',
  templateUrl: './section-header4.component.html',
  styleUrls: ['./section-header4.component.css'],
})
export class SectionHeader4Component implements OnInit, OnChanges {
  @Input() headerData: HeaderEntity | any;
  @Input() navData: MenuEntity | any;

  @Input() popupData: PopupEntity[] | any;

  popup: PopupEntity[] | any;
  @Output() headerDataChange = new EventEmitter<HeaderEntity>();
  @Output() navDataChange = new EventEmitter<MenuEntity>();
  currentLang: string = 'tr-TR';
  controlHome: boolean = false;
  controlAday: boolean = false;
  isScrolled: boolean = false;
  showModal: boolean = false;
  public isMenuVisible: boolean = false;

  logo1 = '';

  logo2 = '';

  logo3 = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private langService: LangService,
    private darkModeService: DarkModeService,
    private storageService: StorageService,
    private responsiveService: ResponsiveService,
    private translationService: TranslationService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['navData'] && changes['navData'].currentValue) {
      this.setupMenu();

      this.updateDropdownData();
    }

    if (changes['popupData'] && changes['popupData'].currentValue) {
      this.popup = changes['popupData'].currentValue;
    }

    this.translationServices();
  }

  translationServices() {
    const lang: 'en' | 'tr' = this.storageService
      .getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';

    this.logo1 = this.translationService.getTranslation('logo1', lang);
    this.logo2 = this.translationService.getTranslation('logo2', lang);

    this.logo3 = this.translationService.getTranslation('logo3', lang);
  }

  searchValue: string = '';
  isMobile: boolean = false;
  activeMenus: Set<any> = new Set();
  activeMenu: any = null;

  issubmenu = 'sub-menu';
  ishaschild = 'has-child';
  isnavactive = 'nav-active';
  modalStatus: boolean = false;
  modalDisplayDuration: number = 24 * 60 * 60 * 1000; // 24 saat
  closeModal() {
    this.modalStatus = false;

    this.storageService.saveData('hasSeenModal', 'true');
    this.storageService.saveData('modalTimestamp', Date.now().toString());

    jQuery('#myModal1').modal('hide');
  }
  private setupMenu() {
    if (this.responsiveService.isMobileScreen()) {
      this.isMobile = true;
    }
  }
  hasChildren(menuItem: any): boolean {
    var status = false;

    if (Array.isArray(menuItem.child) && menuItem.child.length > 1) {
      status = true;
    }

    return status;
  }
  toggleMenu(menuItem: any) {
    if (this.activeMenus.has(menuItem)) {
      this.activeMenus.delete(menuItem);
    } else {
      this.activeMenus.add(menuItem);
    }
  }
  // Method to show submenu
  showSubMenu(navItem: any): void {
    navItem.isOpen = true;
  }

  toggletopMenu() {
    this.isMenuVisible = !this.isMenuVisible;
  }

  isActiveMenu: boolean = false;
  toggleSideDrawer(): void {
    this.isActiveMenu = !this.isActiveMenu;
  }
  // Method to hide submenu
  hideSubMenu(navItem: any): void {
    navItem.isOpen = false;
  }
  isActive(menuItem: any): boolean {
    return this.activeMenus.has(menuItem);
  }
  currentText: number = 1;
  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.controlHome = event.urlAfterRedirects === '/';
        this.controlAday = !event.urlAfterRedirects.startsWith('/aday-ogrenci');
      });

    this.controlHome = this.router.url === '/' || this.router.url === 'index';

    this.controlAday = this.router.url !== 'aday-ogrenci';
    this.langService.currentLangCode$.subscribe((langCode) => {
      this.currentLang = langCode;
      this.selectedValue = langCode;
      this.changeLanguage(langCode);
    });

    this.translationServices();
    const hasSeenModal = this.storageService.getData('hasSeenModal');
    const modalTimestamp = this.storageService.getData('modalTimestamp');

    if (
      !hasSeenModal ||
      !modalTimestamp ||
      this.isTimestampExpired(modalTimestamp)
    ) {
      this.modalStatus = true;
    }

    this.startAnimationLoop();

    const savedPosition = this.storageService.getData('isMovedDown');
    if (savedPosition === 'true') {
      this.isMovedDown = true;
    }
  }
  isTimestampExpired(timestamp: string): boolean {
    const currentTime = Date.now();
    const timestampNumber = Number(timestamp);

    return currentTime - timestampNumber > this.modalDisplayDuration;
  }
  createMobileNav() {
    (function ($) {
      setTimeout(function () {
        mobile_side_drawer();
      }, 400);
    })(jQuery);
  }
  startAnimationLoop(): void {
    // Sayıları ve süreyi ayarlayın
    const numberOfTexts = 3;
    const interval = 4500; // 4.5 saniye

    setInterval(() => {
      // Mevcut metni güncelleyin
      this.currentText = (this.currentText % numberOfTexts) + 1;
    }, interval); // Toplam süre
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= 50) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }

  selectedValue = this.storageService.getData('siteLanguage');
  selectedName = 'Türkçe';
  icon = 'assets/images/flag/tr.png';
  search(): void {
    if (!this.searchValue.trim()) {
      // Input değeri boşsa işlemi durdur
      return;
    }

    // Arama URL'sini oluştur
    const searchUrl = `/arama/q=${encodeURIComponent(this.searchValue)}`;

    // Yönlendirme işlemi
    this.router.navigateByUrl(searchUrl);
  }

  @ViewChild('stickyHeader') stickyHeader: ElementRef | any;
  isMovedDown = false;
  setPosition() {
    this.isMovedDown = !this.isMovedDown;

  }

  togglePosition() {

    // Durumu tersine çevir
    this.isMovedDown = !this.isMovedDown;


    this.setHeaderPosition();
  }

  setHeaderPosition() {
    const stickyHeaderElement = this.stickyHeader.nativeElement;

    if (this.isMovedDown) {
      stickyHeaderElement.style.top = 'auto';
    }
  }

  changeLanguage(language: string) {
    this.languages.forEach((element) => {
      if (element.val === language) {
        this.selectedName = element.name;
        this.icon = element.src;
      }
    });
  }

  selectSite(event: any) {
    this.changeLanguage(event.value);
    this.onLanguageChange(event.value);
  }

  onLanguageChange(event: any) {
    this.langService.setLangCode(event);

    // Yeni gelen verilere göre headerData ve navData'yı güncelle
    // Yeni verileri yayınla
    this.headerDataChange.emit(this.headerData);
    this.navDataChange.emit(this.navData);
  }
  toggleDarkMode() {
    this.darkModeService.toggleDarkMode();
  }

  get isDarkMode(): boolean {
    return this.darkModeService.getDarkModeStatus();
  }

  languages = [
    {
      src: 'assets/images/flag/tr.png',
      name: 'TR',
      val: 'tr-TR',
    },
    {
      src: 'assets/images/flag/en.png',
      name: 'EN',
      val: 'en-EN',
    },
    {
      src: 'assets/images/flag/arb.png',
      name: 'AR',
      val: 'arb-ARB',
    },
    {
      src: 'assets/images/flag/jpn.png',
      name: 'JA',
      val: 'jpn-JPN',
    },
    {
      src: 'assets/images/flag/isp.png',
      name: 'ES',
      val: 'isp-ISP',
    },
  ];

  isDisabled(genderVal: string): boolean {
    return genderVal !== 'tr-TR' && genderVal !== 'en-EN';
  }

  private renderedDropDowns: Set<string> = new Set();

  getDropdownItems(title: string) {
    return this.headerData?.topNav.child.filter(
      (item: any) => item.dropDownTitle === title && !item.button
    );
  }

  isFirstDropdown(dropDownTitle: string): boolean {
    // DropDown başlığı varsa ve daha önce gösterilmemişse true döner
    if (dropDownTitle && !this.displayedDropdownTitles.has(dropDownTitle)) {
      this.displayedDropdownTitles.add(dropDownTitle);
      return true;
    }
    return false;
  }

  resetDropdownTracking(): void {
    // Her yeni render işleminden önce bu fonksiyon ile başlıkları sıfırlayın
    this.displayedDropdownTitles.clear();
  }

  dropdownItems: { [key: string]: any[] } = {};
  displayedDropdownTitles: Set<string> = new Set<string>();
  // Dropdown başlıklarını ve öğelerini güncelleyen fonksiyon
  // Dropdown başlıklarını ve öğelerini güncelleyen fonksiyon
  updateDropdownData(): void {
    this.displayedDropdownTitles.clear();
    this.dropdownItems = {};

    if (this.headerData?.topNav?.child) {
      this.headerData.topNav.child.forEach((item: any) => {
        if (item.dropDown && !item.button && item.dropDownTitle) {
          // Başlıkları yalnızca bir kez ekle
          if (!this.displayedDropdownTitles.has(item.dropDownTitle)) {
            this.displayedDropdownTitles.add(item.dropDownTitle);
            this.dropdownItems[item.dropDownTitle] = [];
          }
          // Başlığa ait öğeleri ekle
          this.dropdownItems[item.dropDownTitle].push(item);
        }
      });
    }
  }
}
