import { Component, Input, OnInit } from '@angular/core';
import { ShortCutNavEntity } from 'src/app/contracts/cms/shortcut-nav-entity';
import { LangService } from 'src/app/services/lang-service';

@Component({
  selector: 'app-section-welcome1',
  templateUrl: './section-welcome1.component.html',
  styleUrls: ['./section-welcome1.component.css']
})
export class SectionWelcome1Component implements OnInit {
  currentLang: string = 'tr-TR';
  @Input() data:ShortCutNavEntity|any;

  constructor(  private langService: LangService,) { }

  ngOnInit(): void {
    this.langService.currentLangCode$.subscribe((langCode) => {
      this.currentLang = langCode;

    });
  }

}
