// src/app/loader.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  private timeoutId: any;
  show() {
    this.loadingSubject.next(true);
  }

  hide() {
    this.loadingSubject.next(false);
    this.clearTimeout(); // Loading gizlendikten sonra timeout'u iptal et
  }

  hideAfterDelay(delay: number) {
    this.timeoutId = setTimeout(() => {
      this.hide();
    }, delay);
  }


  private clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId); // Timeout'u iptal et
      this.timeoutId = null;
    }
  }
}
