import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  DoCheck,
} from '@angular/core';
import {
  ActivityHistoryEntity,
  ConferencePaper,
} from 'src/app/contracts/cms/avesis-entities';
import {
  LangService,
  _languageDefaultCode,
} from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { TranslationService } from 'src/app/services/translation.services';

declare var jQuery: any;
declare var services_gallery_carousel_two: any;

@Component({
  selector: 'app-section-services9',
  templateUrl: './section-services9.component.html',
  styleUrls: ['./section-services9.component.css'],
})
export class SectionServices9Component implements OnInit, OnChanges, DoCheck {
  @Input() data: any;

  @Input() activityHistoryData: ConferencePaper[] | any;
  @Input() latestPublicationData: ActivityHistoryEntity[] | any;

  title = '';
  title2 = '';


  academictitle="";
  subtitle="";
  academicmetin="";

  currentLang = 'tr-TR';
  getlang = 'primary';
  activityHistorys: ConferencePaper[] | any;
  latestPublications: ActivityHistoryEntity[] | any;

  private latestPublicationDataPrev: ActivityHistoryEntity[] | any;

  constructor(
    private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['latestPublicationData']) {
      this.latestPublications = changes['latestPublicationData'].currentValue;
      if (this.latestPublications != null) {
        this.createCarousel();
      }
    }

    if (changes['activityHistoryData']) {
      this.activityHistorys = changes['activityHistoryData'].currentValue;
    }
  }

  async ngOnInit(): Promise<void> {
    await this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.getlang = langCode === 'tr-TR' ? 'primary' : 'secondary';
      this.translationServices();
    });
  }

  translationServices() {
    const lang: 'en' | 'tr' = this.storageService.getData('siteLanguage')
    ?.split('-')[0] as 'en' | 'tr';

    this.title =  this.translationService.getTranslation(
      'akademikgelismeler',
      lang
    );

    this.academictitle = this.translationService.getTranslation(
      'avesistitle',
      lang
    );

    this.subtitle =  this.translationService.getTranslation(
      'avesisSubtitle',
      lang
    );

    this.academicmetin= this.translationService.getTranslation(
      'avesisicerik',
      lang
    );



  }

  ngDoCheck(): void {
    if (this.latestPublications !== this.latestPublicationDataPrev) {
      this.latestPublicationDataPrev = this.latestPublications;
      this.createCarousel();
    }
  }

  createCarousel() {
    (function ($) {
      setTimeout(function () {
        services_gallery_carousel_two();
      }, 200);
    })(jQuery);
  }
}
