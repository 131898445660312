<div class="page-wraper">
  <!-- CONTENT START -->
  <div class="page-content">
    <app-section-inner-banner title="Haberler" [breadcrumbs]="breadcumb"  ></app-section-inner-banner>
    <div class="section-full p-t80 p-b50 bg-gray-light">
      <div class="container">
        <!-- BLOG SECTION START -->
        <div class="section-content" *ngIf="newsData != null">
          <div class="row d-flex justify-content-center">
            <div class="{{ newsData.categories!=null && currentLang=='tr-TR'?' col-xl-8 col-lg-8 col-md-8 col-sm-12':' col-xl-12 col-lg-12 col-md-12 col-sm-12'}}">
              <!--Block one-->

              <div class="row" *ngIf="newsData.items != null">
                <div
                  class="blog-post date-style-2  {{ newsData.categories!=null && currentLang=='tr-TR'?' col-md-6':' col-md-4'}}"
                  *ngFor="let item of newsData.items"
                >
                  <div class="wt-post-media wt-img-effect zoom-slow">
                    <a href="haber/{{ item.url }}"
                      ><img src="{{ item.manset + '?' + currentDate}}" alt=""
                    /></a>
                  </div>
                  <div class="wt-post-info bg-white p-t10">
                    <div class="wt-post-meta">
                      <ul>
                        <li class="post-category">
                          <span>{{ item.category.name }}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="wt-post-title">
                      <p class="post-title">
                        <a
                          href="haber/{{ item.url }}"
                          class="site-text-secondry"
                          >{{ item.baslik }}
                        </a>

                        <a
                          *ngIf="postType == 'haberler'"
                          href="haber/{{ item.url }}"
                          class="site-text-secondry"
                          >{{ item.baslik }}
                        </a>
                      </p>
                      <div class="wt-post-readmore"></div>
                      <div class="row justify-content-around">
                        <div class="col-md-6">
                          <a class="btn btn-sm btn-warning post-date"
                            > {{ transform(item.updatedDate!=null ? item.updatedDate: item.createdDate) }}
                            {{ ayYaz(item.updatedDate!=null ? item.updatedDate: item.createdDate) }}
                            {{ transformYil(item.updatedDate!=null ? item.updatedDate: item.createdDate) }}

                            {{item.updatedDate!=null ? "":""}}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="pagination m-b0 p-b0" *ngIf="newsData != null">
                <li class="page-item" *ngIf="newsData.hasPrevious">
                  <a class="page-link" href="javascript:void(0);">{{onceki}}</a>
                </li>
                <li class="page-item" *ngFor="let page of pagesArray">
                  <a
                    class="page-link"
                    routerLink="/kategori/haberler/{{
                      categorySlug ? categorySlug + '/' : ''
                    }}{{ page }}"
                    >{{ page }}</a
                  >
                </li>
                <li class="page-item" *ngIf="newsData.hasNext">
                  <a class="page-link" href="javascript:void(0);">{{sonraki}}</a>
                </li>
              </ul>
            </div>

            <div
              class="col-xl-4 col-lg-4 col-md-12 col-sm-12 rightSidebar m-b30"
              *ngIf="newsData  && currentLang=='tr-TR'"
            >
              <aside class="side-bar">
                <div class="widget widget_services p-a20">
                  <div class="text-left m-b30">
                    <h5 class="widget-title">Kategoriler</h5>
                  </div>
                  <ul>
                    <li *ngFor="let category of newsData.categories">
                      <a href="kategori/haberler/{{ category.slug }}/1">{{
                        category.name
                      }}</a>
                    </li>
                  </ul>
                </div>

                <!-- TAGS -->

                <!-- Social -->
              </aside>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
