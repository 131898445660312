import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ItemDetailModel } from 'src/app/contracts/base/news-detail-model';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
import { TranslationService } from 'src/app/services/translation.services';
interface UrlData {
  type: string;
  getMethod: string;
}
const ayIsimleriTR = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];
const ayIsimleriEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const urlData: UrlData[] | any[] = [
  { type: 'haber', getMethod: 'NewsSlug' },
  { type: 'etkinlik', getMethod: 'EventsSlug' },
  { type: 'duyuru', getMethod: 'NoticesSlug' },
  { type: 'tanitim', getMethod: 'PagesSlug' },
];
@Component({
  selector: 'app-section-post-detail',
  templateUrl: './section-post-detail.component.html',
  styleUrls: ['./section-post-detail.component.css'],
})
export class SectionPostDetailComponent implements OnInit {
  method: string | any;

  slug: string | any;

  items: ItemDetailModel | any;
  breadcumb: Breadcrumb[] = [{
    label:'Haberler',
    url:"/kategori/haberler/1"
  }];
  currentLang = 'tr-TR';
  getlang = 'primary';

  onceki = '';
  sonraki = '';
  @Input() data: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClientService: CMSDynamicService,
    private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService,
    private seoService: SeoService,
    private sanitizer: DomSanitizer
  ) {
    this.method = this.activatedRoute.snapshot.params['type'];

    this.slug = this.activatedRoute.snapshot.params['slug'];
  }


safeContent="";
  async ngOnInit() {
    window.scrollTo(0, 0);
    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.translationServices();
    });
  }
  async translationServices() {
    const lang: 'en' | 'tr' = this.storageService
      .getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';
    await this.getNews();

    this.onceki = this.translationService.getTranslation('onceki', lang);
    this.sonraki = this.translationService.getTranslation('sonraki', lang);
  }

  content:any;
  async getNews() {
    const promiseData: ItemDetailModel | any = await this.httpClientService.get(
      'CMS',
      'GetMainNewsSlug',
      '',
      1,
      this.currentLang,
      '&categorySlug=' + this.slug
    );

    this.items = promiseData;


    this.breadcumb.push({
      label:this.items.item.category.name,
      url: "/kategori/haberler/"+this.items.item.category.slug,
    });
    const unwantedString = '<h1>&nbsp;</h1>';
  this.content= this.sanitizer.bypassSecurityTrustHtml(
    this.items.item.icerik.replace(unwantedString, '')
    );
    this.updateMetaTags(this.items.item.baslik, this.items.item.manset);
  }
  currentDate: Date = new Date();
  updateMetaTags(titlex: string = '', newsimage: string = ''): void {
    const title =
      this.currentLang == 'tr-TR'
        ? titlex + ' | Ankara Üniversitesi'
        : titlex + ' | Ankara University';

    const description =
      this.currentLang == 'tr-TR'
        ? 'Cumhuriyetin İlk Üniversitesi '
        : 'The First University of the Republic';
    const keywords = ['Ankara', 'üniversite', 'haberler', titlex];
    const authors = [
      this.currentLang == 'tr-TR' ? 'Ankara Üniversitesi' : 'Ankara University',
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl = newsimage
      ? newsimage
      : 'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/haber/' + this.slug;

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      titlex,
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );


    this.breadcumb.push({
      label:title.split('|')[0],
      url: canonicalUrl,
    });
    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }

  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);

    return splitDate[0].split('-')[2];
  }
  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }
  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }

  ayIsimAl(ayIndex: number): string {
    const lang = this.currentLang;
    switch (lang) {
      case 'tr-TR':
        return ayIsimleriTR[ayIndex] || '';
      case 'en-EN':
        return ayIsimleriEN[ayIndex] || '';
      default:
        return '';
    }
  }
}
