// http-error.interceptor.ts

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotFoundHandlerService } from './not-found-handller-service';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private notFoundHandler: NotFoundHandlerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.notFoundHandler.handleNotFound(request.url);
        }
        return throwError(error);
      })
    );
  }
}