import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, catchError, lastValueFrom, throwError } from 'rxjs';
import { avesis, environment } from '../../environments/environment';
import { _avesisAccessToken } from './models/avesis-auth.service';

@Injectable({
  providedIn: 'root',
})
export class HttpClientService {
  constructor(private httpClient: HttpClient) {}

  baseUrl = environment.baseUrl;

  errorMsg?: string;

  private url(requestParameter: Partial<RequestParameters>): string {
    return `${
      requestParameter.baseUrl ? requestParameter.baseUrl : this.baseUrl
    }/${requestParameter.controller}${
      requestParameter.action ? `/${requestParameter.action}` : ''
    }`;
  }

  get<T>(
    requestParameter: Partial<RequestParameters>,
    id?: string
  ): Observable<T> {
    let url: string = '';
    if (requestParameter.fullEndPoint) url = requestParameter.fullEndPoint;
    else
      url = `${this.url(requestParameter)}${id ? `/${id}` : ''}${
        requestParameter.queryString ? `?${requestParameter.queryString}` : ''
      }`;

    return this.httpClient.get<T>(url, {
      headers: requestParameter.headers,
      responseType: requestParameter.responseType as 'json',
    });
  }

  async getAvesis<T>(
    queryString: string,
    customUrl: string
  ): Promise<Observable<T>> {
    let url: string = environment.avesisApi + customUrl + '?' + queryString;

    var requestParameter: Partial<RequestParameters>;
    const res: Observable<any> = this.httpClient.get<T>(url, {
      headers: new HttpHeaders({

        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        Authorization: 'Bearer ' + _avesisAccessToken,
      }),
    });

    var res2 = await lastValueFrom(res);
    return res2;
  }

  post<T>(
    requestParameter: Partial<RequestParameters>,
    body: Partial<T>
  ): Observable<T> {
    let url: string = '';
    if (requestParameter.fullEndPoint) {
      url = requestParameter.fullEndPoint;
    } else {
      url = `${this.url(requestParameter)}${
        requestParameter.queryString ? `?${requestParameter.queryString}` : ''
      }`;
    }

    return this.httpClient.post<T>(url, body, {
      headers: requestParameter.headers,
      responseType: requestParameter.responseType as 'json',
    });
  }

  post2<T>(
    requestParameter: Partial<RequestParameters>,
    body: any
  ): Observable<T> {
    let url: string = '';
    if (requestParameter.fullEndPoint) {
      url = requestParameter.fullEndPoint;
    } else {
      url = `${this.url(requestParameter)}${
        requestParameter.queryString ? `?${requestParameter.queryString}` : ''
      }`;
    }

    return this.httpClient.post<T>(url, body, {
      headers: requestParameter.headers,
    });
  }

  obsLogin(requestParameter: Partial<RequestParameters>, body: any) {
    let url: string = 'https://kapi.ankara.edu.tr/mobil/login';

    return this.httpClient.post(url, body, {
      headers: requestParameter.headers,
      responseType: requestParameter.responseType as 'json',
    });
  }

  avesisLogin() {
    let url: string = environment.avesisAuthApi;

    var data =
      'grant_type=client_credentials&client_id=ki-client&client_secret=99FCD2B9-8F0F-4975-AA1D-8491DB0ECF31&scope=avesis-public';

    return this.httpClient.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',

      }),
    });
  }

  put<T>(
    requestParameter: Partial<RequestParameters>,
    body: Partial<T>
  ): Observable<T> {
    let url: string = '';

    url = `${this.url(requestParameter)}${
      requestParameter.queryString ? `?${requestParameter.queryString}` : ''
    }`;

    return this.httpClient.put<T>(url, body, {
      headers: requestParameter.headers,
      responseType: requestParameter.responseType as 'json',
    });
  }

  delete<T>(
    requestParameter: Partial<RequestParameters>,
    id: string
  ): Observable<T> {
    let url: string = '';
    if (requestParameter.fullEndPoint) url = requestParameter.fullEndPoint;
    else
      url = `${this.url(requestParameter)}/?id=${id}${
        requestParameter.queryString ? `?${requestParameter.queryString}` : ''
      }`;

    return this.httpClient.delete<T>(url, {
      headers: requestParameter.headers,
      body: { id: id },
      responseType: requestParameter.responseType as 'json',
    });
  }
}

export class RequestParameters {
  controller?: string;
  action?: string;
  queryString?: string;
  headers?: HttpHeaders;
  baseUrl?: string;
  fullEndPoint?: string;
  responseType?: string = 'json';
}
