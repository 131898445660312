import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private isDarkMode = false;

  constructor() { }

  toggleDarkMode(): void {
    this.isDarkMode = !this.isDarkMode;
  }

  getDarkModeStatus(): boolean {
    return this.isDarkMode;
  }
}