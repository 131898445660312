<div class="page-wraper">
  <!-- CONTENT START -->
  <div class="page-content">
    <app-section-inner-banner [breadcrumbs]="breadcumb"></app-section-inner-banner>

    <div class="section-full p-t0 p-b40">
      <div class="toolbar">
        <div
          class="container d-flex justify-content-center aligment-item-center"
        >
          <button *ngIf="param==undefined"
            class="btn btn-light"
            style="height: 50px; padding-right: 10px"
            value="Tümü"
            (click)="selectLisans($event)"
          >
          {{tum}}
          </button>

          <button *ngIf="param!=undefined"
          class="btn btn-light"
          style="height: 50px; padding-right: 10px"
          value="Tümü"
          (click)="routeProgram()"
        >
        {{tum}}
        </button>

          <select *ngIf="param!=undefined && param==1"
            id="selectOlisans"
            class="selectpicker select-margin ml-auto"
            data-live-search="true"
            title="{{onlisans}}"
            (change)="selectOnlisans($event)"
          >
            <option
              *ngFor="let onlisans of onLisansFilterData"
              value="{{ onlisans.faculty_id }}"
            >
              {{ onlisans.type }}
            </option>
          </select>

          <select *ngIf="param!=undefined && param==2"
            id="selectLisans"
            class="selectpicker select-margin ml-auto"
            data-live-search="true"
            title="{{lisans}}"
            (change)="selectLisans($event)"
          >
            <option
              *ngFor="let lisans of lisansFilterData"
              value="{{ lisans.faculty_id }}"
            >
              {{ lisans.type }}
            </option>
          </select>

          <select *ngIf="param!=undefined && param==3"
            id="selectYuksekLisans"
            class="selectpicker select-margin ml-auto"
            data-live-search="true"
            title="{{yukseklisans}}"
            (change)="selectYuksek($event)"
          >
            <option
              *ngFor="let yuksek of yuksekDoktoraFilterData"
              value="{{ yuksek.faculty_id }}"
            >
              {{ yuksek.type }}
            </option>
          </select>
          <select *ngIf="param!=undefined && param==4"
            id="selectDoktora"
            class="selectpicker select-margin ml-auto"
            data-live-search="true"
            title="{{doktora}}"
            (change)="selectDoktora($event)"
          >
            <option
              *ngFor="let doktora of yuksekDoktoraFilterData"
              value="{{ doktora.faculty_id }}"
            >
              {{ doktora.type }}
            </option>
          </select>

          <mat-form-field class="ml-auto">
            <mat-label>Program {{search}}</mat-label>
            <input matInput placeholder="Ara"  (keyup)="searchFilter($event)"/>
          </mat-form-field>
        </div>
      </div>

      <div class="container">
        <div class="section-content">
          <div class="wt-separator-two-part">
            <div class="row wt-separator-two-part-row">
              <div class="col-lg-2 col-md-2 wt-separator-two-part-left">
                <!-- TITLE START-->
                <div class="section-head left wt-small-separator-outer">
                  <h3 *ngIf="param!=undefined && param==1"> {{onlisans}} {{title}}</h3>
                  <h3 *ngIf="param!=undefined && param==2"> {{lisans}} {{title}}</h3>
                  <h3 *ngIf="param!=undefined && param==3"> {{yukseklisans}} {{title}}</h3>
                  <h3 *ngIf="param!=undefined && param==4"> {{doktora}} {{title}}</h3>
                  <div class="wt-small-separator site-text-primary">
                    <div class="sep-leaf-left"></div>
                    <div>- {{ count }} {{countmetin}}</div>
                  </div>
                </div>

                <!-- TITLE END-->
              </div>

              <div
                class="col-lg-3 col-md-3 wt-separator-two-part-right text-right"
              >
                <select
                  id="selectProgramTip"
                  class="selectpicker select-margin ml-auto"
                  data-live-search="true"
                  title="{{programtype}}"
                  (change)="changeType($event)"
                >
                  <option
                    *ngFor="let programType of programTypes"
                    value="{{ programType.type }}"
                  >
                    {{ programType.type }}
                  </option>
                </select>
              </div>
              <div
                class="col-lg-7 col-md-7 wt-separator-two-part-right text-right"
              >
                <a
                  class="btn btn-sm btn-warning select-margin"
                  value="Ön Lisans"
                 href="/programlar/1"
                >
                 {{onlisans}}
            </a>
                <a
                  class="btn btn-sm btn-primary select-margin"
                  value="Lisans"
                  href="/programlar/2"
                >
                {{lisans}}
          </a>
                <a
                  class="btn btn-sm btn-success select-margin"
                  value="Yüksek Lisans"
                  href="/programlar/3"
                >
                {{yukseklisans}}
        </a>
                <a
                  class="btn btn-sm btn-danger select-margin"
                  value="Doktora"
                  href="/programlar/4"
                >
                {{doktora}}
      </a>
              </div>
            </div>
          </div>

          <div
            class="row d-flex justify-content-center"
            *ngIf="this.tempProgramData != null"
          >
            <div
              class="col-lg-4 col-md-6 col-sm-12 m-b50"
              *ngFor="let object of this.tempProgramData"
            >
              <div class="service-border-box service-image-box">
                <div class="wt-box service-box-1 bg-white">
                  <div
                    class="service-box-title title-style-2 site-text-secondry  {{
                      object.color
                    }}-border"
                  >
                    <span class="s-title-one">{{
                      object.program_name[this.getLang]
                    }}</span>
                    <span class="s-title-one">{{
                      object.program_type[this.getLang]
                    }}</span>
                    <span class="s-title-two">{{
                      object.unit_name[this.getLang]
                    }}</span>
                  </div>
                  <div class="service-box-content">
                    <a
                      routerLink="{{ object.id }}-{{ object.botid }}"
                      class="site-button-link btn btn-light w-100"
                      >{{read}}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
