import { HttpHeaders } from "@angular/common/http";
export const environment = {
 production: true,
baseUrl: "https://ubys.ankara.edu.tr/api",
 //baseUrl: "http://localhost:5278/api",
  fileUrl: "http://localhost:5278/",
  basicAuthUser:"mobil",
  basicAuthPasword:'!fS42m*!',
  version:1,
  avesisAuthApi:"https://abisis.ankara.edu.tr/identity/connect/token",
  avesisApi:"https://avesis.ankara.edu.tr/api/"
};
export const avesis={
  grant_type:"client_credentials",
  client_id:"ki-client",
  client_secret:"99FCD2B9-8F0F-4975-AA1D-8491DB0ECF31",
  scope:"avesis-public"
}
