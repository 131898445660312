import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ItemDetailModel } from 'src/app/contracts/base/news-detail-model';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
interface UrlData {
  type: string;
  getMethod: string;
}

const urlData: UrlData[] | any[] = [
  { type: 'haber', getMethod: 'NewsSlug' },
  { type: 'etkinlik', getMethod: 'EventsSlug' },
  { type: 'duyuru', getMethod: 'NoticesSlug' },
  { type: 'tanitim', getMethod: 'PagesSlug' },
];
@Component({
  selector: 'app-section-page-detail',
  templateUrl: './section-page-detail.component.html',
  styleUrls: ['./section-page-detail.component.css'],
})
export class SectionPageDetailComponent implements OnInit {
  method: string | any;
  public iframeContent: SafeHtml = '';
  slug: string | any;
  slug2: string | any;
  breadcumb: Breadcrumb[] = [
    {
      label: 'Kurumsal',
      url: '/dizin/birimler',
    },
  ];

  items: ItemDetailModel | any;
  @Input() data: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClientService: CMSDynamicService,
    private sanitizer: DomSanitizer,
    private seoService: SeoService
  ) {
    this.method = this.activatedRoute.snapshot.params['type'];

    this.slug = this.activatedRoute.snapshot.params['slug'];

    this.slug2 = this.activatedRoute.snapshot.params['slug2'];
  }

  async ngOnInit() {
    window.scrollTo(0, 0);
    await this.getNews();
  }

  async getNews() {
    if (this.slug2) {
      this.slug = this.slug2;
    }

    const promiseData: ItemDetailModel | any = await this.httpClientService.get(
      'CMS',
      'GetMainPagesSlug',
      '',
      1,
      'tr-TR',
      '&categorySlug=' + this.slug
    );

    this.items = promiseData;
    this.iframeContent = this.sanitizer.bypassSecurityTrustHtml(
      this.items.item.icerik
    );
    this.updateMetaTags(this.items.item.baslik, this.items.item.manset);

  }


  updateMetaTags(titlex: string = '', newsimage: string = ''): void {
    if (this.slug2) {
      this.slug = this.slug2;
    }
    const title =

         titlex + ' | Ankara Üniversitesi'
       ;

    const description =
      'Cumhuriyetin İlk Üniversitesi ';

    const keywords = ['Ankara', 'üniversite', 'haberler', titlex];
    const authors = [
     'Ankara Üniversitesi' ,
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl = newsimage
      ? newsimage
      : 'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/haber/' + this.slug;

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      titlex,
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );
    this.breadcumb = [
      {
        label: "Kurumsal",
        url: '/kurumsal/yonetim',
      },
    ];


    this.breadcumb.push({
      label: titlex,
      url: '/kurumsal/yonetim/'+canonicalUrl,
    });
    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }
  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);

    return splitDate[0].split('-')[2];
  }
  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }
  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }

  ayIsimAl(ayIndex: number): string {
    switch (ayIndex) {
      case 0:
        return 'Ocak';
      case 1:
        return 'Şubat';
      case 2:
        return 'Mart';
      case 3:
        return 'Nisan';
      case 4:
        return 'Mayıs';
      case 5:
        return 'Haziran';
      case 6:
        return 'Temmuz';
      case 7:
        return 'Ağustos';
      case 8:
        return 'Eylül';
      case 9:
        return 'Ekim';
      case 10:
        return 'Kasım';
      case 11:
        return 'Aralık';
      default:
        return '';
    }
  }
}
