<div class="page-wraper">
  <!-- CONTENT START -->
  <div class="page-content" *ngIf="unitDetail != null">
    <!-- INNER PAGE BANNER -->
    <app-section-inner-banner
      [data]="unitDetail.Kapak_Gorseli"
    ></app-section-inner-banner>
    <!-- INNER PAGE BANNER END -->

    <!-- OUR BLOG START -->
    <div class="section-full p-t80 p-b50 bg-white">
      <div class="container">
        <!-- BLOG SECTION START -->
        <div class="section-content">
          <div class="row">
            <div class="col-md-12 faculty-header d-flex align-items-center">
              <img
                src="https://dizin.ankara.edu.tr/assets/{{ unitDetail.Logo }}"
                alt="{{ unitDetail.Baslik }}"
              />
              <div>
                <h2>{{ getTitle(unitDetail) }}</h2>

                <a
                  href="{{ unitDetail.Web_Sitesi }}"
                  target="_blank"
                  class="btn btn-primary btn-sm"
                >
                  <span *ngIf="currentLang === 'tr-TR'">Web Sitesi</span>
                  <span *ngIf="currentLang === 'en-EN'">Website</span>
                </a>

                <a
                  href="{{ unitDetail.location }}"
                  target="_blank"
                  class="btn btn-secondary btn-sm"
                >
                  <span *ngIf="currentLang === 'tr-TR'">Harita</span>
                  <span *ngIf="currentLang === 'en-EN'">Map</span>
                </a>
              </div>

            </div>

            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 m-b30">
              <!-- BLOG START -->
              <div class="blog-post date-style-2 blog-lg">
                <iframe
                *ngIf="unitDetail.Video != null"
                class="vh-45"
                width="100%"
                [src]="videoUrl"
                id="youtubeVideo"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
              </iframe>
                <div
                  class="wt-post-media wt-img-effect zoom-slow"
                  *ngIf="unitDetail.Video == null"
                >
                  <a
                    ><img
                      *ngIf="unitDetail.Kapak_Gorseli != null"
                      class="vh-45"
                      src="https://dizin.ankara.edu.tr/assets/{{
                        unitDetail.Kapak_Gorseli.split('?')[0]
                      }}"
                      alt="" />
                    <img
                      *ngIf="unitDetail.Kapak_Gorseli == null"
                      class="vh-45"
                      src="https://www.ankara.edu.tr/wp-content/themes/75yil/img/placeholder.jpg"
                      alt=""
                  /></a>
                </div>
                <div class="wt-post-info bg-white p-t30">
                  <div class="wt-post-text">
                    <p [innerHTML]="getDescription(unitDetail)"></p>

                    <blockquote>
                      <i class="fa fa-quote-left font-20"></i>
                      <p>
                        <span class="quoter">


                          <span *ngIf="currentLang === 'tr-TR'">
                            {{getTitle(unitDetail) }} hakkında kısa bir bilgilendirme sunmaktadır. Detaylı bilgi için
                            <a target="_blank" href="{{ unitDetail.Web_Sitesi }}">web sitesini</a> ziyaret edebilirsiniz.
                          </span>
                          <span *ngIf="currentLang === 'en-EN'">
                            A brief information about {{ getTitle(unitDetail) }} is provided. For more detailed information, you can visit the
                            <a target="_blank" href="{{ unitDetail.Web_Sitesi }}">website</a>.
                          </span>
                        </span>

                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
              <!-- BLOG END -->
            </div>
            <!-- SIDE BAR START -->
            <div
              class="col-xl-4 col-lg-4 col-md-12 col-sm-12 rightSidebar m-b30"
            >
              <aside class="side-bar sidebar">
                <!-- SEARCH -->
                <div class="widget widgetp">
                  <div class="contact-card">

                    <div class="title" *ngIf="this.param =='hastaneler' ">
                      <i class="fa fa-user"></i> {{ HastaneBashekim}}:
                      <a
                        target="_blank"
                        class="f-size18px"
                        href="https://avesis.ankara.edu.tr/arama?q=Yakup KUMTEPE&Bulunan%20Kay%C4%B1t%20T%C3%BCrleri[0]=Ara%C5%9Ft%C4%B1rmac%C4%B1lar"
                        >Prof. Dr. Yakup KUMTEPE</a
                      >
                    </div>
                    <div class="title">
                      <i class="fa fa-user"></i> {{ this.param =="Fakulteler"? dekan : this.param =="hastaneler"? bashekim :mudur }} :
                      <a
                        target="_blank"
                        class="f-size18px"
                        href="https://avesis.ankara.edu.tr/arama?q={{
                          unitDetail.Yonetici
                        }}&Bulunan%20Kay%C4%B1t%20T%C3%BCrleri[0]=Ara%C5%9Ft%C4%B1rmac%C4%B1lar"
                        >{{ unitDetail.Yonetici }}</a
                      >
                    </div>
                    <div class="info">
                      <i class="fa fa-envelope"></i> {{ eposta }}:
                      <a
                        class="f-size18px"
                        href="mailto:{{ unitDetail.Eposta }}"
                        >{{ unitDetail.Eposta }}</a
                      >
                    </div>
                    <div class="info">
                      <i class="fa fa-phone"></i> {{ telefon }}:
                      <a
                        class="f-size18px"
                        href="tel:{{ unitDetail.Telefon }}"
                        >{{ unitDetail.Telefon }}</a
                      >
                    </div>
                    <div class="info">
                      <i class="fa fa-fax"> </i>{{ faks }}:<span>
                        {{ unitDetail.Faks }}</span
                      >
                    </div>
                    <div class="info">
                      <i class="fa fa-map-marker"></i>{{ adres }}:
                      <span> {{ unitDetail.Adres }}</span>
                    </div>
                  </div>

                  <div class="widget" *ngIf="latestPublicationa != null">
                    <h5>{{ update }}</h5>
                    <div class="updates-list">
                      <div
                        class="update-item"
                        *ngFor="let latest of latestPublicationa"
                      >
                        <img
                          src="https://avesis.ankara.edu.tr/{{
                            latest.userPhotoUrl
                          }}"
                          alt="{{latest.activityTitle?.[getlang]}} {{
                            latest.name
                          }} {{ latest.surname }}"
                        />
                        <div class="details">
                          <div class="title">
                            <a
                              href="https://avesis.ankara.edu.tr/{{
                                latest.userAvesisUrl
                              }}"
                              >{{latest.title?.[getlang]}} {{ latest.name }}
                              {{ latest.surname }}</a
                            >
                          </div>
                          <div class="subtitle">
                            {{latest.facultyName?.[getlang]}} -
                            {{latest.departmentName?.[getlang]}}
                          </div>
                          <span class="badge badge-primary"
                            >{{latest.message?.[getlang]}}
                            <br />{{latest.activityTitle?.[getlang]}}</span
                          >
                        </div>
                      </div>

                      <!-- Daha fazla güncelleme buraya eklenebilir -->
                    </div>
                  </div>

                  <div class="widget" *ngIf="acedemicPersonels != null">
                    <h5>{{ personel }}</h5>
                    <ul class="list-unstyled staff-list">
                      <li
                        class="staff-member d-flex align-items-center"
                        *ngFor="let acade of acedemicPersonels"
                      >
                        <img
                          src="https://avesis.ankara.edu.tr/user/image/{{
                            acade.id
                          }}"
                          alt=""
                        />
                        <div>
                          <div class="name">
                            <a href="{{ acade.webAddress }}"
                              >{{ acade.cvTitle[getlang] }} {{ acade.name }}
                              {{ acade.surname }}</a
                            >
                          </div>
                          <div class="position">
                            {{ acade.department[getlang] }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="widget" *ngIf="programData != null">
                    <h5>{{ program }}</h5>
                    <ul class="list-unstyled staff-list">
                      <li
                        class="staff-member d-flex align-items-center"
                        *ngFor="let program of programData"
                      >
                        <div>
                          <div class="name">
                            {{ program.program_name[Lang] }}
                          </div>
                          <div class="position">
                            {{ program.program_type[Lang] }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- Social -->
              </aside>
            </div>
            <!-- SIDE BAR END -->
          </div>
        </div>
      </div>
    </div>
    <!-- OUR BLOG END -->
  </div>
  <!-- CONTENT END -->

  <!-- FOOTER START -->

  <!-- FOOTER END -->
</div>
