<!-- <div appLoader class="loading-area">
    <div class="loading-box"></div>
    <div class="loading-pic">

        <div class="loader">
            <span class="block-1"></span>
            <span class="block-2"></span>
            <span class="block-3"></span>
            <span class="block-4"></span>
            <span class="block-5"></span>
            <span class="block-6"></span>
            <span class="block-7"></span>
            <span class="block-8"></span>
            <span class="block-9"></span>
            <span class="block-10"></span>
            <span class="block-11"></span>
            <span class="block-12"></span>
            <span class="block-13"></span>
            <span class="block-14"></span>
            <span class="block-15"></span>
            <span class="block-16"></span>
        </div>
    </div>
</div> -->

<!-- src/app/loader/loader.component.html    -->
<div *ngIf="loading$ | async"  class="loader-container">

  <svg class="water" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 450" width="100" height="100">


    <path fill="#0000000"
      d="
    M224.000000,1.000001
      C226.041672,1.000000 228.083328,1.000000 230.229523,1.246330
      C232.701340,5.559708 237.296707,8.897084 234.706116,14.601586
      C231.161301,22.407276 236.587769,30.991186 245.092651,30.999018
      C246.759140,31.000553 248.788513,30.409031 250.003616,31.150770
      C251.658936,32.161236 253.572006,34.118496 253.708054,35.804295
      C253.812042,37.092789 251.528915,39.408363 249.949921,39.791012
      C247.889175,40.290405 245.475494,39.330654 243.213226,39.001743
      C239.475113,38.458267 237.608109,39.657433 237.898071,43.847595
      C238.245407,48.866993 236.319321,54.133541 240.578934,58.610111
      C241.444992,59.520256 241.005737,62.196102 240.458374,63.818478
      C239.319580,67.193810 240.770798,68.052444 243.721420,68.004066
      C245.220871,67.979477 246.721054,67.997910 248.220917,68.000198
      C253.443130,68.008179 257.613525,72.404808 257.803680,78.101250
      C257.982239,83.449722 253.542343,88.773087 248.454224,88.941406
      C245.761642,89.030487 243.049423,88.526451 240.588470,88.311371
      C241.069244,91.604958 242.009232,94.815269 241.883972,97.983475
      C241.718155,102.177994 243.725021,102.963379 247.250565,103.048172
      C254.855179,103.231102 262.457031,103.615532 270.052216,104.053108
      C272.497375,104.193977 274.918060,104.759506 277.349976,105.130768
      C277.227905,102.593773 277.121490,100.055847 276.969788,97.520630
      C276.941406,97.046577 276.731689,96.583374 276.617004,96.157295
      C269.444885,94.282837 263.359894,83.625359 266.102112,77.401772
      C268.480591,72.003708 273.317993,70.068466 279.400879,69.723495
      C278.301544,66.878639 277.350281,64.416893 276.920624,63.304985
      C273.746613,63.003407 271.533691,62.793144 269.320770,62.582886
      C269.740601,58.916260 269.685638,55.105389 270.740967,51.631828
      C271.491180,49.162273 273.737732,47.147301 274.560913,45.990879
      C271.649994,42.975647 269.349060,40.592239 267.048126,38.208824
      C270.143768,36.805592 273.174713,35.225536 276.357727,34.061100
      C278.490112,33.281013 280.838318,33.090778 283.402893,32.573483
      C279.663452,27.144510 279.167175,22.953691 283.121429,19.791071
      C286.944824,16.733088 291.829956,14.731811 296.541534,13.125013
      C302.176300,11.203383 304.776154,13.513370 305.022949,19.458183
      C305.130463,22.047705 304.680206,25.154402 305.926697,27.102852
      C308.785187,31.571131 307.978088,35.054657 304.574463,38.732666
      C308.713409,39.652046 313.311890,39.962830 313.815186,46.709385
      C310.697357,47.138634 307.518860,47.359711 304.454071,48.070755
      C302.238922,48.584679 299.464752,49.184513 298.212250,50.758499
      C296.402344,53.032906 295.322021,55.947124 297.867828,59.128197
      C300.939819,62.966732 302.504883,67.367622 298.090485,71.838287
      C305.352295,72.132668 310.656372,77.968376 309.624695,84.674133
      C309.183350,87.542641 307.682404,90.557396 305.832184,92.829018
      C302.889771,96.441589 298.634644,96.708572 294.265900,94.999481
      C294.536499,99.913002 294.646057,104.339806 295.137390,108.723816
      C295.233276,109.579575 296.689392,110.577148 297.724884,110.987030
      C306.375092,114.411095 315.073669,117.712959 324.465576,121.323853
      C324.653290,118.358025 324.849304,115.260918 325.046814,112.140350
      C316.339203,111.830513 314.045929,110.314285 313.039368,104.654915
      C311.188385,94.247414 311.868286,93.068405 322.510712,90.970352
      C326.073242,90.268044 329.825134,90.526085 333.907715,90.324547
      C335.058716,84.561874 334.778473,77.408539 337.927673,72.420357
      C341.644684,66.532845 348.243378,62.415607 353.826904,57.815128
      C354.557190,57.213440 357.926147,58.180496 357.967468,58.619270
      C358.694550,66.337997 359.161011,74.084549 359.513153,81.832832
      C359.592621,83.581421 358.523346,85.457428 358.844910,87.106720
      C360.199921,94.057335 358.306763,97.612694 351.527954,100.009605
      C350.994019,100.198410 350.492981,100.480331 349.097656,101.125275
      C351.228699,101.964149 352.472504,102.700325 353.805206,102.938034
      C361.930420,104.387245 365.090790,112.247154 360.107483,119.534309
      C359.713867,120.075150 359.546143,120.422958 359.378418,120.770767
      C359.949341,120.546501 360.520203,120.322227 361.491455,120.064423
      C370.501343,116.695442 372.992432,123.486351 376.080627,128.873032
      C376.521851,128.748032 376.720154,128.754578 376.789154,128.663345
      C380.773438,123.394531 382.355133,116.387512 388.759857,112.815262
      C390.761688,111.698738 392.028381,109.331963 393.806549,107.731926
      C397.051086,104.812469 402.532318,103.850471 402.156586,97.785446
      C402.121246,97.215065 403.438934,96.412727 404.262360,95.963730
      C407.300751,94.307014 410.997192,94.015396 412.580872,97.028152
      C413.892242,99.522820 414.096802,103.604568 412.970337,106.189369
      C409.409332,114.360397 405.068085,122.198784 400.857727,130.074432
      C399.744385,132.156937 398.415619,134.719116 396.496582,135.669632
      C389.434998,139.167343 389.115448,139.819580 395.327728,144.162598
      C399.451324,147.045425 398.238739,151.063400 397.653412,154.567642
      C397.082825,157.983902 395.735382,161.392838 391.127411,162.075607
      C383.477264,163.209167 380.944946,161.776840 377.179077,153.085571
      C373.033508,156.598694 372.630096,164.206833 376.181641,168.886566
      C379.764313,173.607330 383.542725,178.240494 386.532562,183.325302
      C388.098663,185.988754 389.834290,186.631531 392.096161,185.715485
      C396.869965,183.782120 401.474640,181.431168 406.770447,178.961594
      C401.473480,174.844238 397.722687,171.062958 401.436005,164.666840
      C404.186432,159.929260 408.505890,159.450256 413.060730,160.068695
      C420.703552,161.106415 420.116852,167.378845 420.112488,172.316681
      C424.476135,171.502960 428.408630,170.795380 432.328522,170.023666
      C433.576172,169.778046 434.931152,169.604340 435.997101,168.984863
      C443.746643,164.481415 452.137512,162.786285 461.000000,163.000000
      C461.000000,164.388885 461.000000,165.777786 460.762390,167.243927
      C457.644348,170.019516 454.329865,172.390808 451.989441,175.496170
      C448.817657,179.704666 445.121948,182.493286 440.070587,184.149902
      C435.309937,185.711197 430.886047,188.299423 426.140533,190.516953
      C427.795654,192.255325 429.419281,193.702744 430.732788,195.391357
      C433.924713,199.494705 433.747131,203.364517 430.365936,208.001862
      C427.685791,211.677719 422.097046,213.450455 417.944946,211.593811
      C413.621307,209.660477 411.669037,205.693024 411.396271,201.327820
      C411.108551,196.723694 410.070648,195.576248 405.572784,197.005447
      C403.733948,197.589722 401.917084,198.255783 400.124359,198.970291
      C394.584259,201.178345 393.290497,204.264938 396.190002,209.334930
      C399.633728,215.356522 400.934235,221.748276 401.157410,228.626404
      C401.244781,231.319290 402.553680,234.367706 404.236389,236.530518
      C407.633026,240.896286 408.281433,244.675415 405.834991,249.551392
      C404.854767,251.504974 404.247925,253.788635 404.060699,255.972595
      C403.259430,265.320282 402.779175,274.695465 401.976440,284.042969
      C401.533325,289.202820 400.683685,294.327728 400.017731,299.465118
      C402.748077,300.440674 405.121765,301.288757 407.583130,302.168213
      C407.952850,299.589966 408.350525,297.493591 408.534637,295.378632
      C408.831085,291.974030 410.703735,289.400970 413.892944,289.333527
      C418.021942,289.246185 422.220642,290.122406 426.270142,291.085022
      C426.888000,291.231903 427.413910,294.226746 427.028320,295.644989
      C426.091278,299.091370 424.592865,302.385071 422.897461,306.856232
      C425.397766,305.672943 426.816345,304.968781 428.261292,304.323792
      C433.023407,302.198120 437.752899,301.518311 442.905548,303.406555
      C448.825287,305.575958 454.959137,307.161102 461.000000,309.000000
      C461.000000,310.388885 461.000000,311.777802 460.736450,313.227570
      C458.169373,314.339874 454.620056,314.787842 453.769745,316.543243
      C449.160065,326.059448 439.754089,320.918488 433.038208,323.734985
      C432.015717,324.163788 429.439087,322.599976 428.545349,321.296631
      C426.013275,317.604309 423.571381,318.747192 421.815948,319.841125
      C422.962616,326.832611 424.557861,332.997284 424.821411,339.218353
      C425.060974,344.872131 424.457336,350.730042 423.155731,356.245514
      C420.478333,367.591309 414.232544,377.248596 407.064697,386.273499
      C398.323029,397.279877 388.375824,406.947632 374.150208,410.173981
      C365.714020,412.087311 357.017365,413.607880 348.402679,413.810181
      C333.649902,414.156616 318.870728,413.251312 304.100250,413.035248
      C298.052002,412.946777 291.753601,415.098633 289.032745,419.932373
      C283.480621,429.795990 279.580597,440.589539 275.000000,451.000000
      C247.979111,451.000000 220.958206,451.000000 193.467606,450.600098
      C190.537140,443.176758 188.712921,435.843872 185.480728,429.195496
      C179.111221,416.094025 169.081726,407.943665 153.525482,409.014374
      C150.063431,409.252655 146.615952,409.894623 143.156189,409.941711
      C125.608696,410.180450 108.298935,408.262848 91.540466,402.921082
      C77.432938,398.424286 63.549465,392.557220 56.599880,378.543182
      C47.865849,360.930756 40.594105,342.599670 40.941025,322.283661
      C40.994564,319.148254 41.395039,316.750671 44.100479,314.531067
      C45.895538,313.058350 46.509609,310.146179 47.328407,308.521240
      C39.904274,311.073853 32.846577,313.875183 25.563341,315.840759
      C22.111610,316.772308 17.870306,315.160461 14.626600,316.389587
      C9.926965,318.170410 5.605373,318.206146 1.000000,317.000000
      C1.000000,313.969391 1.000000,310.938812 1.246617,307.785675
      C8.240591,304.019135 15.059907,300.500671 21.711576,296.689636
      C26.649931,293.860199 31.370745,290.651093 37.001469,287.098114
      C37.001469,285.536591 36.715187,282.859039 37.108894,280.285492
      C37.344864,278.743042 38.284538,276.811066 39.525612,276.004028
      C45.492851,272.123718 52.667187,274.387360 54.801014,280.910736
      C55.812634,284.003418 57.278328,285.488281 60.467293,284.863159
      C63.780849,284.213623 65.829254,282.528656 65.006615,278.736237
      C64.625687,276.980133 64.092766,275.211060 64.043274,273.436310
      C63.691658,260.827454 63.462055,248.215256 63.132446,235.605698
      C63.112602,234.846512 62.785507,233.770966 62.230556,233.405045
      C59.512547,231.612946 56.799511,229.708099 53.831970,228.429932
      C50.991188,227.206375 48.251842,227.192520 47.906166,231.593964
      C47.802814,232.909866 46.898167,234.449417 45.885033,235.353592
      C42.835720,238.074890 37.568256,237.480057 32.715866,233.791931
      C29.552588,231.387619 29.118841,228.155365 30.871716,224.567551
      C32.130772,221.990540 33.232944,219.336853 34.466572,216.579987
      C19.259624,214.114594 11.957259,200.908447 15.686470,182.991150
      C27.140005,186.601166 39.663624,187.860535 46.812565,200.292587
      C47.150143,192.980270 48.266582,190.884430 51.882946,190.056519
      C57.318790,188.812057 62.975346,191.028870 65.791878,195.506958
      C70.149048,202.434509 65.769005,206.592255 59.685989,210.832321
      C63.101749,213.021347 65.683998,214.676208 68.769478,216.653564
      C71.985588,200.983795 79.988869,188.606476 89.060966,177.174438
      C86.999252,174.662155 85.241783,172.520615 82.682312,169.401794
      C79.963318,176.610992 79.309242,184.510284 69.345787,181.134140
      C63.962330,179.309921 61.952248,175.817215 61.109890,170.890106
      C60.103058,165.000946 63.810322,162.191132 69.773338,159.702576
      C67.180817,157.451630 65.032555,154.172653 63.409363,154.414719
      C55.097942,155.654175 52.307117,150.198486 49.270409,144.402451
      C48.300739,142.551666 46.120361,141.354904 45.050747,139.532150
      C43.443470,136.793152 41.288284,133.766464 41.287834,130.861786
      C41.287365,127.840591 40.944916,125.485359 39.650997,122.754677
      C38.914146,121.199638 40.326382,118.626259 40.770870,116.511444
      C42.773418,117.106110 45.028751,117.303642 46.723251,118.382256
      C49.776875,120.326004 52.459034,122.844643 55.364315,125.032928
      C57.593204,126.711739 59.850533,128.378922 62.240269,129.808838
      C63.829651,130.759857 65.739830,131.160233 67.388252,132.030807
      C69.381889,133.083694 71.751740,133.975998 73.070808,135.641205
      C75.944397,139.268814 78.255257,143.342178 81.605415,148.472839
      C83.191719,140.857132 85.808289,135.771988 94.125076,138.338760
      C90.025497,129.479263 91.058090,122.725906 100.617935,118.747925
      C88.185890,114.326134 80.679184,107.058594 78.903038,94.564125
      C77.605461,85.436211 81.298805,82.331696 90.319046,84.717842
      C98.770439,86.953514 103.054092,93.794968 108.124626,99.752136
      C113.679329,106.278137 113.517746,106.415680 120.963585,101.583031
      C125.417236,98.692429 134.084122,99.920563 137.362701,103.906876
      C140.590729,107.831703 139.409180,114.684982 134.710327,118.816292
      C132.782166,120.511536 130.716644,122.050529 128.441315,123.879478
      C131.550598,126.858322 133.901337,128.365280 138.072784,126.134819
      C145.773544,122.017250 153.893173,118.683075 162.093079,114.905128
      C161.234787,109.764397 160.337006,104.387230 159.341644,98.425537
      C157.189209,99.550468 155.898087,100.198059 154.632065,100.891457
      C147.979889,104.534752 143.121658,102.439217 140.968735,94.986610
      C139.354706,89.399384 140.876389,85.541924 146.197159,81.518303
      C147.824020,80.288055 149.353485,78.929001 151.429047,77.214737
      C144.508972,76.733841 143.400284,74.828117 145.958450,69.231110
      C146.838028,67.306717 148.256256,65.530815 148.698105,63.526112
      C149.427399,60.217159 149.563217,56.777401 150.052078,52.490211
      C147.477951,53.593288 146.416580,54.047306 145.355850,54.502785
      C140.879898,56.424786 135.647980,55.453503 135.270737,52.300232
      C134.709534,47.609509 138.535522,46.519653 142.051895,45.482216
      C145.241516,44.541172 148.439728,43.629246 150.487564,43.036411
      C150.487564,39.588821 150.291901,37.434158 150.539459,35.331680
      C150.818497,32.961937 151.673431,30.659723 151.948792,28.289900
      C152.197083,26.152908 151.981064,23.964054 152.005035,21.798462
      C152.049408,17.788013 154.195831,14.932969 157.935394,14.429708
      C161.761032,13.914858 163.840454,16.847900 164.754074,20.318727
      C165.335068,22.525862 165.763458,24.801294 165.974396,27.072649
      C166.730362,35.212791 167.538956,43.007645 178.990036,42.335911
      C180.207779,42.264481 182.653152,46.131844 182.707718,48.219547
      C182.757660,50.130302 180.753143,52.941803 178.910965,53.851028
      C176.323776,55.127945 173.049469,55.012623 169.591217,55.569729
      C171.417862,60.116943 173.667389,64.208748 174.652145,68.585152
      C175.672394,73.119232 178.199509,73.590691 181.805252,72.852333
      C187.848297,71.614891 193.172165,73.925491 194.776154,78.589729
      C196.688339,84.150108 194.173477,89.519920 188.355347,92.346268
      C187.246140,92.885094 186.039291,93.338089 185.110519,94.110779
      C182.704041,96.112808 180.427521,98.271034 178.099121,100.366905
      C177.608215,100.128212 177.117310,99.889519 176.626404,99.650826
      C177.430756,102.767624 177.901306,106.035187 179.206924,108.925568
      C179.715363,110.051163 182.256668,111.124100 183.615189,110.866531
      C197.544250,108.225655 211.420486,105.306206 225.721680,102.380600
      C226.888916,93.214531 226.349655,92.939812 216.790207,95.382973
      C214.228104,96.037781 209.942917,95.234596 208.434204,93.435631
      C206.390244,90.998413 204.811508,86.561325 205.597107,83.681549
      C207.481522,76.773766 211.105469,70.541229 220.800797,70.676964
      C219.800461,67.566803 218.659576,65.059647 218.245819,62.437748
      C217.840195,59.867371 217.282990,56.721527 218.357361,54.633553
      C221.163086,49.180836 216.196960,45.107166 217.806870,40.321705
      C216.500671,42.477093 215.923462,45.265930 214.514526,45.781380
      C210.643311,47.197666 204.526627,41.484081 203.994202,36.632774
      C203.324356,30.529245 207.766418,32.008026 210.947830,32.043331
      C219.090240,32.133682 223.853745,20.685692 220.777237,15.886292
      C218.266144,11.968934 219.481018,9.158130 221.263184,5.930369
      C222.171738,4.284858 223.087509,2.643331 224.000000,1.000001
    M353.976227,123.921341
      C350.146576,123.271378 346.316895,122.621414 341.414551,121.789398
      C340.200867,124.115005 338.441528,127.486145 336.761047,130.706192
      C343.611969,135.978775 350.000610,140.808334 356.284393,145.770706
      C359.165283,148.045761 360.740936,146.258743 362.296295,144.172546
      C364.339264,141.432327 362.295746,140.362473 360.197540,139.587692
      C356.861359,138.355774 354.547882,136.453384 354.897949,132.544846
      C355.134460,129.903503 355.491913,127.272964 356.257965,124.199387
      C356.396423,123.811584 356.534851,123.423775 356.673309,123.035980
      C356.058319,123.297653 355.443298,123.559334 353.976227,123.921341
    M253.887131,336.322266
      C253.423340,336.274933 252.959564,336.227600 252.495773,336.180267
      C252.747101,336.658081 252.998444,337.135895 253.688934,338.123657
      C259.677185,344.128571 265.576996,350.227142 271.741241,356.045593
      C272.761444,357.008575 275.758850,357.590118 276.449707,356.917450
      C281.936890,351.574554 287.087311,345.885742 291.857239,340.817291
      C279.587433,339.529724 266.858032,338.193939 253.887131,336.322266
    M59.005077,342.409119
      C60.334637,346.755615 61.625343,351.114410 63.001095,355.446259
      C67.052940,368.204315 74.069695,379.121643 87.129341,383.339233
      C105.051468,389.127197 123.629250,392.488525 142.642181,391.902191
      C145.203506,391.823212 147.725296,390.461945 151.283142,389.386932
      C105.396347,373.889343 82.937698,340.031525 69.620163,296.657654
      C68.123329,298.668640 64.997459,301.170288 65.431786,302.556305
      C68.591034,312.637756 62.754887,321.041931 59.709965,329.357697
      C57.981892,334.077148 57.874989,337.399902 59.005077,342.409119
    M334.057098,388.834534
      C333.029510,389.043976 332.001923,389.253448 330.974335,389.462891
      C332.758240,391.649506 334.697693,393.402588 336.871399,393.769958
      C348.299103,395.701233 360.029205,396.757965 371.112823,393.194702
      C391.596466,386.609375 402.753632,370.906372 408.879974,351.101624
      C409.265259,349.856110 409.077148,347.619415 408.253998,346.945618
      C402.814667,342.493347 404.758545,337.493103 406.655884,332.317322
      C401.643402,326.855438 401.641418,324.552917 407.237457,317.811615
      C402.829865,318.350128 398.782837,318.614868 396.871948,312.223633
      C394.731384,315.992676 393.175262,318.390900 391.958008,320.950287
      C389.735565,325.623199 388.263794,330.745270 385.496429,335.048126
      C379.287720,344.701721 373.053864,354.447144 365.742920,363.252350
      C358.941711,371.443634 351.112152,378.892883 341.261536,383.738953
      C338.883362,384.908936 336.893219,386.867615 334.057098,388.834534
    M208.498550,431.001953
      C212.824295,436.487427 219.292068,434.148438 224.604370,434.703278
      C234.468094,435.733459 244.522491,435.183075 254.482117,434.841980
      C256.456085,434.774384 259.106628,433.156342 260.172577,431.455658
      C265.702087,422.633362 262.807007,414.245331 252.741287,407.963257
      C247.617706,404.765625 239.971252,403.973663 235.316360,408.775269
      C234.047638,410.084015 230.787598,410.509369 228.851608,409.941559
      C220.783615,407.575409 213.658844,409.895935 206.913086,413.836670
      C205.431244,414.702332 203.924911,417.462982 204.207626,419.030365
      C204.910858,422.929291 206.638962,426.643402 208.498550,431.001953
    M250.557098,121.009628
      C250.930786,122.100929 250.986862,123.523476 251.726715,124.232918
      C260.308075,132.461380 268.992737,140.582138 277.815430,148.887177
      C284.130035,143.481506 290.504364,138.024673 297.219238,132.276337
      C282.770874,123.881592 267.687256,119.990364 250.557098,121.009628
    M225.006088,373.405121
      C225.714859,361.779999 226.423630,350.154846 227.163818,338.014435
      C218.397858,345.167206 210.307571,351.768616 202.208878,358.376923
      C209.453583,368.301636 220.745728,379.206818 225.002426,379.624847
      C225.002426,377.805817 225.002426,376.060272 225.006088,373.405121
    M303.095612,279.234161
      C295.945160,284.348602 288.794708,289.463013 281.173523,294.914124
      C289.935974,303.155762 298.169373,310.899780 305.994812,318.260101
      C305.994812,305.516235 306.012573,292.656738 305.900696,279.798370
      C305.897644,279.448364 304.497101,279.110565 303.095612,279.234161
    M247.004944,351.476959
      C245.312561,349.254333 243.620193,347.031677 241.401810,344.118225
      C239.948975,346.596436 239.123260,347.365295 239.081635,348.174530
      C238.648560,356.591705 238.211395,365.014038 238.123566,373.438538
      C238.108353,374.897827 239.370758,377.086853 240.644608,377.710999
      C244.728058,379.711761 249.196747,380.483826 253.138474,377.383728
      C256.880676,374.440582 260.312408,371.102692 264.201202,367.654114
      C258.329071,362.141296 252.918716,357.062042 247.004944,351.476959
    M97.688339,246.136017
      C111.349060,249.350052 124.966248,247.932251 138.579514,246.097839
      C138.915619,245.645859 139.251709,245.193863 139.587814,244.741882
      C133.134155,238.282852 126.680504,231.823837 119.311485,224.448700
      C111.574860,232.101242 104.800201,238.802277 97.688339,246.136017
    M333.406921,268.995239
      C331.326630,268.995239 329.246338,268.995239 325.595062,268.995239
      C330.611664,274.428040 334.383789,278.963379 338.626190,283.005066
      C345.505615,289.558960 345.827789,289.665009 353.210541,283.825562
      C358.937714,279.295624 364.108154,274.061829 369.526062,269.140930
      C361.469696,267.999268 354.035339,267.889923 346.606354,268.042542
      C342.506897,268.126709 338.416412,268.650513 333.406921,268.995239
    M259.563416,322.996429
      C270.142456,324.919769 280.708588,327.021729 292.309570,325.619049
      C285.168335,318.535126 278.625763,311.919922 271.884216,305.514160
      C271.029114,304.701630 268.590912,304.274780 267.802795,304.874298
      C261.399872,309.744965 255.215591,314.902985 248.282974,320.526489
      C252.507919,321.516052 255.604797,322.241394 259.563416,322.996429
    M173.445480,280.061371
      C170.869186,277.922974 168.292877,275.784576 165.141312,273.168671
      C163.938446,287.384613 162.818130,300.624725 161.697830,313.864838
      C162.553238,314.354218 163.408646,314.843567 164.264053,315.332947
      C171.153580,307.826416 178.043121,300.319885 184.945312,292.799591
      C181.204788,288.648285 177.569244,284.613525 173.445480,280.061371
    M104.452919,268.012238
      C109.565269,272.444366 114.829712,276.716858 119.722961,281.378998
      C122.016754,283.564423 123.703621,283.180389 125.646729,281.420624
      C131.719894,275.920471 137.760086,270.383850 143.813049,264.861389
      C143.688675,264.484375 143.564301,264.107361 143.439941,263.730347
      C128.775467,263.730347 114.111015,263.730347 99.048607,263.730347
      C100.843987,265.117004 102.393028,266.313385 104.452919,268.012238
    M109.640076,290.020996
      C101.804047,283.263977 93.968010,276.506958 85.446449,269.158813
      C84.824890,271.147003 83.829399,272.576935 84.133446,273.632507
      C86.903595,283.249939 89.673553,292.880127 92.929390,302.339386
      C94.476562,306.834442 96.432213,307.121307 100.305519,304.110626
      C103.689957,301.479950 106.604698,298.202789 110.135101,295.814606
      C113.300835,293.673157 112.157860,292.315796 109.640076,290.020996
    M376.185486,305.621429
      C380.532959,297.330902 380.960754,288.259949 381.806152,279.272156
      C381.403229,278.933044 381.000305,278.593933 380.597382,278.254852
      C372.661255,285.545380 364.725128,292.835907 356.699524,300.208618
      C361.479340,305.472443 365.866241,310.303528 370.689453,315.615143
      C372.749298,312.041504 374.382965,309.207184 376.185486,305.621429
    M227.104919,297.463135
      C227.104919,289.859406 227.104919,282.255676 227.104919,273.279419
      C219.838287,279.796173 213.466034,285.510864 206.708588,291.571014
      C213.460815,297.878845 219.654343,303.664734 225.847855,309.450623
      C226.248657,309.212341 226.649445,308.974030 227.050247,308.735718
      C227.050247,305.306702 227.050247,301.877686 227.104919,297.463135
    M86.049561,227.796265
      C86.236443,230.410400 86.423325,233.024551 86.689919,236.753708
      C95.126411,229.328293 102.483391,222.853043 108.987190,217.128693
      C104.987892,212.617981 101.062317,208.190430 96.936111,203.536591
      C90.255455,210.304291 88.394997,218.689606 86.049561,227.796265
    M198.045609,141.462021
      C199.287521,141.579803 200.999084,142.284744 201.698975,141.724915
      C208.990234,135.892838 216.118942,129.857529 223.292755,123.878601
      C223.016800,123.347931 222.740845,122.817261 222.464890,122.286591
      C209.546982,122.146149 197.430878,125.966591 185.326645,130.549225
      C189.478668,134.110367 193.494598,137.554779 198.045609,141.462021
    M355.281189,329.999054
      C357.441437,329.846710 359.601654,329.694397 361.761902,329.542084
      C362.029449,329.001160 362.296997,328.460205 362.564514,327.919281
      C357.254730,322.167908 351.944916,316.416565 345.556122,309.496490
      C339.928192,316.580017 334.703766,323.155640 330.247864,328.763977
      C336.972382,329.108459 345.664917,329.553741 355.281189,329.999054
    M197.401962,163.964401
      C192.129105,168.257080 186.856232,172.549774 180.843582,177.444717
      C184.123703,179.684723 186.402649,182.414444 189.010468,182.776688
      C194.374100,183.521759 199.913605,183.000702 205.304016,183.000702
      C209.321884,172.099518 207.114975,166.376190 197.401962,163.964401
    M245.000107,169.506409
      C245.001205,172.336899 244.975540,175.167740 245.010483,177.997803
      C245.050156,181.211990 246.940948,182.821548 249.929398,182.952133
      C253.917801,183.126389 257.920624,183.054413 261.915100,182.976807
      C264.466003,182.927246 265.017090,182.190643 264.294495,179.240448
      C262.610931,172.366821 259.016693,166.866852 253.796234,162.481583
      C251.604614,160.640610 248.476562,159.914383 245.000046,158.328125
      C245.000046,162.588913 245.000046,165.565292 245.000107,169.506409
    M116.625710,332.000031
      C114.182350,332.000031 111.738991,332.000031 109.295639,332.000031
      C108.906883,332.428619 108.518135,332.857178 108.129387,333.285767
      C112.186577,338.460052 116.157845,343.707092 120.402313,348.722900
      C120.976738,349.401703 123.409454,349.504944 124.155457,348.883240
      C129.932632,344.068573 135.506622,339.010040 141.143524,334.027039
      C140.956024,333.351379 140.768524,332.675690 140.581024,332.000031
      C132.917664,332.000031 125.254311,332.000031 116.625710,332.000031
    M364.472443,226.993927
      C363.355225,227.797211 362.238007,228.600494 361.186523,229.356537
      C368.613770,236.054596 375.847473,242.578110 383.457611,249.441116
      C381.851318,238.536804 380.275635,227.840118 378.545441,216.094513
      C373.285095,220.126709 369.132782,223.309570 364.472443,226.993927
    M285.001160,253.001221
      C286.206360,252.899551 287.411591,252.797882 289.593872,252.613785
      C282.711700,246.539474 276.577820,241.125641 270.229095,235.522141
      C264.086914,240.892334 258.410370,245.855408 252.733856,250.818481
      C258.649933,252.004318 263.753357,251.874664 268.847076,252.040787
      C273.947784,252.207138 279.038849,252.668915 285.001160,253.001221
    M154.000122,210.844696
      C153.203079,209.243164 152.497864,206.317184 151.594116,206.254517
      C145.615173,205.839920 139.593597,206.040100 133.419647,206.040100
      C136.042847,217.927017 144.643173,226.761108 154.000534,227.671371
      C154.000534,222.358459 154.000534,217.069763 154.000122,210.844696
    M178.587555,358.171326
      C174.349350,350.582245 164.620483,341.362305 161.296951,341.621857
      C161.400558,343.355988 161.711472,345.098328 161.567581,346.802246
      C161.141449,351.848419 160.281464,356.868591 160.065384,361.919220
      C159.800552,368.109619 160.319214,368.491547 166.410843,367.933380
      C167.721313,367.813293 169.162643,367.074646 170.180191,366.198700
      C172.937943,363.824646 175.500443,361.223816 178.587555,358.171326
    M154.004990,184.580658
      C153.795792,182.134995 155.033615,178.967026 150.543640,178.964417
      C141.902206,178.959381 133.400757,188.133209 134.436722,196.875961
      C141.204742,197.312042 148.014938,197.750839 155.284958,198.219269
      C154.803818,193.357025 154.414719,189.424973 154.004990,184.580658
    M218.127823,173.088074
      C216.692001,177.950211 219.988892,179.440659 223.576630,179.818665
      C227.940308,180.278381 232.389343,179.927887 236.062592,179.927887
      C236.062592,172.553543 236.062592,165.809250 236.062592,158.986374
      C227.131516,160.462753 221.850647,165.233200 218.127823,173.088074
    M277.039032,279.527130
      C282.000244,276.026703 286.961487,272.526276 292.985565,268.275940
      C279.207794,268.275940 267.054260,268.275940 255.822418,268.275940
      C258.819519,271.598755 262.304749,275.110687 265.371735,278.956177
      C268.857086,283.326172 272.371185,283.928772 277.039032,279.527130
    M265.557678,204.948395
      C264.309052,206.029541 263.060394,207.110687 261.821442,208.183441
      C270.099060,216.894714 270.096985,216.891800 279.443817,210.267944
      C279.833008,209.992126 280.433319,209.973358 280.758728,209.654617
      C284.334412,206.152588 289.791229,203.846207 288.595398,196.699524
      C280.871063,198.823578 270.491394,192.125031 265.557678,204.948395
    M179.971954,268.409119
      C186.211670,273.161194 192.451385,277.913269 196.905609,281.305542
      C202.819489,275.957581 207.878830,271.382416 212.938187,266.807220
      C212.918503,266.344940 212.898834,265.882690 212.879166,265.420410
      C201.757736,266.217957 190.636307,267.015503 179.971954,268.409119
    M317.767120,187.181381
      C317.289398,186.075867 316.811676,184.970367 316.354248,183.911880
      C305.502472,187.239899 297.532471,196.530075 298.341400,204.633331
      C304.529510,204.633331 310.714661,204.633331 316.879883,204.633331
      C317.259979,198.856155 317.616089,193.444061 317.767120,187.181381
    M352.747162,195.409058
      C352.747162,203.256516 352.747162,211.103989 352.747162,220.009598
      C359.391632,214.315903 365.686554,209.049652 371.782501,203.562378
      C372.713806,202.724075 373.471161,200.469635 372.993347,199.565384
      C372.207611,198.078323 370.457306,196.363098 368.917389,196.147736
      C363.865997,195.441299 358.726257,195.366776 352.747162,195.409058
    M349.723785,240.029633
      C343.697571,244.962219 337.671326,249.894806 331.137878,255.242554
      C343.807281,255.242554 355.216797,255.242554 366.626282,255.242554
      C366.869934,254.686111 367.113617,254.129654 367.357269,253.573212
      C361.730042,249.051468 356.102844,244.529709 349.723785,240.029633
    M341.987762,179.536926
      C348.636810,186.056305 355.772583,187.083435 363.942230,181.897537
      C360.278564,177.421143 356.941132,172.812744 353.026611,168.762085
      C350.726715,166.382172 347.536499,165.947418 344.642090,168.583298
      C342.115173,170.884537 339.493347,173.081604 336.608154,175.590179
      C338.524048,176.952362 339.995361,177.998444 341.987762,179.536926
    M209.056091,314.553436
      C204.638443,310.693451 200.220795,306.833496 195.816422,302.985107
      C189.579620,308.540100 183.521698,313.935760 177.704742,319.116821
      C189.137573,319.116821 200.477722,319.116821 212.878143,319.116821
      C211.213272,317.053162 210.408829,316.056030 209.056091,314.553436
    M328.103363,183.023468
      C327.069672,184.117065 325.231323,185.150299 325.143982,186.314880
      C324.798218,190.922943 325.133331,195.577240 324.954926,200.204346
      C324.830658,203.427612 326.397552,204.278076 329.202209,204.001526
      C334.075439,203.520981 338.950134,203.055084 344.347443,202.532364
      C342.901733,192.970718 337.226135,187.062225 328.103363,183.023468
    M170.797409,136.408829
      C168.889954,137.500366 165.817780,138.246704 165.366364,139.769775
      C164.786530,141.726105 165.556610,145.293716 167.062088,146.444824
      C173.208572,151.144485 176.856781,156.592972 175.023483,164.764404
      C175.545547,165.123032 176.067596,165.481674 176.589661,165.840317
      C180.717178,161.766251 184.844711,157.692169 189.210403,153.383011
      C182.327148,147.037704 176.837646,141.977234 170.797409,136.408829
    M209.840439,211.431458
      C211.272919,210.089035 212.705414,208.746613 214.273804,207.276825
      C211.702179,203.388062 210.042740,198.140839 206.623581,196.448105
      C202.912918,194.611084 197.564316,196.080994 192.930038,196.118240
      C192.773483,196.119507 192.617752,196.222275 192.966476,196.098404
      C190.552368,205.854477 197.586823,210.304443 203.544983,215.702515
      C205.478470,214.392014 207.355927,213.119476 209.840439,211.431458
    M102.965317,151.253738
      C102.458740,156.499771 98.211037,157.123047 94.082535,157.688431
      C96.610931,162.745743 99.803513,164.520020 101.930092,162.240417
      C108.542175,155.152527 114.995255,147.916290 121.655838,140.576920
      C118.026382,133.657959 116.486824,133.594315 110.622917,138.777878
      C108.081078,141.024796 104.238708,141.800476 100.904465,143.281097
      C101.378822,144.914108 102.181747,147.678238 102.965317,151.253738
    M216.134811,188.446732
      C215.843521,197.618301 223.494263,206.717773 233.593979,208.958725
      C236.958679,202.757965 233.294983,196.476379 233.557602,190.264648
      C233.574371,189.867706 231.709366,189.102249 230.671143,188.999527
      C226.085373,188.545868 221.481812,188.272125 216.134811,188.446732
    M301.808136,146.227280
      C297.152222,150.629135 292.496307,155.030991 287.808258,159.463242
      C292.924622,163.927155 297.628906,168.031555 303.123413,172.825409
      C302.618530,164.920914 303.285583,158.702850 307.278442,152.851471
      C309.076813,150.216019 308.961487,146.064346 306.435455,142.504623
      C304.900696,143.775970 303.662933,144.801285 301.808136,146.227280
    M240.999268,295.444580
      C240.999268,298.811310 240.999268,302.178009 240.999268,306.404022
      C247.696106,301.696686 253.574539,297.564606 260.131622,292.955505
      C253.413330,287.540009 247.526642,282.794861 240.999313,277.533325
      C240.999313,283.908081 240.999313,289.185089 240.999268,295.444580
    M299.002380,214.186020
      C300.804230,223.378647 310.448151,230.873093 318.005066,229.558273
      C318.005066,226.680405 318.005035,223.919739 318.005035,221.159058
      C318.005035,218.221710 318.005066,215.284348 318.005066,211.922623
      C312.399445,211.922623 307.284332,211.845505 302.175201,211.985535
      C301.112122,212.014694 300.071075,212.847870 299.002380,214.186020
    M164.999557,219.326355
      C164.999557,221.429520 164.999557,223.532684 164.999557,225.694122
      C174.293747,224.165253 182.655441,213.101700 181.497711,205.261383
      C176.090286,205.261383 170.682861,205.261383 164.999329,205.261383
      C164.999329,209.804321 164.999329,214.087341 164.999557,219.326355
    M195.389664,384.021027
      C197.375519,383.373413 199.749939,383.177917 201.212311,381.921204
      C202.207748,381.065674 202.761566,377.838165 202.078278,377.194641
      C198.190552,373.533417 193.871292,370.330383 189.935547,367.162140
      C187.884872,368.497253 185.848267,369.578461 184.134949,371.038940
      C183.241119,371.800873 182.395035,373.249603 182.435150,374.355164
      C182.544434,377.367279 183.136841,380.361847 183.734528,384.835846
      C186.378021,384.665649 190.454910,384.403137 195.389664,384.021027
    M126.454460,308.006531
      C125.005455,306.949188 123.556442,305.891846 122.017029,304.768555
      C116.363815,309.380981 110.871971,313.861725 104.938339,318.702942
      C116.521187,318.702942 127.442764,318.702942 138.699509,318.702942
      C134.569656,315.100311 130.779648,311.794128 126.454460,308.006531
    M335.433655,226.052582
      C340.074005,222.260223 342.443115,217.173477 344.080597,211.005844
      C338.818420,211.005844 333.875793,210.833725 328.963593,211.143997
      C327.920410,211.209900 326.198486,212.990463 326.138702,214.056717
      C325.844513,219.302216 326.005035,224.573212 326.005035,231.274887
      C329.653229,229.363419 332.290405,227.981689 335.433655,226.052582
    M242.996780,193.711945
      C242.762573,198.554398 242.528366,203.396851 242.292740,208.268906
      C251.181641,207.420242 259.686768,200.343414 262.307556,191.571701
      C256.241089,191.057770 250.242599,190.500595 244.231766,190.161926
      C243.870667,190.141586 243.411285,191.865524 242.996780,193.711945
    M299.412445,242.052429
      C301.057709,243.370361 302.702972,244.688278 304.639343,246.239426
      C305.978088,242.184891 307.704895,238.852280 304.048676,235.488770
      C299.003723,230.847717 294.223022,225.919373 288.578857,220.377396
      C287.111755,221.488312 284.374603,223.560913 281.057922,226.072357
      C287.371552,231.678223 293.061646,236.730438 299.412445,242.052429
    M190.509033,333.997375
      C185.825546,333.997375 181.142044,333.997375 175.440048,333.997375
      C181.153595,339.374603 185.962143,343.900116 190.655701,348.317413
      C196.686005,343.369476 202.194031,338.850098 208.108292,333.997375
      C202.245132,333.997375 196.874557,333.997375 190.509033,333.997375
    M175.012207,186.522476
      C171.270767,183.270187 168.143845,178.943497 162.001724,179.010284
      C162.001724,183.908218 162.059601,188.540955 161.979645,193.171326
      C161.932953,195.876068 162.987411,197.202423 165.806259,197.011383
      C170.754333,196.676056 175.707672,196.418793 181.697571,196.067993
      C179.196579,192.406464 177.361847,189.720352 175.012207,186.522476
    M248.796539,237.334366
      C252.709244,233.633118 256.621948,229.931885 260.808624,225.971451
      C256.911469,222.802567 253.839417,220.071106 250.522675,217.678879
      C247.683960,215.631393 244.042984,217.515839 243.999832,220.934433
      C243.921722,227.123627 243.977722,233.314499 243.977722,240.641129
      C246.357544,239.019974 247.284286,238.388687 248.796539,237.334366
    M134.579987,292.961182
      C139.085297,298.016327 143.590607,303.071503 148.739838,308.849182
      C148.739838,298.126068 148.739838,288.778809 148.739838,278.899780
      C143.909485,283.675171 139.475906,288.058319 134.579987,292.961182
    M345.374115,356.783936
      C348.407562,352.815491 351.441040,348.847015 355.457947,343.591949
      C345.651489,343.591949 337.555542,343.591949 329.402954,343.591949
      C333.112610,347.935028 336.667969,352.289612 340.467560,356.419464
      C341.500763,357.542480 342.813721,360.331299 345.374115,356.783936
    M300.023712,356.378204
      C295.918854,360.382294 291.814026,364.386383 287.549591,368.546173
      C288.693939,369.534485 289.556519,369.939545 289.869354,370.603577
      C292.293762,375.749756 296.188416,375.259644 300.629028,373.783661
      C305.427521,372.188690 303.745056,368.321686 303.983002,365.121826
      C304.105896,363.469482 303.885925,361.789703 304.035034,360.141693
      C304.282623,357.404999 304.702881,354.683929 305.050415,351.956299
      C304.571960,351.823883 304.093506,351.691498 303.615051,351.559082
      C302.557770,352.959991 301.500488,354.360901 300.023712,356.378204
    M229.991470,219.637283
      C229.483994,215.700745 227.211578,215.045212 224.402786,217.211472
      C220.762985,220.018631 217.482986,223.292328 214.067123,226.348129
      C218.677200,231.230682 223.223572,236.045776 227.844238,240.939560
      C228.571808,233.874985 229.260559,227.187347 229.991470,219.637283
    M120.605179,179.000595
      C126.993416,179.998596 132.328903,178.484360 135.883057,172.140305
      C132.814972,169.428726 129.535507,166.530319 125.676117,163.119385
      C121.348419,168.305817 117.359932,173.085724 113.371445,177.865631
      C113.689011,178.243942 114.006584,178.622238 114.324158,179.000534
      C116.110901,179.000534 117.897644,179.000534 120.605179,179.000595
    M331.709869,367.962799
      C330.471741,360.810394 323.833710,358.557892 319.533478,354.032135
      C319.002991,360.276520 318.940338,366.214569 319.067322,372.148529
      C319.087494,373.091492 319.744232,374.471222 320.496826,374.829437
      C321.359436,375.240051 322.930298,375.120178 323.715637,374.560394
      C326.320831,372.703583 328.711212,370.545471 331.709869,367.962799
    M195.919281,242.409149
      C193.465240,244.562408 191.011200,246.715668 188.557159,248.868912
      C188.832458,249.357056 189.107742,249.845200 189.383041,250.333344
      C197.109177,249.893051 204.835312,249.452759 211.913208,249.049408
      C208.384033,245.224411 204.948395,241.500778 201.234375,237.475449
      C199.587311,239.030914 198.038727,240.493378 195.919281,242.409149
    M321.617950,290.768982
      C321.617950,297.385284 321.617950,304.001587 321.617950,311.179565
      C325.537354,307.816559 329.215851,304.660217 333.426453,301.047333
      C330.149414,297.053497 326.968353,293.141266 323.691559,289.310913
      C323.558655,289.155548 322.522308,289.773102 321.617950,290.768982
    M288.649292,177.906647
      C286.352173,176.017807 284.115906,174.047195 281.732758,172.273987
      C280.505127,171.360550 278.197845,169.872391 277.785583,170.236633
      C275.917297,171.887268 273.847992,173.917114 273.240112,176.183090
      C272.804901,177.805435 274.431946,181.625015 275.374512,181.705505
      C281.386169,182.218933 287.463165,181.966965 293.480042,181.966965
      C292.222382,180.783844 290.784546,179.431168 288.649292,177.906647
    M177.371201,235.963654
      C181.914093,232.548080 186.457001,229.132492 191.001862,225.715424
      C189.035355,224.332352 187.340286,223.140182 186.841385,222.789291
      C180.073669,227.590775 173.910065,231.963669 167.204987,236.720703
      C167.204987,237.594727 167.204987,239.630585 167.204987,242.493622
      C170.748276,240.100479 173.702545,238.105164 177.371201,235.963654
    M230.103012,144.928604
      C230.627502,146.447311 231.152008,147.966034 231.628174,149.344818
      C236.500122,149.344818 241.149445,149.344818 247.084473,149.344818
      C246.460587,144.689178 245.843475,140.084015 245.329208,136.246460
      C243.652054,138.528976 242.452484,141.940964 240.288818,142.738983
      C235.658295,144.446838 232.352554,141.850037 231.257339,136.557373
      C230.841583,136.726578 230.425827,136.895782 230.010071,137.064987
      C230.010071,139.400879 230.010071,141.736771 230.103012,144.928604
    M313.054535,160.844437
      C312.109741,164.707520 311.164917,168.570602 310.280548,172.186554
      C317.060394,171.752792 322.812286,171.384811 329.296295,170.969971
      C329.492340,168.197662 329.737518,164.731201 329.982697,161.264740
      C329.547516,161.217682 329.112335,161.170639 328.677155,161.123596
      C328.169067,162.606934 328.097656,164.673325 327.060425,165.450180
      C325.009918,166.985886 322.489532,168.314438 320.023468,168.628098
      C319.248169,168.726715 317.639526,165.411789 317.117676,163.469696
      C316.610474,161.582153 317.007568,159.451614 317.007568,157.427780
      C316.652893,157.332947 316.298248,157.238129 315.943573,157.143295
      C315.101776,158.148178 314.259949,159.153061 313.054535,160.844437
    M146.928055,360.380920
      C146.928055,356.417511 146.928055,352.454132 146.928055,348.037018
      C143.259628,350.459961 139.860535,352.434021 136.822372,354.860046
      C135.771698,355.699097 135.036636,357.748383 135.236496,359.083282
      C135.399460,360.171753 137.128464,361.763947 138.236572,361.826294
      C140.940445,361.978363 143.686951,361.372559 146.928055,360.380920
    M116.617180,193.996292
      C113.521263,193.644501 110.425354,193.292694 105.920448,192.780792
      C111.949593,198.497192 116.772041,203.069473 121.915649,207.946259
      C122.170670,202.548645 122.360374,198.533524 122.574783,193.995392
      C121.105255,193.995392 119.316605,193.995392 116.617180,193.996292
    M136.800797,151.382889
      C135.420471,156.659088 140.008102,155.558365 142.039764,156.945663
      C143.451248,154.639771 144.412369,152.526917 145.854630,150.820770
      C149.407944,146.617279 149.496811,146.692398 148.158722,140.510345
      C144.247131,144.077927 140.626236,147.380356 136.800797,151.382889
    M156.561707,169.005981
      C159.371170,167.891357 162.703796,167.361725 164.818680,165.470413
      C166.409088,164.048157 167.227951,160.744690 164.346634,159.117538
      C161.903397,160.873001 159.893372,163.318527 157.532684,163.724380
      C155.288345,164.110260 152.677460,162.364044 150.088821,161.509430
      C149.775497,165.573959 150.161118,169.125290 156.561707,169.005981
    M217.433090,157.938416
      C223.699875,154.131653 222.068268,148.346771 221.647354,142.154068
      C217.995529,146.163956 214.830841,149.638916 211.591034,153.196381
      C213.053284,154.558945 214.875443,156.256882 217.433090,157.938416
    M264.403381,160.406784
      C265.117645,160.552155 265.831879,160.697525 266.546143,160.842896
      C266.334656,159.696243 266.504486,158.218124 265.845703,157.460266
      C262.984253,154.168396 259.881226,151.086502 256.557190,147.604843
      C255.369507,152.034180 255.279480,155.021927 259.599457,156.555862
      C261.192841,157.121628 262.381317,158.827545 264.403381,160.406784
    M331.014191,150.475113
      C332.106232,154.054626 333.198273,157.634155 334.651642,162.398193
      C336.835480,159.210876 338.015167,157.489075 339.572540,155.216034
      C336.719238,153.355804 334.160309,151.687500 331.014191,150.475113
    M322.163147,241.383194
      C321.671570,243.046204 321.179962,244.709229 320.268036,247.794205
      C324.626862,244.866394 327.761444,242.760895 331.641693,240.154526
      C328.042969,240.519409 325.476349,240.779633 322.163147,241.383194
    z"/>

    </svg>

    <div class="c-leaf__fill"></div>
</div>


