<div class="wt-bnr-inr overlay-wraper bg-center" style="background-image:url('{{this.path}}');">
    <div class="overlay-main site-bg-secondry opacity-07"></div>
    <div class="container d-flex justify-content-center">
        <div class="wt-bnr-inr-entry">
            <div class="banner-title-outer">
                <div class="banner-title-name">
                    <h2 class="site-text-primary"></h2>
                </div>
            </div>
            <!-- BREADCRUMB ROW -->

                <div class="d-flex justify-content-center">
                    <ul class="wt-breadcrumb breadcrumb-style-2" style="text-align: center;">
                        <li *ngFor="let breadcrumbs of breadcrumbs; let isLast = last">
                            <a *ngIf="!isLast; else lastBreadcrumb" [routerLink]="breadcrumbs.url">{{ breadcrumbs.label }}</a>
                            <ng-template #lastBreadcrumb>
                              <span>{{ breadcrumbs.label }}</span>
                            </ng-template>

                          </li>
                    </ul>
                </div>

            <!-- BREADCRUMB ROW END -->
        </div>
    </div>
</div>
