<div class="container mt-3">
  <div class="search-header">
    <i class="fa fa-address-book icon"></i>
    <div class="text-content">
      <div class="title">Rehber</div>
      <div class="subtitle">
        Kişi ve Birim telefon numaralarını sorgulayabilirsiniz.
      </div>
      <div class="divider"></div>
    </div>
  </div>

  <div class="input-group search-container">
    <input
      type="text"
      class="form-control"
      (keyup)="onSearchChange($event)"
      placeholder=" Kişi ve Birim telefon numaralarını sorgulayabilirsiniz."
    />
    <span class="input-group-text">
      <i class="fa fa-search"></i>
      <!-- FontAwesome arama simgesi -->
    </span>
  </div>
</div>
<div class="container mt-4">
  <div *ngIf="people.length > 0" class="mt-4">
    <table class="table table-striped table-responsive">
      <thead>
        <tr>
          <th>
            <button class="btn btn-link" (click)="sortBy('ad')">Ad</button>
          </th>
          <th>
            <button class="btn btn-link" (click)="sortBy('soyad')">
              Soyad
            </button>
          </th>
          <th>
            <button class="btn btn-link" (click)="sortBy('telefon')">
              Telefon
            </button>
          </th>
          <th>Görev Birim</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let person of people">
          <td>{{ person.ad }}</td>
          <td>{{ person.soyad }}</td>
          <td *ngIf="person.telefon">{{ person.telefon }}</td>
          <td *ngIf="!person.telefon">-</td>
          <td>{{ person.gorevBirimKirilim }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
