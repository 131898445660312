import { Component, Input, OnInit } from '@angular/core';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import { LangService } from 'src/app/services/lang-service';

@Component({
  selector: 'app-section-inner-banner',
  templateUrl: './section-inner-banner.component.html',
  styleUrls: ['./section-inner-banner.component.css'],
})
export class SectionInnerBannerComponent implements OnInit {
  @Input() data: any;

  @Input() title: any;
  @Input() breadcrumbs: Breadcrumb[] = [];

  breadcrumb: Breadcrumb[] = [{ label: 'Anasayfa', url: '/index' }];

  path: string =
    'https://www.ankara.edu.tr/wp-content/themes/75yil/img/bg-page/8.jpg';
  constructor( private langService: LangService,) {}

  ngOnInit(): void {


    this.breadcrumb= this.breadcrumbs;
    this.langService.currentLangCode$.subscribe(langCode => {


      if (this.data != null) {
        this.path =
          'https://dizin.ankara.edu.tr/assets/' + this.data.split('?')[0];
      }

    });

     }
}
