import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDetailListBase } from 'src/app/contracts/base/detail-base';
import { CategoryEntity } from 'src/app/contracts/cms/category-entity';
import { CMSDataEntity } from 'src/app/contracts/cms/cms-data-entity';

import { NewsEntity } from 'src/app/contracts/cms/news-entity';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { TranslationService } from 'src/app/services/translation.services';

const ayIsimleriTR = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];
const ayIsimleriEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
@Component({
  selector: 'app-section-search-list',
  templateUrl: './section-search-list.component.html',
  styleUrls: ['./section-search-list.component.css'],
})
export class SectionSearchListComponent implements OnInit {
  aranan: string | any;

  newsData: IDetailListBase<CMSDataEntity[]> | any;
  method: string | any;
  page = 1;
  controlPage = 0;

  currentLang = 'tr-TR';
  getlang = 'primary';
  currentDate: Date = new Date();
  onceki = '';
  sonraki = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClientService: CMSDynamicService,
    private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService
  ) {


    this.activatedRoute.queryParams.subscribe(params => {
      this.aranan = params['q'];
      // Şimdi bu arama sorgusunu kullanabilirsiniz
    });

    if (this.activatedRoute.snapshot.params['page']) {
      this.page = this.activatedRoute.snapshot.params['page'];
      this.controlPage = this.activatedRoute.snapshot.params['page'];
    }
  }
  pagesArray: number[] = [];

  async ngOnInit() {

    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.translationServices();
    });
    if (!this.page) {
      this.page = 0;
    }
  }

  async translationServices() {
    const lang: 'en' | 'tr' = this.storageService.getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';
    await this.getNews();

    this.onceki = this.translationService.getTranslation('onceki', lang);
    this.sonraki = this.translationService.getTranslation('sonraki', lang);
  }

  async getNews() {

    const searchQuery =  await this.generateSearchQuery(this.aranan);
    const promiseData: IDetailListBase<CMSDataEntity[]> | any =
      await this.httpClientService.getPageable(
        this.page - 1,
        50,
        'CMS',
        'GetMainCms',
        '&Filter.Field=Baslik&Filter.Operator=contains&Filter.Value='+searchQuery,
        1,
        this.currentLang
      );

    this.newsData = promiseData;
    this.pagesArray = Array.from(
      { length: this.newsData.pages },
      (_, index) => index + 1
    );

    this.limitPages(20);
  }

 async  generateSearchQuery(aranan: string) {
    // Arama teriminin ilk harfini büyük yapacak yardımcı fonksiyon
    function capitalizeFirstLetter(word: string): string {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    // Arama terimlerini formatlayalım
    const formattedAranan = capitalizeFirstLetter(aranan);

    // Arama sorgusunu oluşturalım
    const searchQuery = formattedAranan;

    return searchQuery;
  }
  limitPages(limit: number) {
    this.pagesArray = this.pagesArray.slice(0, limit);
  }
  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);

    return splitDate[0].split('-')[2];
  }
  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }
  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }

  // Ay isimlerini döndüren fonksiyon
  ayIsimAl(ayIndex: number): string {
    const lang = this.currentLang;
    switch (lang) {
      case 'tr-TR':
        return ayIsimleriTR[ayIndex] || '';
      case 'en-EN':
        return ayIsimleriEN[ayIndex] || '';
      default:
        return '';
    }
  }
}
