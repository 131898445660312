import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AcedemicPersonelEntity } from 'src/app/contracts/cms/acedemic-personel-entity';
import { ActivityHistoryEntity } from 'src/app/contracts/cms/avesis-entities';
import { BbsCodes } from 'src/app/contracts/cms/bbs-code-entity';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import { UnitDetailEntity } from 'src/app/contracts/cms/unit-detail';
import { UnitEntity } from 'src/app/contracts/cms/unit-entity';
import { Program, UnitName, UnitProgram } from 'src/app/contracts/cms/unit-program';
import { UnitData } from 'src/app/contracts/custom-data/unit-data';
import { RequestParameters } from 'src/app/services/http-client-services';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { AvesisAuthService, _avesisAccessToken, _avesisIsAuthenticated } from 'src/app/services/models/avesis-auth.service';
import { AveisLoginService } from 'src/app/services/models/avesis-login.service';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
import { TranslationService } from 'src/app/services/translation.services';
import { YouTubeVideoService } from 'src/app/services/youurbe-services';
import { environment } from 'src/environments/environment';
type langg = 'primary' | 'secondary';
@Component({
  selector: 'app-unit-detail-page',
  templateUrl: './unit-detail-page.component.html',
  styleUrls: ['./unit-detail-page.component.css'],
})
export class UnitDetailPageComponent implements OnInit {
  param: string | any;
  param2: string | any;
  unitDetail: UnitDetailEntity[] | any;

  bbsCodes: BbsCodes[] | any;


  title = '';
  currentLang = 'tr-TR';

  Lang:keyof UnitName="tr";


  getlang: langg='primary';
  breadcumb: Breadcrumb[] = [
    {
      label: 'Birimler',
      url: '/dizin/birimler',
    },
  ];

  acedemicPersonels: any[] | any;
  latestPublication: any;

  latestPublicationa: ActivityHistoryEntity[]=[];
  programs: UnitProgram | any;

  dekan="";
  bashekim="Başhekim";
  HastaneBashekim="Hastaneler Başhekimi";
  adres="";
  update="";
  personel="";
  telefon="";
  program="";

  faks="";
  eposta="";
  mudur="";

  programData: Program[] | any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClientService: CMSDynamicService,
    private sanitizer: DomSanitizer,
    public avesisAuthService: AvesisAuthService,
    public aveisLoginService: AveisLoginService,
    private translationService: TranslationService,
    private langService: LangService,
    private storageService: StorageService,
    private seoService: SeoService,
    private youTubeVideoService: YouTubeVideoService,

  ) {
    this.param = this.activatedRoute.snapshot.params['param'];
    this.param2 = this.activatedRoute.snapshot.params['param2'];
    aveisLoginService.login();
    avesisAuthService.identityCheck();
  }

  async ngOnInit(): Promise<void> {
  await this.langServices();

  }
  langSelected(val:String){

    if(val=="tr-TR"){
      this.Lang="tr"

    }

    if(val=="en-EN"){
      this.Lang="en"
    }
  }

  videoUrl: SafeResourceUrl | any;
  async langServices() {
    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;

      const lang = this.storageService.getData('siteLanguage')?.split('-')[0];
    this.getlang = (lang === 'en' ? 'secondary' : 'primary') as langg;
     await this.langSelected(this.currentLang)
      this.breadcumb = [];
      this.breadcumb = [
        {
          label: 'Birimler',
          url: '/dizin/birimler',
        },
      ];
      this.translationServices();
      this.getUnitDetail(this.param, this.param2);
    this.bbsCodes = UnitData.bbCodes();

    if (!_avesisIsAuthenticated) {
      this.avesisAuthService.identityCheck();
      }
    });
  }
  translationServices() {
    const lang: 'en' | 'tr' = this.storageService.getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';

    var getparam = '';
    if (this.param == 'Fakulteler') {
      getparam = 'fakulteler';
    }
    if (this.param == 'enstituler') {
      getparam = 'enstituler';
    }
    if (this.param == 'konservatuvar') {
      getparam = 'konservatuvar';
    }
    if (this.param == 'myo') {
      getparam = 'myo';
    }
    if (this.param == 'yuksekokullari') {
      getparam = 'yuksekokul';
    }
    if (this.param == 'idari') {
      getparam = 'idaribirimler';
    }
    if (this.param == 'koordinatorlukler') {
      getparam = 'koordinatorlukler';
    }
    if (this.param == 'merkezler') {
      getparam = 'merkezler';
    }
    if (this.param == 'hastaneler') {
      getparam = 'hastaneler';
    }

    this.dekan = this.translationService.getTranslation("dekan", lang);

    this.mudur = this.translationService.getTranslation("mudur", lang);
    this.adres = this.translationService.getTranslation("adres", lang);
    this.telefon = this.translationService.getTranslation("telefon", lang);
    this.update = this.translationService.getTranslation("akademikgelismeler", lang);
    this.personel = this.translationService.getTranslation("akademikpersonel", lang);
    this.program = this.translationService.getTranslation("programlar", lang);
    this.faks = this.translationService.getTranslation("faks", lang);
    this.eposta = this.translationService.getTranslation("eposta", lang);

    this.breadcumb.push({
      label: this.title,
      url: 'dizin/' + this.param,
    });


  }


  transform(video: any) {
    var url = video + '?autoplay=1';
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  private initialLoad: boolean = true;
  private loadVideo() {
    if (this.initialLoad) {
      const iframeElement = document.getElementById('youtubeVideo') as HTMLIFrameElement;
      if (iframeElement) {
        iframeElement.src = iframeElement.src;
      }
      this.initialLoad = false;
    }
  }
  updateMetaTags(titlex:string="",ulr:string=''): void {
    const title =
      this.currentLang == 'tr-TR'
        ?  titlex.length>=2 ? titlex + ' | Ankara Üniversitesi': ' | Ankara Üniversitesi'
        :titlex.length>=2 ? titlex + ' |Ankara University': ' | Ankara University';

    const description =
      this.currentLang == 'tr-TR'
        ? 'Cumhuriyetin İlk Üniversitesi '
        : 'The First University of the Republic';
    const keywords = ['Ankara', 'üniversite', 'Birimler', 'öğrenci'];
    const authors = [
      this.currentLang == 'tr-TR' ? 'Ankara Üniversitesi' : 'Ankara University',
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl =
      'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/dizin/'+this.param+'/' +this.param2.toLowerCase();

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      titlex.length>=2 ? titlex : "Birimler",
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );

    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }

  aciklamasi? :SafeHtml;
  getDescription(object: UnitDetailEntity | any): SafeHtml {

    if (object) {
        if (this.currentLang === 'tr-TR') {

            return this.sanitizer.bypassSecurityTrustHtml(object.Aciklama || '');
        } else {
            const translation = object.translations?.find((trans:any) => trans.languages_code === 'en-US');
            return translation ? this.sanitizer.bypassSecurityTrustHtml(translation.content ||object.Aciklama) :object.Aciklama;
        }
    }
    return this.sanitizer.bypassSecurityTrustHtml(object.Aciklama || ''); // null veya undefined ise boş dize döner
}


getTitle(object: UnitEntity | any): string {

  if (object) {
  if (this.currentLang === 'tr-TR') {
    return object.Baslik || ''; // Türkçe başlık, undefined ise boş dize döner
  } else {
    const translation = object.translations?.find((trans:any) => trans.languages_code === 'en-US');
    return translation ? translation.title || object.Baslik :object.Baslik; // İngilizce başlık, undefined ise boş dize döner
  }
  }
  return object.Baslik || '';
}

  async getUnitDetail(param: string, param2: string) {
    var requestParameter: Partial<RequestParameters> = {
      fullEndPoint:
      environment.baseUrl+ '/Kapi/GetUnitDetail?param=' +
        param +
        '&param2=' +
        param2
       ,
      headers: new HttpHeaders({
        Accept: 'application/json, text/plain, */*',
      }),
    };

    const promise: UnitDetailEntity[] | any = await this.httpClientService.getx(
      requestParameter
    );

    this.unitDetail = promise.data;
    this.videoUrl = this.transform(this.unitDetail.Video);
    var bolognaId = await this.getBolognaId(this.unitDetail);

    this.getBologna(bolognaId);

    var abisiId = await this.getAbisisId(this.unitDetail);
    this.getAvesis(abisiId,bolognaId);





    this.updateMetaTags(this.unitDetail.Baslik,this.param)


  }

  async getAbisisId(value: UnitDetailEntity) {
    var elementx: any;
    this.bbsCodes.forEach((element: any) => {
      if (element['slug'] == value.slug) {
        elementx = element['abisis-id'];
      }
    });

    return elementx;
  }

  async getBolognaId(value: UnitDetailEntity) {
    var elementx: any;
    this.bbsCodes.forEach((element: any) => {
      if (element['slug'] == value.slug) {
        elementx = element['bologna-id'];
      }
    });

    return elementx;
  }

  async getAvesis(value: number,val2:number) {


    var requestParameter2: Partial<RequestParameters> = {
      fullEndPoint:"https://avesisveri.ankara.edu.tr/songuncellemeler.php?id="+value
        /* 'https://avesis.ankara.edu.tr/api/activityhistory?criteria.lastNItem=30&criteria.unitId=' +
        value, */
    /*   headers: new HttpHeaders({

        Authorization: 'Bearer ' + _avesisAccessToken,
      }), */
    };

    const promise2: AcedemicPersonelEntity[] | any =
      await this.httpClientService.getx(requestParameter2);

    this.latestPublicationa = promise2;



    var requestParameter: Partial<RequestParameters> = {
      fullEndPoint: "https://avesisveri.ankara.edu.tr/akademisyenler.php?id="+value
       /*  'https://abisis.ankara.edu.tr/api/user?criteria.isActive=true&criteria.isAcademician=true&criteria.facultyId=' +
        value,
      headers: new HttpHeaders({

        Authorization: 'Bearer ' + _avesisAccessToken,
      }), */
    };

    const promise: AcedemicPersonelEntity[] | any =
      await this.httpClientService.getx(requestParameter);

    this.acedemicPersonels = promise;

  }

  async getBologna(bolognaId: number) {
    var requestParameter: Partial<RequestParameters> = {
      fullEndPoint:
       environment.baseUrl+ '/Kapi/GetBologna?bolognaId=' +
        bolognaId


    };

    const promise: UnitProgram | any = await this.httpClientService.getx(
      requestParameter
    );

    this.programs = promise;

    this.programData = this.programs.data.degree[0].unit.list[0].list;


  }
}
