<div class="container-fluid plr-100 custom-bottom p-t40">
  <div class="row">
    <div
      class=" col-lg-8 col-md-12  col-12 service-gallery-style1-wrapper"
      *ngIf="latestPublications"
    >
      <div class="services-gallery-block-outer">
        <div class="container-fluid half-wide-bg-outer p-t20">
          <div class="center wt-small-separator-outer d-none d-md-block">
            <h4 class="wt-title l40">{{ title }}</h4>
          </div>
          <div class="divider l40 d-none d-md-block"></div>
          <div class="half-wide-bg "></div>
          <div class="section-content">
            <div class="row">
              <div class="col-lg-4 col-md-12  ">
                <!-- TITLE START-->
                <div
                  class="section-head left wt-small-separator-outer text-white"
                >

                  <h4 class="wt-title"> {{ academictitle }} {{ subtitle }}</h4>
                  <p>{{ academicmetin }}</p>

                  <a
                    href="https://avesis.ankara.edu.tr/"
                    class="site-button-secondry site-btn-effect"
                    >AVESİS</a
                  >
                </div>
                <!-- TITLE END-->
              </div>

              <div class="col-lg-8 col-md-12  p-t20">
                <div class="services-gallery-style2">
                  <div
                    class="owl-carousel services-gallery-two owl-btn-vertical-center"
                  >
                    <!-- COLUMNS 1 -->
                    <div class="item" *ngFor="let latest of latestPublications">
                      <div class="service-box-style2">

                        <div class="service-content">
                          <h4 class="service-title-large au-h">
                            <a
                              target="_blank"
                              href="https://avesis.ankara.edu.tr/{{
                                latest.userAvesisUrl
                              }}"
                              >{{latest.title?.[getlang]}} {{ latest.name }}
                              {{ latest.surname }}</a
                            >
                          </h4>
                          <p class="au-p">
                            {{latest.message?.[getlang]}}
                            <br />{{latest.activityTitle?.[getlang]?? ""}}
                          </p>
                          <p class="au-p2">
                            {{latest.facultyName?.[getlang]}} -
                            {{latest.departmentName?.[getlang]}}
                          </p>
                          <a
                            target="_blank"
                            href="https://avesis.ankara.edu.tr/{{
                              latest.userAvesisUrl
                            }}"
                            class="site-button site-btn-effect"
                            >Avesis -></a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-12 col-12" *ngIf="activityHistorys">
      <app-section-testimonials6
        [activity]="activityHistorys"
      ></app-section-testimonials6>
    </div>
  </div>
</div>
