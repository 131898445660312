import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { filter } from 'rxjs';
import { ItemDetailModel } from 'src/app/contracts/base/news-detail-model';
import { CMSDataEntity } from 'src/app/contracts/cms/cms-data-entity';
import { Protocol } from 'src/app/contracts/cms/protocol-entity';
import { RequestParameters } from 'src/app/services/http-client-services';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { TranslationService } from 'src/app/services/translation.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-section-protokol',
  templateUrl: 'protokoller.component.html',
  styleUrls: ['protokoller.component.css'],
})
export class SectionProtokolComponent implements OnInit {


  constructor(private httpClientService: CMSDynamicService) {}
  protocols: Protocol[] = [];
  paginatedProtocols: Protocol[] = [];
  pageSize: number = 10;
  currentPage: number = 1;
  totalPages: number = 0;
  pages: number[] = [];
  countries: string[] = [];
  selectedCountry: string = '';
  showActive: boolean = true;
  async ngOnInit() {
    await this.loadProtocols();
  }
  async loadProtocols(): Promise<void> {
    try {
      var requestParameter: Partial<RequestParameters> = {
        fullEndPoint:environment.baseUrl+ '/Kapi/getProtocol',
      };

      const promiseData: Protocol[] = await this.httpClientService.getx(
        requestParameter
      );
      this.protocols = promiseData;


     await this.extractCountries();
      this.updatePagination();
    } catch (error) {}
  }
  async extractCountries() {
    const countrySet = new Set<string>();
    this.protocols.forEach((protocol) => {
      if (protocol.protocolNational) {
        countrySet.add(protocol.protocolNational);
      }
    });
    const countryArray = Array.from(countrySet);

    this.countries = countryArray.sort((a, b) => a.localeCompare(b));

  }

  updatePagination(): void {
    const filteredProtocols = this.filterProtocols();
    this.totalPages = Math.ceil(filteredProtocols.length / this.pageSize);
    this.pages = Array(this.totalPages)
      .fill(0)
      .map((x, i) => i + 1);
    this.paginate();
  }

  filterProtocols(): Protocol[] {
    let filtered = this.protocols;
    if (this.selectedCountry) {
      filtered = filtered.filter(
        (protocol) => protocol.protocolNational === this.selectedCountry
      );
    }
    const now = new Date();
    filtered = filtered.filter((protocol) =>
      this.showActive
        ? new Date(protocol.finishDate) >= now
        : new Date(protocol.finishDate) < now
    );
    filtered.sort(
      (a, b) =>
        new Date(b.finishDate).getTime() - new Date(a.finishDate).getTime()
    );
    return filtered;
  }

  paginate(): void {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    this.paginatedProtocols = this.filterProtocols().slice(
      startIndex,
      startIndex + this.pageSize
    );
    window.scrollTo(0, 0);
  }

  setPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.paginate();
    }
  }

  previousPage(): void {
    window.scrollTo(0, 0);
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginate();
    }
    window.scrollTo(0, 0);
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginate();
    }
    window.scrollTo(0, 0);
  }

  onCountryChange(): void {
    this.currentPage = 1;
    this.updatePagination();
  }

  onActiveChange(): void {
    this.currentPage = 1;
    this.updatePagination();
  }
}
