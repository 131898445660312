import { HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BbsCodes } from 'src/app/contracts/cms/bbs-code-entity';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import { UnitDetailEntity } from 'src/app/contracts/cms/unit-detail';
import {
  Program,
  UnitProgram,
  Degree,
  Unit,
  UnitName,
} from 'src/app/contracts/cms/unit-program';
import { UnitProgramDetailEntity } from 'src/app/contracts/cms/unit-program-detail';
import { UnitData } from 'src/app/contracts/custom-data/unit-data';
import { RequestParameters } from 'src/app/services/http-client-services';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
import { TranslationService } from 'src/app/services/translation.services';
import { Location } from '@angular/common';
import { Slugify } from 'src/app/utils/slugify';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
const turkishMap: { [key: string]: string } = {
  ç: 'c',
  ğ: 'g',
  ı: 'i',
  ö: 'o',
  ş: 's',
  ü: 'u',
  Ç: 'C',
  Ğ: 'G',
  İ: 'I',
  Ö: 'O',
  Ş: 'S',
  Ü: 'U',
};

@Component({
  selector: 'app-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.css'],
})
export class ProgramDetailComponent implements OnInit {
  programs: UnitProgramDetailEntity | any;
  unitDetail: UnitDetailEntity[] | any;

  bbsCodes: BbsCodes[] | any;
  param: string | any;
  param2: string | any;
  unit: string | any;
  degreeId: string | any;

  detailType: string | any;
  detailSlug: string | any;
  currentLang = 'tr-TR';
  getLangDegree: keyof UnitName = 'tr';
  getLang: keyof UnitName = 'tr';
  breadcumb: Breadcrumb[] = [];


  title="";
  tanitim= "";
  programamac= "";
  courselist= "";
  coursename= "";
  code= "";
  tp= "";
  kredi= "";
  akts= "";
  derskazanim= "";
  kazanilanderece= "";
  kariyersecenekleri= "";
  akademikgelecek= "";
  mezuniyetsartlari= "";
  derecesartlari= "";
  sinavlardegerlendirme= "";
  nasilkayit= "";
  coursebilgi= "";
  coursetitle= "";
  coursecode= "";
  dersduzey= "";
  yariyil= "";
  dersdili= "";
  dersiverenler= "";
  amacvekazanimlar= "";
  haftalikdersakisi= "";
  olcmedegerlendirme= "";
  derecex= "";
  programtipi= "";
  programdili= "";
  programturu= "";
  egitimturu="";
  currentUrl: string | any;
  constructor(
    private httpClientService: CMSDynamicService,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
    private langService: LangService,
    private storageService: StorageService,
    private seoService: SeoService,
    private router: Router, private location: Location
  ) {
    this.unit = this.activatedRoute.snapshot.params['unit'];
    this.param = this.activatedRoute.snapshot.params['param'];

    this.degreeId = this.activatedRoute.snapshot.params['degree'];


  }

  async ngOnInit(): Promise<void> {
    this.router.events.pipe(
      filter((event:any) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.currentUrl = this.location.path();

    });



    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.bbsCodes = UnitData.bbCodes();
      await this.translationServices();
    });

  }



  async langSelected(val: String) {
    if (val == 'tr-TR') {
      this.getLang = 'tr';
      this.getLangDegree = 'tr';
    }

    if (val == 'en-EN') {
      this.getLang = 'en';
      this.getLangDegree = 'en';
    }
  }
  async translationServices() {
    this.langSelected(this.currentLang);

    const lang: 'en' | 'tr' = this.storageService.getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';


    this.tanitim = await this.translationService.getTranslation('tanitim', lang);
    this.programamac = await this.translationService.getTranslation('programamac', lang);
    this.courselist = await this.translationService.getTranslation('courselist', lang);
    this.coursename = await this.translationService.getTranslation('coursename', lang);
    this.code = await this.translationService.getTranslation('code', lang);
    this.tp = await this.translationService.getTranslation('tp', lang);
    this.kredi = await this.translationService.getTranslation('kredi', lang);
    this.akts = await this.translationService.getTranslation('akts', lang);
    this.derskazanim = await this.translationService.getTranslation('derskazanim', lang);
    this.kazanilanderece = await this.translationService.getTranslation('kazanilanderece', lang);
    this.kariyersecenekleri = await this.translationService.getTranslation('kariyersecenekleri', lang);
    this.akademikgelecek = await this.translationService.getTranslation('akademikgelecek', lang);
    this.mezuniyetsartlari = await this.translationService.getTranslation('mezuniyetsartlari', lang);
    this.derecesartlari = await this.translationService.getTranslation('derecesartlari', lang);
    this.sinavlardegerlendirme = await this.translationService.getTranslation('sinavlardegerlendirme', lang);
    this.nasilkayit = await this.translationService.getTranslation('nasilkayit', lang);
    this.coursebilgi = await this.translationService.getTranslation('coursebilgi', lang);
    this.coursetitle = await this.translationService.getTranslation('coursetitle', lang);
    this.coursecode = await this.translationService.getTranslation('coursecode', lang);
    this.dersduzey = await this.translationService.getTranslation('dersduzey', lang);
    this.yariyil = await this.translationService.getTranslation('yariyil', lang);
    this.dersdili = await this.translationService.getTranslation('dersdili', lang);
    this.dersiverenler = await this.translationService.getTranslation('dersiverenler', lang);
    this.amacvekazanimlar = await this.translationService.getTranslation('amacvekazanimlar', lang);
    this.haftalikdersakisi = await this.translationService.getTranslation('haftalikdersakisi', lang);
    this.olcmedegerlendirme = await this.translationService.getTranslation('olcmedegerlendirme', lang);
    this.derecex = await this.translationService.getTranslation('derece', lang);
    this.programtipi = await this.translationService.getTranslation('programtipi', lang);
    this.programdili = await this.translationService.getTranslation('programdili', lang);
    this.programturu = await this.translationService.getTranslation('programdirektor', lang);
    this.egitimturu = await this.translationService.getTranslation('egitimturu', lang);

    this.breadcumb = [
      {
        label: this.title,
        url: '/dizin/programlar',
      },
    ];

  await  this.getBologna();
  }


  updateMetaTags(titlex:string=""): void {
    const title =
      this.currentLang == 'tr-TR'
        ?  titlex.length>=2 ? titlex + ' | Ankara Üniversitesi': 'Programlar | Ankara Üniversitesi'
        :titlex.length>=2 ? titlex + ' |Ankara University': 'Programs | Ankara University';

    const description =
      this.currentLang == 'tr-TR'
        ? 'Cumhuriyetin İlk Üniversitesi '
        : 'The First University of the Republic';
    const keywords = ['Ankara', 'üniversite', 'programlar', 'öğrenci'];
    const authors = [
      this.currentLang == 'tr-TR' ? 'Ankara Üniversitesi' : 'Ankara University',
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl =
      'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/programlar/'+ this.degreeId+ '/'+this.unit+'/'+this.param

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      titlex !=null ? titlex : "Programlar",
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );

    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }

  async getUnitDetail(param: string, param2: string) {

    var deg= param.split("-");
    var requestParameter: Partial<RequestParameters> = {
      fullEndPoint:
        'https://dizin.ankara.edu.tr/items/' +
       param+
        '/' +
       param2 +
        '?access_token=ankara123dizin!',
      headers: new HttpHeaders({
        Accept: 'application/json, text/plain, */*',
      }),
    };

    const promise: UnitDetailEntity[] | any = await this.httpClientService.getx(
      requestParameter
    );

    this.unitDetail = promise.data;
  }

  async getSlug(value: number) {
    var elementx: any;
    this.bbsCodes.forEach((element: any) => {
      if (element['bologna-id'] == value) {
        elementx = element['slug'];
      }
    });

    if (this.degreeId == 1) {
      this.detailType = 'myo';
    }
    if (this.degreeId == 2) {
      this.detailType = 'Fakulteler';
    }
    if (this.degreeId == 3) {
      this.detailType = 'enstituler';
    }
    if (this.degreeId == 4) {
      this.detailType = 'enstituler';
    }

    return elementx;
  }

   slugify(text:string) {
    return text
      .toString()
      .split('')
      .map((char) => turkishMap[char] || char)
      .join('')
      .toLowerCase()
      .replace(/\s+/g, '-') // Boşlukları - ile değiştir
      .replace(/\//g, '-')  // / işaretlerini - ile değiştir
      .replace(/[^\w\-]+/g, '') // Tüm kelime olmayan karakterleri kaldır
      .replace(/\-\-+/g, '-') // Birden fazla - ile değiştirilmiş karakterleri tek - ile değiştir
      .replace(/^-+/, '') // Metnin başındaki - işaretlerini kaldır
      .replace(/-+$/, ''); // Metnin sonundaki - işaretlerini kaldır
  }


  ///  $dizin_data = getURL("https://dizin.ankara.edu.tr/items/" . getUnit(getDegree($bbscodes, $program_detay->unit->id)) . "/" . getSlug($bbscodes, $program_detay->unit->id) . "/?access_token=ankara123dizin!&fields=slug,Baslik,Kapak_Gorseli,Web_Sitesi,Logo&filter[status][_eq]=published");
  ///
  async getBologna() {

    var deg= this.param.split("-");
    var requestParameter: Partial<RequestParameters> = {
      fullEndPoint:
       environment.baseUrl+ '/Kapi/getProgram?programId=' +
        deg[0] +
        '&botId=' +
       deg[1] ,
    };

    const promise: UnitProgramDetailEntity | any =
      await this.httpClientService.getx(requestParameter);

    this.programs = promise;
    this.detailSlug = await this.getSlug(this.unit);


    this.getUnitDetail(this.detailType, this.detailSlug);

    this.updateMetaTags(this.programs.data.program.title[this.getLang]);
  }
}
