import { Injectable } from '@angular/core';

import { catchError, firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClientService } from '../http-client-services';
import { HttpErrorResponse } from '@angular/common/http';
import { AccesTokenEntity } from 'src/app/contracts/cms/avesis-entities';
import { StorageService } from '../local-storage-serves';

@Injectable({
  providedIn: 'root',
})
export class AveisLoginService {
  constructor(
    private httpClientService: HttpClientService,
    private jwtHelperService: JwtHelperService,
    private storageService: StorageService
  ) {}

  async login(): Promise<any> {
    const observable: Observable<any | AccesTokenEntity> =
      this.httpClientService.avesisLogin().pipe(
        catchError((error) => {
          return this.getServerErrorMessage(error);
        })
      );

    console.log(observable);
    const tokenResponse: AccesTokenEntity = (await firstValueFrom(
      observable
    )) as AccesTokenEntity;

    if (tokenResponse) {

      this.storageService.saveData('avesis_accessToken', tokenResponse?.access_token ?? '');


      const decodeToken = this.jwtHelperService.decodeToken(
        tokenResponse?.access_token ?? ''
      );
    }
  }

  private getServerErrorMessage(error: HttpErrorResponse): any {
    switch (error.status) {
      case 400: {
        return console.log('Kullanıcı girişi Başarısız.', 'Giriş Başarısız');
      }
      case 403: {
        return console.log('Kullanıcı girişi Başarısız.', 'Giriş Başarısız');
      }
      case 500: {
        return console.log('Kullanıcı girişi Başarısız.', 'Giriş Başarısız');
      }
      default: {
        return console.log('Kullanıcı girişi Başarısız.', 'Giriş Başarısız');
      }
    }
  }
}
