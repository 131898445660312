import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { SectionSlider4Component } from './modules/home/section-slider4/section-slider4.component';
import { UnitGridListComponent } from './modules/home/units/unit-grid-list/unit-grid-list.component';
import { UnitDetailPageComponent } from './modules/home/units/unit-detail-page/unit-detail-page.component';
import { ProgramListComponent } from './modules/home/program/program-list/program-list.component';
import { ProgramDetailComponent } from './modules/home/program/program-list/program-detail/program-detail.component';
import { ProgramMainListComponent } from './modules/home/program/program-list/program-main-list/program-main-list.component';
import { ProgramMainSubListComponent } from './modules/home/program/program-list/program-main--sub-list/program-main--sub-list.component';

import { SectionPostListComponent } from './modules/home/section-post-list/section-post-list.component';
import { SectionPostCategoryListComponent } from './modules/home/section-post-category-list/section-post-category-list.component';
import { SectionPageCategoryListComponent } from './modules/home/section-page-category-list/section-page-category-list.component';
import { SectionPageDetailComponent } from './modules/home/section-page-detail/section-page-detail.component';
import { SectionPostDetailComponent } from './modules/home/section-post-detail/section-post-detail.component';
import { SectionEventCategoryListComponent } from './modules/home/section-event-category-list/section-event-category-list.component';
import { SectionEventListComponent } from './modules/home/section-event-list/section-event-list.component';
import { SectionEventDetailComponent } from './modules/home/section-event-detail/section-event-detail.component';
import { SectionNoticeCategoryListComponent } from './modules/home/section-notice-category-list/section-notice-category-list.component';
import { SectionNoticeDetailComponent } from './modules/home/section-notice-detail/section-notice-detail.component';
import { SectionNoticeListComponent } from './modules/home/section-notice-list/section-notice-list.component';
import { SectionSearchListComponent } from './modules/home/section-search-list/section-search-list.component';
import { PageError404Component } from './modules/home/page-error404/page-error404.component';
import { SectionSearchDetailComponent } from './modules/home/section-search-detail/section-search-detail.component';
import { SectionProtokolComponent } from './modules/home/protokoller/protokoller.compoenent';
import { WorldMapComponent } from './modules/home/map/map.component';

const routes: Routes = [
  {
    path: '',

    component: HomeComponent,
  },
  {
    path: '/',

    component: HomeComponent,
  },


  {
    path: 'index',

    component: HomeComponent,
  },
  {
    path: 'dizin/:param',
    component: UnitGridListComponent,
  },
  {
    path: 'dizin/:param/:param2',
    component: UnitDetailPageComponent,
  },
  {
    path: 'programlar',
    component: ProgramListComponent,
  },

  {
    path: 'programlar/:param',
    component: ProgramMainListComponent,
  },
  {
    path: 'programlar/:degree/:param',
    component: ProgramMainSubListComponent,
  },

  {
    path: 'programlar/:degree/:unit/:param',
    component: ProgramDetailComponent,
  },

  {
    path: 'kategori/haberler/:category/:page',
    component: SectionPostCategoryListComponent,
  },
  {
    path: 'kategori/haberler/:page',
    component: SectionPostListComponent,
  },
  {
    path: 'haber/:slug',
    component: SectionPostDetailComponent,
  },

  {
    path: 'kurumsal/tanitim',
    component: SectionPageCategoryListComponent,
  },



  {
    path: 'kategori/etkinlikler/:category/:page',
    component: SectionEventCategoryListComponent,
  },
  {
    path: 'kategori/etkinlikler/:page',
    component: SectionEventListComponent,
  },
  {
    path: 'etkinlik/:slug',
    component: SectionEventDetailComponent,
  },

  {
    path: 'kurumsal/tanitim',
    component: SectionPageCategoryListComponent,
  },
  { path: 'uluslararasi/uluslararasi-is-birliklerimiz', component: SectionProtokolComponent },
  {
    path: 'arama/:aranan',
    component: SectionSearchListComponent,
  },

  {
    path: ':aranan',
    component: SectionSearchDetailComponent,
  },
  {
    path: 'harita/map/d/h/map',
    component: WorldMapComponent,
  },








  {
    path: 'kategori/duyurular/:category/:page',
    component: SectionNoticeCategoryListComponent,
  },
  {
    path: 'kategori/duyurular/:page',
    component: SectionNoticeListComponent,
  },
  {
    path: 'duyuru/:slug',
    component: SectionNoticeDetailComponent,
  },


  {
    path: 'kurumsal/:slug',
    component: SectionPageCategoryListComponent,
  },

  {
    path: 'kurumsal/yonetim/:slug',
    component: SectionPageDetailComponent,
  },

  {
    path: 'kurumsal/yonetim/sayfa/:page',
    component: SectionPageCategoryListComponent,
  },

  {
    path: 'kurumsal/mevzuat/sayfa/:page',
    component: SectionPageCategoryListComponent,
  },

  {
    path: 'kurumsal/tanitim',
    component: SectionPageCategoryListComponent,
  },
  {
    path: 'kurumsal/tanitim/:slug',
    component: SectionPageDetailComponent,
  },

  {
    path: 'kurumsal/mevzuat/:slug',
    component: SectionPageDetailComponent,
  },
  {
    path: 'kurumsal/yonetim/rektoryardimcileri/:slug2',
    component: SectionPageDetailComponent,
  },




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
