import { HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import { CourseEnetity } from 'src/app/contracts/cms/programcourse-detail-entity';
import { UnitName } from 'src/app/contracts/cms/unit-program';
import { RequestParameters } from 'src/app/services/http-client-services';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { TranslationService } from 'src/app/services/translation.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-popup-tabs',
  templateUrl: './program-detail-popup.component.html',
  styleUrls: ['./program-detail-popup.component.css'],
})
export class ProgramDetailPopupComponent {
  showModal = false;
  currentLang = 'tr-TR';
  getLangDegree: keyof UnitName = 'tr';
  getLang: keyof UnitName = 'tr';
  breadcumb: Breadcrumb[] = [];




  programamac= "";
  courselist= "";
  coursename= "";
  code= "";
  tp= "";
  kredi= "";
  akts= "";
  derskazanim= "";
  kazanilanderece= "";
  kariyersecenekleri= "";
  akademikgelecek= "";
  mezuniyetsartlari= "";
  derecesartlari= "";
  sinavlardegerlendirme= "";
  nasilkayit= "";
  coursebilgi= "";
  coursetitle= "";
  coursecode= "";
  dersduzey= "";
  yariyil= "";
  dersdili= "";
  dersiverenler= "";
  amacvekazanimlar= "";
  haftalikdersakisi= "";
  olcmedegerlendirme= "";

  programtipi= "";
  programdili= "";
  programturu= "";
  egitimturu="";
  hafta="";
  konu="";
  ogrenme="";
  yontem="";
  etkinlik="";
  numbers="";
  suresi="";
  totalwork="";
  totalworksaat="";
  @Input() title: string = '';
  @Input() id: number = 0;
  coursDetail: CourseEnetity | any;

  constructor(private httpClientService: CMSDynamicService, private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService) {}
  toggleModal() {
    this.showModal = !this.showModal;

    if (this.showModal == true && this.id >= 1) {


      this.langService.currentLangCode$.subscribe(async (langCode) => {
        this.currentLang = langCode;
        await this.translationServices();
      });
    }
  }


  async langSelected(val: String) {
    if (val == 'tr-TR') {
      this.getLang = 'tr';
      this.getLangDegree = 'tr';
    }

    if (val == 'en-EN') {
      this.getLang = 'en';
      this.getLangDegree = 'en';
    }
  }
  async translationServices() {
    this.langSelected(this.currentLang);

    const lang: 'en' | 'tr' = this.storageService.getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';



    this.programamac = await this.translationService.getTranslation('programamac', lang);
    this.courselist = await this.translationService.getTranslation('courselist', lang);
    this.coursename = await this.translationService.getTranslation('coursename', lang);
    this.code = await this.translationService.getTranslation('code', lang);
    this.tp = await this.translationService.getTranslation('tp', lang);
    this.kredi = await this.translationService.getTranslation('kredi', lang);
    this.akts = await this.translationService.getTranslation('akts', lang);
    this.derskazanim = await this.translationService.getTranslation('derskazanim', lang);
    this.kazanilanderece = await this.translationService.getTranslation('kazanilanderece', lang);
    this.kariyersecenekleri = await this.translationService.getTranslation('kariyersecenekleri', lang);
    this.akademikgelecek = await this.translationService.getTranslation('akademikgelecek', lang);
    this.mezuniyetsartlari = await this.translationService.getTranslation('mezuniyetsartlari', lang);
    this.derecesartlari = await this.translationService.getTranslation('derecesartlari', lang);
    this.sinavlardegerlendirme = await this.translationService.getTranslation('sinavlardegerlendirme', lang);
    this.nasilkayit = await this.translationService.getTranslation('nasilkayit', lang);
    this.coursebilgi = await this.translationService.getTranslation('coursebilgi', lang);
    this.coursetitle = await this.translationService.getTranslation('coursetitle', lang);
    this.coursecode = await this.translationService.getTranslation('coursecode', lang);
    this.dersduzey = await this.translationService.getTranslation('dersduzey', lang);
    this.yariyil = await this.translationService.getTranslation('yariyil', lang);
    this.dersdili = await this.translationService.getTranslation('dersdili', lang);
    this.dersiverenler = await this.translationService.getTranslation('dersiverenler', lang);
    this.amacvekazanimlar = await this.translationService.getTranslation('amacvekazanimlar', lang);
    this.haftalikdersakisi = await this.translationService.getTranslation('haftalikdersakisi', lang);
    this.olcmedegerlendirme = await this.translationService.getTranslation('olcmedegerlendirme', lang);

    this.programtipi = await this.translationService.getTranslation('programtipi', lang);
    this.programdili = await this.translationService.getTranslation('programdili', lang);
    this.programturu = await this.translationService.getTranslation('programdirektor', lang);
    this.egitimturu = await this.translationService.getTranslation('egitimturu', lang);


    this.hafta = await this.translationService.getTranslation('hafta', lang);
    this.konu = await this.translationService.getTranslation('konu', lang);
    this.ogrenme = await this.translationService.getTranslation('ogrenme', lang);
    this.yontem = await this.translationService.getTranslation('yontem', lang);

    this.etkinlik=await this.translationService.getTranslation('etkinlik', lang);
    this.numbers=await this.translationService.getTranslation('numbers', lang);
    this.suresi=await this.translationService.getTranslation('suresi', lang);
    this.totalwork=await this.translationService.getTranslation('totalwork', lang);
    this.totalworksaat=await this.translationService.getTranslation('totalworksaat', lang);



    this.breadcumb = [
      {
        label: this.title,
        url: '/dizin/programlar',
      },
    ];

    this.getBologna();
  }

  async getBologna() {
    var requestParameter: Partial<RequestParameters> = {
      fullEndPoint:
       environment.baseUrl+ '/Kapi/detailCourse?courseId=' +
        this.id

    };

    const promise: CourseEnetity | any = await this.httpClientService.getx(
      requestParameter
    );

    this.coursDetail = promise.data;


  }
}
