<div class="page-wraper">
  <!-- CONTENT START -->
  <div class="page-content">
    <app-section-inner-banner
      title="Kurumsal"
      [breadcrumbs]="breadcumb"
    ></app-section-inner-banner>
    <div class="section-full p-t80 p-b50 bg-gray-light">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-b30">
            <!-- BLOG START -->
            <div class="blog-post date-style-2 blog-lg" *ngIf="items">
              <div class="wt-post-info bg-white p-l50 p-r50 p-t30">
                <div class="wt-post-title">
                  <h2 class="post-title">{{ items.item.baslik }}</h2>
                </div>

                <div class="wt-post-text" [innerHtml]="iframeContent"></div>
              </div>
            </div>

            <!--  <div class="widget bg-white  widget_tag_cloud">
             <h4 class="tagcloud">Tags</h4>
             <div class="tagcloud">
                 <a routerLink="/blog-list">Analytics</a>

             </div>
         </div> -->

            <!-- BLOG END -->
          </div>
        <!--   <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 rightSidebar m-b30">
            <aside class="side-bar">
              <div class="widget recent-posts-entry p-a20">
                <div class="text-left m-b30">
                  <h3 class="widget-title">Diğer</h3>
                </div>
                <div class="section-content" *ngIf="items">
                  <div class="widget-post-bx">
                    <div
                      class="widget-post clearfix"
                      *ngFor="let object of items.items"
                    >
                      <div class="wt-post-info">
                        <div class="wt-post-header">
                          <h6 class="post-title">
                            <a routerLink="/{{ object.url }}">{{
                              object.baslik
                            }}</a>
                          </h6>
                        </div>
                        <div class="wt-post-meta">
                          <ul></ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
