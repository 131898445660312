import { HttpHeaders } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import {
  Program,
  UnitProgram,
  Degree,
  Unit,
  UnitName,
} from 'src/app/contracts/cms/unit-program';
import { RequestParameters } from 'src/app/services/http-client-services';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
import { TranslationService } from 'src/app/services/translation.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-program-main-list',
  templateUrl: './program-main-list.component.html',
  styleUrls: ['./program-main-list.component.css'],
})
export class ProgramMainListComponent implements OnInit {
  programs: UnitProgram | any;
  programData: Program[] | any;

  tempProgramData: Program[] | any;
  title = '';
  onlisans = '';
  lisans = '';
  yukseklisans = '';
  doktora = '';
  countmetin = '';
  programtype = '';
  search = '';
  eposta = '';
  tum = '';
  read = '';
  breadcumb: Breadcrumb[] = [];
  onLisansFilterData: {
    degree_id: number;
    faculty_id: number;
    type: string;
  }[] = [];
  lisansFilterData: { degree_id: number; faculty_id: number; type: string }[] =
    [];
  yuksekDoktoraFilterData: {
    degree_id: number;
    faculty_id: number;
    type: string;
  }[] = [];
  programTypes: { degree_id: number; faculty_id: number; type: string }[] = [];

  count: number = 0;

  param: any;
  currentLang = 'tr-TR';
  getLang: keyof UnitName = 'tr';
  constructor(
    private httpClientService: CMSDynamicService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private langService: LangService,
    private translationService: TranslationService,
    private storageService: StorageService,
    private seoService: SeoService,
  ) {
    this.param = this.activatedRoute.snapshot.params['param'];
  }

  ngOnInit(): void {
    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;
      this.translationServices();
    });
  }

  async translationServices() {
    this.langSelected(this.currentLang);
    this.breadcumb = [];
    const lang: 'en' | 'tr' = this.storageService
      .getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';

      this.updateMetaTags();
    this.onlisans = this.translationService.getTranslation('onlisans', lang);
    this.lisans = this.translationService.getTranslation('lisans', lang);
    this.yukseklisans = this.translationService.getTranslation(
      'yukseklisans',
      lang
    );
    this.doktora = this.translationService.getTranslation('doktora', lang);
    this.countmetin = this.translationService.getTranslation(
      'countmetin',
      lang
    );
    this.programtype = this.translationService.getTranslation(
      'programfilter',
      lang
    );
    this.search = this.translationService.getTranslation('search', lang);

    this.tum = this.translationService.getTranslation('tum', lang);
    this.read = this.translationService.getTranslation('readmore', lang);

    this.title = this.translationService.getTranslation('programlar', lang);
    this.breadcumb.push({
      label: this.title,
      url: '/programlar',
    });

    if (this.param == 1) {
      this.breadcumb.push({
        label: this.onlisans,
        url: '/programlar',
      });

      this.updateMetaTags("onlisans");
    }
    if (this.param == 2) {
      this.breadcumb.push({
        label: this.lisans,
        url: '/dizin/programlar',
      });
      this.updateMetaTags(this.lisans);
    }
    if (this.param == 3) {
      this.breadcumb.push({
        label: this.yukseklisans,
        url: '/dizin/programlar',
      });
      this.updateMetaTags("yuksek-lisans");
    }
    if (this.param == 4) {
      this.breadcumb.push({
        label: this.doktora,
        url: '/dizin/programlar',
      });
      this.updateMetaTags(this.doktora);
    }

    await this.langSelected(this.currentLang);
    this.tempProgramData = [];
    this.getBologna();
  }
  updateMetaTags(titlex:string=""): void {
    const title =
      this.currentLang == 'tr-TR'
        ?  titlex.length>=2 ? titlex + ' | Ankara Üniversitesi': 'Programlar | Ankara Üniversitesi'
        :titlex.length>=2 ? titlex + ' |Ankara University': 'Programs | Ankara University';

    const description =
      this.currentLang == 'tr-TR'
        ? 'Cumhuriyetin İlk Üniversitesi '
        : 'The First University of the Republic';
    const keywords = ['Ankara', 'üniversite', 'programlar', 'öğrenci'];
    const authors = [
      this.currentLang == 'tr-TR' ? 'Ankara Üniversitesi' : 'Ankara University',
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl =
      'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/programlar/'+ titlex.toLowerCase();

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      titlex !=null ? titlex : "Programlar",
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );

    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }

  langSelected(val: String) {
    if (val == 'tr-TR') {
      this.getLang = 'tr';
    }

    if (val == 'en-EN') {
      this.getLang = 'en';
    }
  }

  async getBologna() {
    var requestParameter: Partial<RequestParameters> = {
      fullEndPoint:
      environment.baseUrl+ '/Kapi/listProgramByDegree?degreeId=' +
        this.param +
        '&program=true',

    };

    const promise: UnitProgram | any = await this.httpClientService.getx(
      requestParameter
    );

    this.programs = promise;
    var units: Unit[] = [];
    var programs: Program[] | any = [];

    this.onLisansFilterData.push({ degree_id: 0, faculty_id: 0, type: 'Tümü' });
    this.lisansFilterData.push({ degree_id: 0, faculty_id: 0, type: 'Tümü' });
    this.yuksekDoktoraFilterData.push({
      degree_id: 0,
      faculty_id: 0,
      type: 'Tümü',
    });
    this.programTypes.push({ degree_id: 0, faculty_id: 0, type: 'Tümü' });

    this.programs.data.degree.forEach((element: Degree) => {
      element.unit.list.forEach((element2: Unit) => {

        if (element.id == 1) {
          this.onLisansFilterData.push({
            degree_id: element.id,
            faculty_id: element2.id,
            type: element2.name[this.getLang],
          });
        }
        if (element.id == 2) {
          this.lisansFilterData.push({
            degree_id: element.id,
            faculty_id: element2.id,
            type: element2.name[this.getLang],
          });
        }

        if (element.id == 3) {
          this.yuksekDoktoraFilterData.push({
            degree_id: element.id,
            faculty_id: element2.id,
            type: element2.name[this.getLang],
          });
        }

        this.getProgramTypes(element2.list, element.id, element2.id);
        element2.list.forEach((element3: Program) => {
          element3.unit_name = element2.name;
          element3.degree_id = element.id;
          element3.unit_id = element2.id;

          if (element.id == 1) {
            element3.color = 'warning';
          }
          if (element.id == 2) {
            element3.color = 'primary';
          }
          if (element.id == 3) {
            element3.color = 'success';
          }
          if (element.id == 4) {
            element3.color = 'danger';
          }
          programs.push(element3);
        });
      });
    });

    this.programData = programs.sort((a: Program, b: Program) =>
      a.program_name.tr.localeCompare(b.program_name.tr)
    );

    this.tempProgramData = this.programData;
    this.count = this.tempProgramData.length;
  }

  selectOnlisans(event: any) {
    const value = event.target.value;
    if (value != 'Tümü' && value != 'Ön Lisans') {
      this.tempProgramData = null;

      this.tempProgramData = this.programData
        .filter((program: any) => {
          return program.unit_name.tr.includes(value);
        })
        .sort((a: Program, b: Program) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      if (value != 'Ön Lisans') {
        this.tempProgramData = this.programData;
      } else {
        this.tempProgramData = this.programData
          .filter((program: any) => {
            return (
              program.unit_name.tr !== 'Yabancı Diller Yüksekokulu' &&
              (program.program_type.tr.includes('Yüksekokulu') ||
                program.program_type.tr.includes('Ön Lisans') ||
                program.unit_name.tr.includes('Ön Lisans') ||
                program.unit_name.tr.includes('Yüksekokulu'))
            );
          })
          .sort((a: Program, b: Program) =>
            a.program_name.tr.localeCompare(b.program_name.tr)
          );
      }
    }

    this.count = this.tempProgramData.length;
  }

  routeProgram(id: number = 0) {
    if (id == 0) {
      this.router.navigateByUrl('programlar');
    } else {
      this.router.navigateByUrl('/programlar/' + id);
      this.param = id;
      this.programs = null;
      this.programData = null;

      this.tempProgramData = null;

      this.onLisansFilterData = [];
      this.lisansFilterData = [];
      this.yuksekDoktoraFilterData = [];
      this.getBologna();
    }
  }

  selectLisans(event: any) {
    const value = event.target.value;

    this.router.navigateByUrl('/programlar/' + this.param + '/' + value);
  }
  selectYuksek(event: any) {
    const value = event.target.value;
    if (value != 'Tümü') {
      this.tempProgramData = null;

      this.tempProgramData = this.programData
        .filter((program: any) => {
          return program.unit_name.tr.includes(value);
        })
        .sort((a: Program, b: Program) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      if (value != 'Yüksek Lisans') {
        this.tempProgramData = this.programData;
      } else {
        this.tempProgramData = this.programData
          .filter((program: any) => {
            return program.program_type.tr.includes(value);
          })
          .sort((a: Program, b: Program) =>
            a.program_name.tr.localeCompare(b.program_name.tr)
          );
      }
    }
    this.count = this.tempProgramData.length;
  }

  selectDoktora(event: any) {
    const value = event.target.value;
    if (value != 'Tümü') {
      this.tempProgramData = null;

      this.tempProgramData = this.programData
        .filter((program: any) => {
          return program.unit_name.tr.includes(value);
        })
        .sort((a: Program, b: Program) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      if (value != 'Doktora') {
        this.tempProgramData = this.programData;
      } else {
        this.tempProgramData = this.programData
          .filter((program: any) => {
            return program.program_type.tr.includes(value);
          })
          .sort((a: Program, b: Program) =>
            a.program_name.tr.localeCompare(b.program_name.tr)
          );
      }
    }
    this.count = this.tempProgramData.length;
  }

  changeType(event: any) {
    const value = event.target.value;
    if (value != 'Tümü') {
      this.tempProgramData = null;

      this.tempProgramData = this.programData
        .filter((program: any) => {
          return program.program_type.tr.includes(value);
        })
        .sort((a: Program, b: Program) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      this.tempProgramData = this.programData;
    }
    this.count = this.tempProgramData.length;
  }

  getProgramTypes(
    data: any,
    degreeid: number = 0,
    facultyid: number = 0
  ): { degree_id: number; type: string }[] {
    data.forEach((entry: any) => {
      if (entry.program_type[this.getLang] && entry.program_type['en']) {
        const type = entry.program_type[this.getLang];
        if (!this.programTypes.some((pt) => pt.type === type)) {
          this.programTypes.push({
            degree_id: degreeid,
            faculty_id: facultyid,
            type,
          });
        }
      }
    });

    return this.programTypes;
  }

  searchFilter(event: any) {
    const value = event.target.value.toLowerCase();

    if (value.length >= 4) {
      this.tempProgramData = this.programData
        .filter((program: any) => {
          return (
            program.program_name.tr.toLowerCase().includes(value) ||
            program.program_type.tr.toLowerCase().includes(value) ||
            program.unit_name.tr.toLowerCase().includes(value)
          );
        })
        .sort((a: any, b: any) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      this.tempProgramData = this.programData;
    }

    this.count = this.tempProgramData.length;
  }
}
