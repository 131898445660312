<div class="modal  fade" tabindex="-1" [ngClass]="{'show': showModal}" [ngStyle]="{'display': showModal ? 'block' : 'none'}" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="coursDetail!=null">
      <div class="modal-header">
        <h5 class="modal-title">{{coursDetail.course_information.title[getLang]}}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="toggleModal()"><i class="fa fa-close"></i></button>
      </div>
      <div class="modal-body" style="overflow-y: auto;">
        <div class="container">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link active" data-toggle="tab" href="#dersBilgileri">{{coursebilgi}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#amacVeKazanimlar">{{amacvekazanimlar}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#haftalikDersAkisi">{{haftalikdersakisi}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" data-toggle="tab" href="#olcmeVeDegerlendirme">{{olcmedegerlendirme}}</a>
            </li>
          </ul>

          <div class="tab-content">
            <div class="tab-pane container active" id="dersBilgileri">
              <ul>
                <li><span class="des">{{coursename}}:</span> {{ coursDetail.course_information.title[getLang] }} <hr></li>
                <li><span class="des">{{coursecode}}:</span> {{ coursDetail.course_information.code }} <hr></li>
                <li><span class="des">{{dersduzey}}:</span> {{ coursDetail.course_information.level[getLang] }}<hr></li>
                <li><span class="des">{{yariyil}}:</span> {{ coursDetail.course_information.semester }}<hr></li>
                <li><span class="des">{{tp}}:</span> {{ coursDetail.course_information.tp }}<hr></li>
                <li><span class="des">{{kredi}}:</span> {{ coursDetail.course_information.credits }}<hr></li>
                <li><span class="des">{{dersdili}}:</span> {{ coursDetail.course_information.language[getLang] }}<hr></li>
                <li><span class="des">{{dersiverenler}}:</span>
                  <a *ngFor="let egitmen of coursDetail.course_information.instructors">{{ egitmen[getLang] }}</a>
                </li>
              </ul>
            </div>
            <div class="tab-pane container" id="amacVeKazanimlar">
              <table class="table">

                <tbody>
                  <tr>
                    <td>{{ coursDetail.course_information.goals[getLang] }}</td>
                  </tr>
                  <tr *ngFor="let outcome of coursDetail.course_information.outcomes">
                    <td>{{ outcome[getLang] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="tab-pane container" id="haftalikDersAkisi">

              <table class="table">
                <thead>
                  <tr>
                      <th>{{hafta}}</th>
                      <th>{{konu}}</th>
                      <th>{{ogrenme}}</th>
                      <th>{{yontem}}</th>
                  </tr>
              </thead>
                <tbody>
                  <tr *ngFor="let weekly of coursDetail.course_weekly" >
                    <td>{{weekly.week}}</td>
                    <td>{{weekly.topics[getLang]}}</td>
                    <td>{{weekly.activities[getLang]}}</td>
                    <td>{{weekly.methods[getLang]}}</td>
                  </tr>

                </tbody>
              </table>


            </div>
            <div class="tab-pane container" id="olcmeVeDegerlendirme">

              <table class="table">
                <thead>
                  <tr>
                      <th>{{etkinlik}}</th>
                      <th>{{numbers}}</th>
                      <th>{{suresi}}</th>
                      <th>{{totalwork}}</th>
                  </tr>
              </thead>
                <tbody>
                  <tr *ngFor="let measurement of coursDetail.measurements" >
                    <td>{{measurement.eventData[getLang]}}</td>
                    <td>{{measurement.quantity}}</td>
                    <td>{{measurement.duration}}</td>
                    <td>{{measurement.total_workload}}</td>
                  </tr>

                  <tr  >
                    <td>{{totalworksaat}}</td>
                    <td></td>
                    <td></td>
                    <td>{{coursDetail.total_workload}}</td>

                  </tr>


                  <tr  >
                    <td>{{totalwork}}</td>
                    <td></td>
                    <td></td>
                    <td>{{coursDetail.total_workload_30}}</td>

                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="toggleModal()">Kapat</button>
      </div>
    </div>
  </div>
</div>
<a  class="" (click)="toggleModal()">{{title}}</a>
