<div class="section-full" *ngIf="activ">
  <div class="container">
      <div class="bg-white">
          <div class="section-content">
              <div class="center wt-small-separator-outer m-t20">
                  <h4 class="wt-title">{{songullememler}}</h4>
              </div>

              <!-- Yatay kaydırılabilir alan -->
              <div class="item-container">
                  <div class="item" *ngFor="let object of activ">
                      <div class="testimonial-11">
                          <div class="testimonial-content">
                              <div class="testimonial-detail clearfix">
                                  <div class="testimonial-info">
                                      <span class="testimonial-name">
                                          <a target="_blank" href="https://avesis.ankara.edu.tr/yayin/{{object.id}}/{{slugify(object.title)}}"
                                             [innerHTML]="object.title?.[getlang]">
                                          </a>
                                      </span>
                                      <span class="testimonial-position"><strong></strong>{{object.publicationType.name?.[getlang]}}</span>
                                      <span class="testimonial-position"><strong></strong>{{object.publicationSubType.name?.[getlang]}}</span>
                                  </div>
                              </div>
                              <div [ngClass]="{'collapsed': !isExpanded}" class="testimonial-text authors">
                                  <strong>{{yazarlar}}</strong>
                                  {{getAuthorsList(object.rightsOwners)}}
                                <!--   <p *ngFor="let author of object.rightsOwners; let i = index; let last = last">
                                      {{author.name}}  {{author.surname}}{{!last ? ',' : ''}}
                                  </p> -->
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
