import { HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Breadcrumb } from 'src/app/contracts/cms/breadcumb';
import {
  Program,
  UnitProgram,
  Degree,
  Unit,
  UnitName,
  DegreeName,
  ProgramName,
} from 'src/app/contracts/cms/unit-program';
import { RequestParameters } from 'src/app/services/http-client-services';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { CMSDynamicService } from 'src/app/services/models/cms-service';
import { SeoService } from 'src/app/services/seo-service';
import { TranslationService } from 'src/app/services/translation.services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.css'],
})
export class ProgramListComponent implements OnInit {
  programs: UnitProgram | any;
  programData: Program[] | any;

  currentLang = 'tr-TR';
  getLangDegree: keyof UnitName = 'tr';
  getLang: keyof UnitName = 'tr';

  tempProgramData: Program[] | any;

  onLisansFilterData: Unit[] = [];
  lisansFilterData: Unit[] = [];
  yuksekDoktoraFilterData: Unit[] = [];
  programTypes: { degree_id: number; type: string }[] = [];
  title = '';

  onlisans = '';
  lisans = '';
  yukseklisans = '';
  doktora = '';
  countmetin = '';
  programtype = '';
  search = '';
  eposta = '';
  tum = '';
  read = '';
  breadcumb: Breadcrumb[] = [];
  count: number = 0;
  constructor(
    private httpClientService: CMSDynamicService,
    private langService: LangService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private translationService: TranslationService,
    private storageService: StorageService,
    private seoService: SeoService
  ) {}
  @ViewChild('selectOlisans') elementToBeRemoved!: ElementRef;

  async ngOnInit() {
    this.langService.currentLangCode$.subscribe(async (langCode) => {
      this.currentLang = langCode;

      await this.translationServices();
      this.cdr.detectChanges();
      this.getBologna();
      this.cdr.detectChanges();
    });
  }

  async updateOptions() {
    this.onLisansFilterData = [];
    this.lisansFilterData = [];
    this.yuksekDoktoraFilterData = [];
  }

  async langSelected(val: String) {
    if (val == 'tr-TR') {
      this.getLang = 'tr';
      this.getLangDegree = 'tr';
    }

    if (val == 'en-EN') {
      this.getLang = 'en';
      this.getLangDegree = 'en';
    }
  }

  async translationServices() {
    this.updateOptions();
    this.langSelected(this.currentLang);

    const lang: 'en' | 'tr' = this.storageService
      .getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';
    this.updateMetaTags();
    this.onlisans = this.translationService.getTranslation('onlisans', lang);
    this.lisans = this.translationService.getTranslation('lisans', lang);
    this.yukseklisans = this.translationService.getTranslation(
      'yukseklisans',
      lang
    );
    this.doktora = this.translationService.getTranslation('doktora', lang);
    this.countmetin = this.translationService.getTranslation(
      'countmetin',
      lang
    );
    this.programtype = this.translationService.getTranslation(
      'programfilter',
      lang
    );
    this.search = this.translationService.getTranslation('search', lang);

    this.tum = this.translationService.getTranslation('tum', lang);
    this.read = this.translationService.getTranslation('readmore', lang);

    this.title = this.translationService.getTranslation('programlar', lang);
    this.breadcumb = [
      {
        label: this.title,
        url: '/dizin/programlar',
      },
    ];
  }
  updateMetaTags(titlex: string = ''): void {
    const title =
      this.currentLang == 'tr-TR'
        ? titlex.length>=2
          ? titlex + ' | Ankara Üniversitesi'
          : 'Programlar | Ankara Üniversitesi'
        : titlex.length >=2
        ? titlex + ' |Ankara University'
        : 'Programs | Ankara University';

    const description =
      this.currentLang == 'tr-TR'
        ? 'Cumhuriyetin İlk Üniversitesi '
        : 'The First University of the Republic';
    const keywords = ['Ankara', 'üniversite', 'programlar', 'öğrenci'];
    const authors = [
      this.currentLang == 'tr-TR' ? 'Ankara Üniversitesi' : 'Ankara University',
    ];
    const developers = ['Sezgin Akçay'];
    const tags = ['Ankara Üniversitesi', 'Cumhuriyetin İlk Üniversitesi'];

    const imageUrl =
      'https://www.ankara.edu.tr/wp-content/uploads/sites/6/2021/12/hitit-gunesi.jpg';
    const site = 'Ankara Üniversitesi';
    const twitterSite = '@ankarauni';
    const canonicalUrl = 'https://www.ankara.edu.tr/programlar/' + titlex;

    this.seoService.updateMetaTags(
      title,
      description,
      keywords,
      authors,
      developers,
      tags
    );
    this.seoService.updateSocialMetaTags(
      titlex != null ? titlex : 'Programlar',
      imageUrl,
      site,
      twitterSite,
      canonicalUrl
    );

    // Canonical URL'yi ekleyin
    this.seoService.addCanonicalUrl(canonicalUrl);
  }

  async getBologna() {
    var requestParameter: Partial<RequestParameters> = {
      fullEndPoint:
       environment.baseUrl+ '/Kapi/listAllPrograms',

    };

    const promise: UnitProgram | any = await this.httpClientService.getx(
      requestParameter
    );

    this.programs = promise;
    var units: Unit[] = [];
    var programs: Program[] | any = [];

    // Temizleme işlemi
    this.onLisansFilterData.splice(0, this.onLisansFilterData.length);
    this.lisansFilterData.splice(0, this.lisansFilterData.length);
    this.yuksekDoktoraFilterData.splice(0, this.yuksekDoktoraFilterData.length);
    this.programTypes.splice(0, this.programTypes.length);

    // Yeniden doldurma işlemi
    this.onLisansFilterData.push({
      id: 0,
      name: { tr: this.tum, en: this.tum },
      list: [],
    });
    this.lisansFilterData.push({
      id: 0,
      name: { tr: this.tum, en: this.tum },
      list: [],
    });
    this.yuksekDoktoraFilterData.push({
      id: 0,
      name: { tr: this.tum, en: this.tum },
      list: [],
    });
    this.programTypes.push({ degree_id: 0, type: this.tum });

    this.programs.data.degree.forEach((element: Degree) => {
      element.unit.list.forEach((element2: Unit) => {
        if (element.id == 1) {
          this.onLisansFilterData.push(element2);
        }
        if (element.id == 2) {
          this.lisansFilterData.push(element2);
        }
        if (element.id == 3) {
          this.yuksekDoktoraFilterData.push(element2);
        }
        this.getProgramTypes(element2.list, element.id);
        element2.list.forEach((element3: Program) => {
          element3.unit_name = element2.name;
          element3.degree_id = element.id;
          if (element.id == 1) {
            element3.color = 'warning';
          }
          if (element.id == 2) {
            element3.color = 'primary';
          }
          if (element.id == 3) {
            element3.color = 'success';
          }
          if (element.id == 4) {
            element3.color = 'danger';
          }
          element3.unit_id = element2.id;
          programs.push(element3);
        });
      });
    });

    this.programData = await programs.sort((a: Program, b: Program) =>
      a.program_name.tr.localeCompare(b.program_name.tr)
    );

    this.tempProgramData = this.programData;
    this.count = this.tempProgramData.length;
  }

  routerW(val: number = 1) {
    this.router.navigateByUrl('programlar/' + val);
  }
  async selectOnlisans(event: any) {
    // this.router.navigateByUrl('programlar/1');
    const value = event.target.value;

    if (value != 'Tümü' && value != 'Ön Lisans') {
      this.tempProgramData = null;

      this.tempProgramData = this.programData
        .filter((program: any) => {
          return program.unit_name[this.getLang].includes(value);
        })
        .sort((a: Program, b: Program) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      if (value != 'Lisans') {
        this.tempProgramData = this.programData;
      } else {
        this.tempProgramData = this.programData
          .filter((program: any) => {
            return (
              program.unit_name.tr !== 'Yabancı Diller Yüksekokulu' &&
              (program.program_type.tr.includes('Yüksekokulu') ||
                program.program_type.tr.includes('Ön Lisans') ||
                program.unit_name.tr.includes('Ön Lisans') ||
                program.name.tr.includes('Yüksekokulu'))
            );
          })
          .sort((a: Program, b: Program) =>
            a.program_name.tr.localeCompare(b.program_name.tr)
          );
      }
    }

    this.count = this.tempProgramData.length;
  }

  selectLisans(event: any) {
    //this.router.navigateByUrl('programlar/2');
    const value = event.target.value;
    if (value != 'Tümü') {
      this.tempProgramData = null;

      this.tempProgramData = this.programData
        .filter((program: any) => {
          return program.unit_name[this.getLang].includes(value);
        })
        .sort((a: Program, b: Program) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      if (value != 'Lisans') {
        this.tempProgramData = this.programData;
      } else {
        this.tempProgramData = this.programData
          .filter((program: any) => {
            return program.program_type.tr.includes(value);
          })
          .sort((a: Program, b: Program) =>
            a.program_name.tr.localeCompare(b.program_name.tr)
          );
      }
    }
    this.count = this.tempProgramData.length;
  }

  selectYuksek(event: any) {
    // this.router.navigateByUrl('programlar/3');
    const value = event.target.value;
    if (value != 'Tümü') {
      this.tempProgramData = null;

      this.tempProgramData = this.programData
        .filter((program: any) => {
          return program.unit_name.tr.includes(value);
        })
        .sort((a: Program, b: Program) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      if (value != 'Yüksek Lisans') {
        this.tempProgramData = this.programData;
      } else {
        this.tempProgramData = this.programData
          .filter((program: any) => {
            return program.program_type.tr.includes(value);
          })
          .sort((a: Program, b: Program) =>
            a.program_name.tr.localeCompare(b.program_name.tr)
          );
      }
    }
    this.count = this.tempProgramData.length;
  }

  selectDoktora(event: any) {
    const value = event.target.value;
    if (value != 'Tümü') {
      this.tempProgramData = null;

      this.tempProgramData = this.programData
        .filter((program: any) => {
          return program.unit_name.tr.includes(value);
        })
        .sort((a: Program, b: Program) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      if (value != 'Doktora') {
        this.tempProgramData = this.programData;
      } else {
        this.tempProgramData = this.programData
          .filter((program: any) => {
            return program.program_type.tr.includes(value);
          })
          .sort((a: Program, b: Program) =>
            a.program_name.tr.localeCompare(b.program_name.tr)
          );
      }
    }
    this.count = this.tempProgramData.length;

    //  this.router.navigateByUrl('programlar/4');
  }

  changeType(event: any) {
    const value = event.target.value;
    if (value != 'Tümü') {
      this.tempProgramData = null;

      this.tempProgramData = this.programData
        .filter((program: any) => {

          return program.program_type.tr.includes(value);
        })
        .sort((a: Program, b: Program) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      this.tempProgramData = this.programData;
    }
    this.count = this.tempProgramData.length;
  }

  getProgramTypes(
    data: any,
    degreeid: number = 0
  ): { degree_id: number; type: string }[] {
    data.forEach((entry: any) => {
      if (entry.program_type['tr'] && entry.program_type['en']) {
        const type = entry.program_type['tr'];
        if (!this.programTypes.some((pt) => pt.type === type)) {
          this.programTypes.push({ degree_id: degreeid, type });
        }
      }
    });

    return this.programTypes;
  }

  searchFilter(event: any) {
    const value = event.target.value.toLowerCase();

    if (value.length >= 4) {
      this.tempProgramData = this.programData
        .filter((program: any) => {
          return (
            program.program_name.tr.toLowerCase().includes(value) ||
            program.program_type.tr.toLowerCase().includes(value) ||
            program.unit_name.tr.toLowerCase().includes(value)
          );
        })
        .sort((a: any, b: any) =>
          a.program_name.tr.localeCompare(b.program_name.tr)
        );
    } else {
      this.tempProgramData = this.programData;
    }

    this.count = this.tempProgramData.length;
  }
}
