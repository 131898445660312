import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, async, catchError, lastValueFrom } from 'rxjs';
import { HttpClientService, RequestParameters } from '../http-client-services';
import { ErrorHandler } from 'src/app/helpers/error-handler';
import { IListBase } from 'src/app/contracts/base/list-base';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CMSDynamicService {
  constructor(
    private httpClientService: HttpClientService,
    private errorHandler: ErrorHandler
  ) {}

  async get(
    controller: string = '',
    action: string = '',
    filter: string = '',
    websiteId?: number,
    str: string = '',

    slug:string=""
  ): Promise<any> {
    var website = 0;

    if (websiteId != null) {
      website = websiteId;
    }

    const promiseData: Observable<any[] | any> = this.httpClientService
      .get({
        controller: controller,
        action: action,
        queryString: `${filter}&websiteId=${website}&languageCode=${str}${slug}`,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .pipe(
        catchError((error) => {
          throw this.errorHandler.getServerErrorMessage(error);
        })
      );

    var res = await lastValueFrom(promiseData);

    return res;
  }

  async getx(requestParameter: Partial<RequestParameters>): Promise<any> {
    var website = 0;

    const promiseData: Observable<any[] | any> = this.httpClientService
      .get(requestParameter)

      .pipe(
        catchError((error) => {
          throw this.errorHandler.getServerErrorMessage(error);
        })
      );

    var res = await lastValueFrom(promiseData);

    return res;
  }
  async getRehber(query: any): Promise<any> {







    const requestParameter: Partial<RequestParameters> = {
      fullEndPoint: environment.baseUrl+"/Kapi/rektorlukrehber?query="+query,

    };
    try {
      const response = await lastValueFrom(
        this.httpClientService.get(requestParameter).pipe(
          catchError((error) => {
            console.error('HTTP Error:', error);
            throw error;  // Hata işleme
          })
        )
      );
      return response;
    } catch (error) {
      console.error('Request failed:', error);
      throw error;  // Hata işleme
    }
  }

  async getPageable(
    page: number = 0,
    size: number = 20,
    controller: string = '',
    action: string = '',
    filter: string = '',
    websiteId?: number,
    languageCode:string='',
    str: string = ''
  ): Promise<any> {
    var website = 0;
var language="tr-TR";
    if (websiteId != null) {
      website = websiteId;
    }
    if (languageCode != null) {
      language = languageCode;
    }

    const promiseData: Observable<IListBase<any[]> | any> =
      this.httpClientService
        .get({
          controller: controller,
          action: action,
          queryString: `Page=${page}&PageSize=${size}&${filter}&websiteId=${website}&languageCode=${languageCode}${str}`,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        })
        .pipe(
          catchError((error) => {
            throw this.errorHandler.getServerErrorMessage(error);
          })
        );

    var res = await lastValueFrom(promiseData);

    return res;
  }

  async getAllIdData(
    page: number = 0,
    size: number = 20,
    controller: string = '',
    action: string = '',
    filter: string = '',
    id: string = ''
  ): Promise<any> {
    var idStr = '';
    if (id != null) {
      idStr = id;
    }

    const promiseData: Observable<IListBase<any[]> | any> =
      this.httpClientService
        .get({
          controller: controller,
          action: action,
          queryString: `Page=${page}&PageSize=${size}${idStr}${filter}`,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        })
        .pipe(
          catchError((error) => {
            throw this.errorHandler.getServerErrorMessage(error);
          })
        );
    var res = await lastValueFrom(promiseData);

    return res;
  }

  async getAllData(
    page: number = 0,
    size: number = 20,
    controller: string = '',
    action: string = '',
    filter: string = ''
  ): Promise<any> {
    const promiseData: Observable<IListBase<any[]> | any> =
      this.httpClientService
        .get({
          controller: controller,
          action: action,
          queryString: `Page=${page}&PageSize=${size}&${filter}`,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        })
        .pipe(
          catchError((error) => {
            throw this.errorHandler.getServerErrorMessage(error);
          })
        );
    var res = await lastValueFrom(promiseData);

    return res;
  }
}
